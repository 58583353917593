import { useState } from "react";

export const useSelected = data => {
  const [selected, setSelected] = useState(data);

  const handleClick = id => {
    const itemSelected = data.filter(elem => elem._id === id);
    setSelected(itemSelected);
  };

  return [selected, handleClick];
};
