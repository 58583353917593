import { authChecker } from "../../services/authService";
import agents from "../../api/agents";
import { toast } from "react-toastify";
import { getDateParam, resetDateRange } from "../../utils/dateParams";

export const GET_ALL_AGENTS = "GET_ALL_AGENTS";
export const CHANGE_PAGE_SIZE_AGENTS = "CHANGE_PAGE_SIZE_AGENTS";
export const HANDLE_PAGE_CHANGE_AGENTS = "HANDLE_PAGE_CHANGE_AGENTS";
export const CHANGE_OFFSET_VALUE_AGENTS = "CHANGE_OFFSET_VALUE_AGENTS";
export const SET_INFO_TO_DISPLAY_AGENTS = "SET_INFO_TO_DISPLAY_AGENTS";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET_PAGINATION_VALUES_AGENTS";
export const GET_ALL_FARMERS_BY_SEARCHING = "GET_ALL_FARMERS_BY_SEARCHING";
export const CHANGE_SELECTED_SEARCH_PARAM = "CHANGE_SELECTED_SEARCH_PARAM";

export const getAllAgents = () => dispatch => {
  authChecker();
  resetDateRange(dispatch);
  const params = { role: "agent" };
  return agents
    .get("/users", { params })
    .then(agentData => {
      dispatch({
        type: GET_ALL_AGENTS,
        payload: agentData.data,
      });
    })
    .catch(err => {
      toast.error("Something went wrong fetching Agents, try refreshing the page");
    });
};

export const changePageSize = perPage => dispatch => {
  dispatch({
    type: CHANGE_PAGE_SIZE_AGENTS,
    payload: perPage,
  });
};

export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  const { agent, common } = getState();
  const dateParams = getDateParam(common);
  const params = {
    ...agent.selectedSearchParam,
    ...dateParams,
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
  };
  return agents
    .get("/farmers", { params })
    .then(agentData => {
      dispatch({
        type: GET_ALL_FARMERS_BY_SEARCHING,
        payload: agentData.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE_AGENTS,
        payload: agentData.data.skip,
      });
    })
    .catch(err => {
      toast.error("Something went wrong fetching Agents, try refreshing the page");
    });
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;
  dispatch({
    type: CHANGE_OFFSET_VALUE_AGENTS,
    payload: offsetValue,
  });
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY_AGENTS,
    payload: arrayOfInfo,
  });
};

// Function is triggered when admin is searching for a farmer

export const filterFarmersBySearching = values => (dispatch, getState) => {
  authChecker();
  let { term, key } = values;
  let defaultKey = "firstName";
  const dateParams = getDateParam(getState().common);

  let selectedParam = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    selectedParam = {};
  }
  const params = {
    search: term,
    // ...selectedParam,
    ...dateParams,
  };
  return agents
    .get("/farmers", { params })
    .then(agentData => {
      dispatch({
        type: GET_ALL_FARMERS_BY_SEARCHING,
        payload: agentData.data,
      });
      dispatch({
        type: CHANGE_SELECTED_SEARCH_PARAM,
        payload: selectedParam,
      });
      dispatch({
        type: RESET_PAGINATION_VALUES_AGENTS,
      });
      if (!agentData.data.data.length) {
        toast.error("No Match found");
      }
    })
    .catch(err => {
      toast.error("Something went wrong fetching Agents, try refreshing the page");
    });
};
