import React from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Label } from "reactstrap";

const CustomHTMLTextField = ({
  label,
  type = "text",
  handleChange,
  name,
  required,
  placeholder = "Search",
  onClear,
  ...props
}) => {
  return (
    <>
      {label && (
        <Label htmlFor={name}>
          {label}
          {required && (
            <span className="ml-1" style={{ color: "red" }}>
              *
            </span>
          )}
        </Label>
      )}
      <div
        className="d-flex align-items-center w-100"
        style={{
          border: "1px solid #707070",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        <FiSearch className="form-icon" />
        <input
          name={name}
          type={type}
          className="w-100 h-100"
          placeholder={placeholder}
          style={{
            paddingLeft: "50px",
            border: "none",
          }}
          onChange={handleChange}
          {...props}
        />

        {props.value && (
          <IoMdClose
            className="form-icon"
            size={25}
            color={"#21c570"}
            onClick={onClear}
            
          />
        )}
      </div>
    </>
  );
};
export default CustomHTMLTextField;
