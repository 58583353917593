import dayjs from "dayjs";
import moment from "moment";
import currencyFormat from "../lib/AmountFormat";

export const agentBody = (farmer) => {
  return farmer.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.agentId ?? item?.adminId ?? "N/A",
        `${item?.firstName ?? "N/A"} ${item?.lastName ?? "N/A"}`,
        item?.phone ?? "N/A",
        item?.gender ?? "N/A",
        item?.state ?? "N/A",
        item?.lga ?? "N/A",
        item?.district ?? "N/A",
      ],
      dropDowns: {
        lockUser: true,
        unlockUser: true,
        assignRole: true,
        viewFarmers: true,
      },
    },
  }));
};

export const agentOnboardedFarmerBody = (farmer) => {
  return farmer.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item ? `${item.firstName} ${item.lastName}` : "",
        moment(item.meta.createdAt).format("DD MMMM YYYY h:mm:ss a"),
        item?.phone ?? "N/A",
        item?.gender ?? "N/A",
        item?.state ?? "N/A",
        item?.lga ?? "N/A",
        item?.street ?? "N/A",
      ],
    },
  }));
};

export const agentOtpBody = (agent) => {
  return agent.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.verified === true ? "Yes" : "No",
        item?.otpRequestCount,
        item?.phone,
        item?.verifyPhoneOtp === null ? "OTP Used" : item.verifyPhoneOtp,
        moment(item?.verifyPhoneOtpTimer).format("MMMM Do YYYY, h:mm:ss a"),
        moment(item?.meta?.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
      ],
    },
  }));
};

export const tradeApprovalSell = (approval) => {
  return approval.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.status,
        // item?.approved === true ? "Yes" : "No",
        item?.input?.inputName || item?.output?.outputName,
        `${item?.seller?.firstName || "No agent"} ${item?.seller?.lastName}`,
        moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
      ],
      dropDowns: { negotiate: true },
    },
  }));
};

export const tradeApprovalBuy = (approval) => {
  return approval.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.status,
        // item?.approved === true ? "Yes" : "No",
        item?.input?.inputName || item?.output?.outputName,
        `${item?.buyer?.firstName || "No"} ${item?.buyer?.lastName || "agent"}`,
        moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
      ],
      dropDowns: { negotiate: true },
    },
  }));
};
export const supplierBody = (suppliers) => {
  return suppliers.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.agentName,
        item?.driverName,
        item?.truckNumber,
        item?.commodityType,
        item?.quantity,
        moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
        item?.tracking,
      ],
      dropDowns: {weightTicket:true},
    },
  }));
};

export const farmerBody = (farmer) => {
  return farmer.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item ? `${item?.firstName} ${item?.lastName}` : "N/A",
        moment(item?.meta?.createdAt).format("DD MMMM YYYY h:mm:ss a"),
        item?.phone,
        item?.gender,
        item?.state,
        item?.lga,
        item?.street,
      ],
      dropDowns: true,
    },
  }));
};
export const adminBody = (farmer) => {
  return farmer.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item ? `${item?.firstName} ${item?.lastName}` : "N/A",
        item?.role ?? "N/A",
        item?.phone,
        item?.gender,
        item?.state,
        item?.lga,
      ],
      dropDowns: { editAccess: true },
    },
  }));
};

export const transactionBody = (transaction) => {
  return transaction.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.trackingReference ?? "No tracking reference",
        item?.inputName ?? item?.outputName ?? "Not avaliable",
        currencyFormat(item?.negotiatedAmount ?? 0),
        currencyFormat(item?.amountPaid ?? 0),
        item?.agentName ?? "",
        item?.units ?? "not avaliable",
        item?.status ?? "",
        item?.agentPhone ?? "",
      ],
      dropDowns:
        item?.serviceType === "fund" || item?.serviceType === "withdraw"
          ? true
          : {
              editAccess: true,
              trackTransaction: true,
            },
    },
  }));
};

export const resellerRequestBody = (resellerRequest) => {
  return resellerRequest.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.user?.companyName ?? "Testing",
        item?.user?.companyAddress ?? "Testing",
        item?.user?.firstName ?? "Testing",
        item?.user?.lastName ?? "Testing",
        item.approved ? "Approved" : "Not Approved",
        item?.user?.phone,
        item?.user?.email,
        dayjs(item?.meta?.updatedAt).format("YYYY-MM-DD"),
      ],
      dropDowns: { verifyCompany: true },
    },
  }));
};

export const resellerBody = (reseller) => {
  return reseller.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.companyName ?? "Testing",
        item?.companyAddress ?? "Testing",
        item?.firstName ?? "Testing",
        item?.lastName ?? "Testing",
        item.resellerApproved ? "Approved" : "Not Approved",
        item?.phone,
        item?.email,
        dayjs(item?.meta?.updatedAt).format("YYYY-MM-DD"),
      ],
      dropDowns: true,
    },
  }));
};

export const inputManagementBody = (inputMgt) => {
  return inputMgt.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.inputType,
        item?.inputCategory,
        item?.inputName,
        item?.inputState,
        item?.inputLga,
        item?.inputReference,
      ],
      dropDowns: {
        deleteAccess: true,
        assignAgentOffer: item?.isCustom,
        editAccess: true,
      },
    },
  }));
};

//called subscription sometimes
export const outputManagementBody = (outputMgt) => {
  return outputMgt.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.outputType,
        item?.outputCategory,
        item?.outputName,
        item?.outputState,
        item?.outputLga,
        item?.outputReference,
      ],
      dropDowns: {
        deleteAccess: true,
        assignAgentOffer: item?.isCustom,
        editAccess: true,
      },
    },
  }));
};

export const warehouseBody = (warehouse) => {
  return warehouse.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.state,
        item?.lga,
        item?.address,
        item?.district,
        item?.street,
        item?.warehouseNumber,
        item?.storageCapacity_trailer_load,
      ],
      dropDowns: { deleteAccess: true, editAccess: true },
    },
  }));
};

export const overviewBody = (overview) => {
  return overview.map((item) => ({
    key: item?._id,
    data: {
      body: [
        item?.agentId,
        `${item?.firstName} ${item?.lastName}`,
        item?.phone,
        item?.gender,
        item?.state,
        item?.lga,
        item?.district,
      ],
      dropDowns: true,
    },
  }));
};
