import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { createAdmin } from "../../../features/admin/action";
import CustomSelectField from "../../Forms/customFields/CustomSelectField";
import CustomSelectLocation from "../../Forms/customFields/CustomSelectLocation";
import CustomTextField from "../../Forms/customFields/CustomTextField";
import LoaderButton from "../../Loader/LoaderButton";

const CreateAdminModal = ({ toggleModal, create }) => {
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "superAdmin", label: "Super Admin" },
  ];
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        pin: "",
        role: "",
        confirmPin: "",
        lga: "",
        state: "",
        gender: "",
      }}
      //validationSchema={managerSchema}
      onSubmit={(values, { setSubmitting }) => {
        createAdmin(values, { setSubmitting }, toggleModal, dispatch);
      }}
    >
      {({ values, handleChange, setFieldValue, isSubmitting, handleSubmit }) => {
        const { CustomLgaSelect, CustomStateSelect } = CustomSelectLocation(
          { value: "state", label: "State", required: true },
          { value: "lga", label: "LGA", required: true },
          setFieldValue,
          values.state,
          values.lga
        );
        return (
          <Form>
            <ModalBody>
              <div className="form-grid">
                <div className="form-grid__individual">
                  <CustomTextField handleChange={handleChange} label="First Name" name={"firstName"} type="text" required />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField handleChange={handleChange} label="Last Name" name={"lastName"} type="text" required />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField handleChange={handleChange} label="Email Address" name={"email"} type="email" required />
                </div>

                <div className="form-grid__individual">
                  <CustomTextField handleChange={handleChange} label="Phone" name={"phone"} type="text" required />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField handleChange={handleChange} label="Confirm Pin" name={"confirmPin"} type="password" required />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField handleChange={handleChange} label="Pin" name={"pin"} type="password" required />
                </div>
                <div className="form-grid__individual">{CustomStateSelect}</div>
                <div className="form-grid__individual">{CustomLgaSelect}</div>
                <div className="form-grid__individual">
                  <CustomSelectField
                    label={"Gender"}
                    onChange={option => setFieldValue("gender", option.value)}
                    options={genderOptions}
                    required
                  />
                </div>
                <div className="form-grid__individual">
                  <CustomSelectField
                    label={"Role"}
                    onChange={option => setFieldValue("role", option.value)}
                    options={roleOptions}
                    required
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <LoaderButton
                className="btn-primary"
                text="Create User"
                isLoading={isSubmitting}
                style={{
                  width: 180,
                  marginRight: "10px",
                  backgroundColor: "#3bc552",
                  borderRadius: "0px 8px",
                }}
                onClick={handleSubmit}
                id="mc-embedded-subscribe"
              />
              <Button className="form-btn-2 ca-font-regular" onClick={() => toggleModal()}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateAdminModal;
