import { Formik } from "formik";
import React from "react";
import { Button, Col, Form, ModalBody, ModalFooter, Row } from "reactstrap";
import { createStatusOptions } from "../../../views/Dashboard/component/status";
import { createStockOptions } from "../../../views/Dashboard/component/stock";
import { createUnitOfMeasurement } from "../../../views/Dashboard/component/unitofMeasurement";
import LoaderButton from "../../Loader/LoaderButton";
import { outputCategoryObj, outPutCategoryValues } from "../../options/outputCategory";
import CustomSelectField, { setSelectValue } from "../../Forms/customFields/CustomSelectField";
import CustomTextField from "../../Forms/customFields/CustomTextField";
import CustomSelectLocation from "../../Forms/customFields/CustomSelectLocation";

const EditOutputMgtModal = ({ outputObject, toggleModal, updateSubscription }) => {
  return (
    <Formik
      initialValues={{
        minimumRequestLimit: outputObject.minimumRequestLimit,
        outputId: outputObject._id,
        outputType: outputObject.outputType,
        outputCategory: outputObject.outputCategory,
        outputName: outputObject.outputName,
        outputState: outputObject.outputState,
        outputLga: outputObject.outputLga,
        perUnitPrice: outputObject.perUnitPrice,
        unitOfMeasurement: outputObject.unitOfMeasurement,
        totalUnitsRequired: outputObject.totalUnitsRequired,
        unitsRemaining: outputObject.unitsRemaining,
        outputRequired: outputObject.outputRequired,
        qualityRequirement: outputObject.qualityRequirement,
        imageUrl: outputObject.imageUrl,
        description: outputObject.description,
        moistureContentPercentage: outputObject.moistureContentPercentage,
        outputWeight_in_unitOfMeasurement: outputObject.outputWeight_in_unitOfMeasurement,
        dirtRatioPercentage: outputObject.dirtRatioPercentage,
        typeOfGrain: outputObject.typeOfGrain,
        discoloringPercentage: outputObject.discoloringPercentage,

        outputActive: outputObject.outputActive,
      }}
      onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
        updateSubscription(
          values,
          {
            setErrors,
            setStatus,
            setSubmitting,
          },
          toggleModal
        );
      }}
    >
      {({ isSubmitting, setSubmitting, values, handleChange, handleSubmit, setFieldValue }) => {
        const { CustomLgaSelect, CustomStateSelect } = CustomSelectLocation(
          { value: "outputState", label: "Output State" },
          { value: "outputLga", label: "Output Local Government" },
          setFieldValue,
          values.outputState,
          values.outputLga
        );
        return (
          <>
            <ModalBody>
              <Form>
                <Col>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomSelectField
                        label={"Output Category"}
                        onChange={option => setFieldValue("outputCategory", option.value)}
                        options={outPutCategoryValues}
                        selectValue={setSelectValue(outPutCategoryValues, values.outputCategory)}
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomSelectField
                        label={"Output Type"}
                        onChange={option => setFieldValue("outputType", option.value)}
                        options={outputCategoryObj[values.outputCategory]}
                        selectValue={setSelectValue(outputCategoryObj[values.outputCategory] ?? [], values.outputType)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label={"Output Name"} name="outputName" type="text" />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Minimum Request Limit" name={"minimumRequestLimit"} type="text" />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>{CustomStateSelect}</Col>
                    <Col lg={5}>{CustomLgaSelect}</Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Per Unit Price" name={"perUnitPrice"} type="number" />
                    </Col>
                    <Col lg={5}>
                      <CustomSelectField
                        label={"Unit Of Measurement"}
                        onChange={option => setFieldValue("unitOfMeasurement", option.value)}
                        options={createUnitOfMeasurement}
                        selectValue={setSelectValue(createUnitOfMeasurement, values.unitOfMeasurement)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular mt-3">
                    <Col lg={5}>
                      <CustomSelectField
                        label={"status"}
                        onChange={option => setFieldValue("outputActive", option.value)}
                        options={createStatusOptions}
                        selectValue={setSelectValue(createStatusOptions, values.outputActive)}
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Total Units Required" name={"totalUnitsRequired"} type="number" />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Units Remaining" name={"unitsRemaining"} type="number" />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Quality Requirement" name={"qualityRequirement"} type="text" />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Description" name={"description"} type="text" />
                    </Col>
                    <Col lg={5}>
                      <CustomSelectField
                        label={"Output Instock"}
                        onChange={option => setFieldValue("outputRequired", option.value)}
                        options={createStockOptions}
                        selectValue={setSelectValue(createStockOptions, values.outputRequired)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Mositure Content"
                        name={"moistureContentPercentage"}
                        type="number"
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Weight of Produce"
                        name={"outputWeight_in_unitOfMeasurement"}
                        type="number"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Dirt Ratio" name={"dirtRatioPercentage"} type="number" />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Grain Type" name={"typeOfGrain"} type="text" />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label="Discoloring" name={"discoloringPercentage"} type="number" />
                    </Col>
                  </Row>

                  <Row className="mb-3 d-flex justify-content-around ca-font-regular"></Row>
                </Col>
              </Form>
            </ModalBody>
            <ModalFooter>
              <LoaderButton
                type="search"
                isLoading={isSubmitting}
                className="btn-primary"
                text="Update output"
                style={{
                  marginRight: "10px",
                  width: "150px",
                  borderRadius: "0px 8px",
                  backgroundColor: "#3bc552",
                }}
                id="mc-embedded-subscribe"
                onClick={handleSubmit}
              />
              <Button className="form-btn-2 ca-font-regular" onClick={toggleModal}>
                {" "}
                Cancel{" "}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default EditOutputMgtModal;
