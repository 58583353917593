import { Formik } from "formik";
import React from "react";
import { Button, Col, Form, ModalBody, ModalFooter, Row } from "reactstrap";
import { createStockOptions } from "../../../views/Dashboard/component/stock";
import { createUnitOfMeasurement } from "../../../views/Dashboard/component/unitofMeasurement";
import CustomSelectField from "../../Forms/customFields/CustomSelectField";
import CustomSelectLocation from "../../Forms/customFields/CustomSelectLocation";
import CustomTextField from "../../Forms/customFields/CustomTextField";
import LoaderButton from "../../Loader/LoaderButton";
import { CategoryValues, categoryObj } from "../../options/inputCategoryAndValues";

const NewInputMgtModal = ({ toggleModal, handleCancel, submitSubscription }) => {
  const [imagePreview, setImagePreview] = React.useState("");
  return (
    <Formik
      initialValues={{
        inputType: "",
        inputCategory: "",
        inputName: "",
        inputLga: "",
        inputState: "",
        instock: Boolean,
        perUnitPrice: "",
        inputDistributionCompany: "",
        minimumRequestLimit: "",
        description: "",
        discount: "",
        imageUrl: "",
      }}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        const updateValue = {
          ...values,
          imageUrl: imagePreview,
        };
        submitSubscription(
          updateValue,
          {
            setErrors,
            setStatus,
            setSubmitting,
          },
          toggleModal
        );
      }}
    >
      {({ isSubmitting, values, handleChange, handleSubmit, setFieldValue }) => {
        const { CustomLgaSelect, CustomStateSelect } = CustomSelectLocation(
          { value: "inputState", label: "Input State" },
          { value: "inputLga", label: "Input Local Government" },
          setFieldValue,
          values.inputState,
          values.inputLga
        );
        const combinedCategory = (categoryObj.Fertilizer || []).concat(categoryObj.Produce || []);
        const selectedImage = combinedCategory.find(preview => preview.value === values.inputType);
        if (selectedImage) {
          setImagePreview(selectedImage.imageTitle);
        }
        return (
          <Form>
            <ModalBody>
              <Row className="ca-font-regular mx-2">
                <Col md={4}>
                  <CustomSelectField
                    label={"Input Category"}
                    onChange={option => setFieldValue("inputCategory", option.value)}
                    options={CategoryValues}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelectField
                    label={"Input Type"}
                    onChange={options => setFieldValue("inputType", options.value)}
                    options={categoryObj[values.inputCategory] ?? []}
                  />
                </Col>
                <Col md={4}>
                  <CustomTextField type="text" handleChange={handleChange} label="Input Name" name={"inputName"} />
                </Col>
                <Col md={4} className="mt-3">
                  {CustomStateSelect}
                </Col>
                <Col md={4} className="mt-3">
                  {CustomLgaSelect}
                </Col>
                <Col md={4} className="mt-3">
                  <CustomSelectField
                    options={createStockOptions}
                    label={"In stock"}
                    onChange={option => setFieldValue("instock", option.value)}
                  />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomSelectField
                    label={"Unit Measurement"}
                    onChange={option => setFieldValue("unitOfMeasurement", option.value)}
                    options={createUnitOfMeasurement}
                  />
                </Col>
              </Row>
              <div className="my-4">
                <Row className="modal-header">
                  <Col className="form-header-color tab-font-size ca-font-medium">Input Subscription</Col>
                </Row>
              </div>
              <Row className="ca-font-regular mx-2">
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label={"per Unit Price"} name={"perUnitPrice"} type="number" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField
                    handleChange={handleChange}
                    label={"Input Distribution Company"}
                    name={"inputDistributionCompany"}
                    type="text"
                  />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label="Minimum Request Limit" name={"minimumRequestLimit"} type="number" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label={"Description"} name={"description"} type="text" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label={"Discount(%)"} name={"discount"} type="number" />
                </Col>
              </Row>
              <Row className="my-20">
                {selectedImage !== undefined && (
                  <Col>
                    {values.inputCategory && (
                      <>
                        <img
                          src={selectedImage?.imageTitle}
                          alt="Selected Input Category"
                          style={{ width: "100%", height: "auto", marginTop: "20px" }}
                        />
                      </>
                    )}
                  </Col>
                )}
              </Row>
            </ModalBody>
            <ModalFooter className="">
              <LoaderButton
                type="search"
                isLoading={isSubmitting}
                className="btn-primary"
                text="Create Input"
                style={{
                  width: 140,
                  marginRight: "10px",
                  backgroundColor: "#3bc552",
                  borderRadius: "0px 8px",
                }}
                id="mc-embedded-subscribe"
                onClick={handleSubmit}
              />
              <Button className="form-btn-2 ca-font-regular" onClick={() => handleCancel()}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewInputMgtModal;
