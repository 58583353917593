const sidebar = {
  Overview: {
    en: "Overview",
    ha: "Dubawa",
  },
  overview: {
    en: "overview",
    ha: "Dubawa",
  },
  agents: {
    en: "agents",
    ha: "wakilai",
  },
  Agent: {
    en: "Agent",
    ha: "Wakili",
  },
  "agent OTP": {
    en: "Agent OTP",
    ha: "Wakilin OTP",
  },
  "Agent OTP": {
    en: "Agent OTP",
    ha: "Wakilin OTP",
  },

  farmers: {
    en: "farmers",
    ha: "manoma",
  },

  Farmers: {
    en: "farmers",
    ha: "Manoma",
  },

  Transaction: {
    en: "Transaction",
    ha: "Ma'amala",
  },

  transaction: {
    en: "Transaction",
    ha: "ma'amala",
  },

  "Reseller Request": {
    en: "Reseller Request",
    ha: "mai sayarwa",
  },

  "Reseller Management": {
    en: "Reseller Management",
    ha: "Gudanar da Sake siyarwa",
  },

  "Input MGT": {
    en: "Input MGT",
    ha: "Shigar da MGT",
  },

  "Input Management": {
    en: "Input Management",
    ha: "Gudanar da shigarwa",
  },

  "Output MGT": {
    en: "Output MGT",
    ha: "Fitowar MGT",
  },

  "Output Management": {
    en: "Output Management",
    ha: "Gudanar da fitarwa",
  },
  "Warehouse MGT": {
    en: "Warehouse MGT",
    ha: "Farashin MGT",
  },
  "Warehouse Management": {
    en: "Warehouse MGT",
    ha: "Gudanar da Warehouse",
  },
};

export default sidebar;
