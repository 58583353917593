import React from "react";

export default ({ children, unit, top, left, bottom, right }) => {
  return (
    <div
      style={{
        margin: unit || "",
        marginLeft: left || "",
        marginRight: right || ""
      }}
    >
      {children}
    </div>
  );
};
