import { useState } from "react";

export const useOptions = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectedOption = selected => {
    setSelectedOption(selected);
  };

  return [selectedOption, handleSelectedOption];
};
