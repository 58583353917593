import moment from "moment";
import React from "react";
import { Col, ModalBody, Row } from "reactstrap";
import ModalColumn from "../modalColumn_tempt";

const ViewNegotiations = ({ buyObj }) => {
  return (
    <ModalBody>
      <div>
        <Col>
          <Row className="d-flex justify-content-between ca-font-regular">
            <ModalColumn
              result={buyObj?.input?.inputName || buyObj?.output?.outputName}
              title="Product Name"
            />
            <ModalColumn result={buyObj?.units} title="Total unit" />
          </Row>
          <Row className="d-flex justify-content-between ca-font-regular mt-3">
            <ModalColumn
              result={buyObj?.originalPricePerBag}
              title="Original Price Per Bag"
            />
            <ModalColumn
              result={buyObj?.negotiationPricePerBag}
              title="Negotiated Price Per Bag"
            />
          </Row>
          <Row className="d-flex justify-content-between ca-font-regular mt-3">
            <ModalColumn
              result={buyObj?.approved ? "Yes" : "No"}
              title="is Approved"
            />
            <ModalColumn
              result={moment(buyObj?.updatedAt).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
              title="last Updated At"
            />
          </Row>
          {buyObj?.approved && buyObj?.approvedBy && (
            <Row className="d-flex justify-content-between ca-font-regular mt-3">
              <ModalColumn
                result={`${buyObj?.approvedBy?.firstName} ${buyObj?.approvedBy?.lastName}`}
                title="Approved By"
              />
              <ModalColumn
                result={buyObj?.approvedBy?.email}
                title="Approvers Email"
              />
            </Row>
          )}
        </Col>
      </div>
    </ModalBody>
  );
};

export default ViewNegotiations;
