import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import authRoutes from "../routes/auth";
export default class Sign extends Component {
  render() {
    return (
      <div>
        <Switch>
          {authRoutes.map((prop, key) => {
            if (prop.hasSubMenu) {
              return prop.views.map((prop2, key2) => {
                return <Route path={prop2.path} component={prop2.component} key={key2} exact />;
              });
            }
            if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            }
            return <Route path={prop.path} component={prop.component} key={key} exact />;
          })}
        </Switch>
      </div>
    );
  }
}
