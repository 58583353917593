import admin from "../../api/admin3";
import { authChecker } from "../../services/authService";
import { toast } from "react-toastify";

export const CREATE_NEW_MANAGER = "CREATE NEW MANAGER";
export const SET_ALL_MANAGER = "GET ALL MANAGER";
export const SET_LOADING_STATE = "SET LOADING STATE";

const createNewManager = (values, setSubmitting, toggleModal) => (dispatch) => {
  authChecker();
  const roleAdded = { ...values, role: "warehouseManager" };
  admin
    .post("/", roleAdded)
    .then(() => {
      toast.success("warehouse manager created");
      toggleModal();
    })
    .catch((err) => toast.error(err.response.data.message || "Network Error"))
    .finally(() => {
      setSubmitting(false);
    });
};

export { createNewManager };
