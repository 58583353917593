import {
  GET_ALL_WAREHOUSE,
  HANDLE_PAGE_CHANGE,
  RESET_PAGINATION_VALUES_AGENTS,
  SET_SELECTED_PARAMS,
  SEARCH_WAREHOUSE,
} from "./action";

const initialState = {
  warehouseData: [],
  pageNumber: 1,
  searchParams: [
    { value: "state", label: "state" },
    { value: "lga", label: "local government" },
    { value: "warehouseNumber", label: "warehouseID" },
  ],
  selectedSearchParam: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WAREHOUSE:
      return {
        ...state,
        warehouseData: action.payload,
        selectedSearchParam: {},
        pageNumber: 1,
      };
    case RESET_PAGINATION_VALUES_AGENTS:
      return {
        ...state,
        pageNumber: 1,
      };
    case SEARCH_WAREHOUSE:
      return {
        ...state,
        warehouseData: action.payload,
      };
    case SET_SELECTED_PARAMS:
      return {
        ...state,
        selectedSearchParam: action.payload,
      };
    case HANDLE_PAGE_CHANGE:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumber: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumber: pageNumberOld,
        };
      }
      break;
    default:
      return state;
  }
};
