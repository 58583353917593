import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import DateFilter from "../../components/DatePicker/filter/dateFilter";
import Search from "../../components/Search";
import Spacer from "../../components/common/Spacer";
import { TableLoader } from "../../components/common/TableLoader";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import NewInputMgtModal from "../../components/modal/inputMgtModals/newInputMgtModal";
import { from_location, handle_modal_state, reset_modal_state } from "../../features/common/actions";
import { getAllInputManagement, handlePageChange, initiateInputManagement, setTable } from "../../features/inputManagement/action";
import {
  getAllTransaction,
  handlePageChange as handlePageChangeTransaction,
  transactionServiceTypes,
} from "../../features/transaction/actions";
import useTranslate from "../../hooks/useTranslate";
import InputManagementTable from "../DataTables/inputManagement";
import transactionTable from "../DataTables/transactionTable";
import InnerTab from "./component/innerTabs";
import TableAndPagination from "./component/tableAndPagination";

export const Tables = {
  InputManagement: "inputManagement",
  transaction: "transaction",
};

function InputManagement(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const {
    getAllInputManagement,
    getAllTransaction,

    totalDocumentCountTransaction,
    currentPageTransaction,
    pageSizeTransaction,
    handlePageChangeTransaction,

    totalDocumentCountIM,
    pageSizeIM,
    handlePageChangeIM,
    currentPageIM,
    reset_modal_state,
    handle_modal_state,
    updateLocation,
    submitSubcription,
    clickState,
    setTable,
    table,
  } = props;

  useEffect(() => {
    reset_modal_state();

    (async () => {
      try {
        await getAllInputManagement();
        await getAllTransaction(transactionServiceTypes.input);
        updateLocation("InputMangement");
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSwitchIM = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "inputManagement") return null;
    setLoadingPage(true);
    handlePageChangeIM(pageNumber, pageSize)
      .then(res => setLoadingPage(false))
      .catch(err => setLoadingPage(false));
  };

  const handlePageSwitchTransaction = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "transaction") return null;
    setLoadingPage(true);
    handlePageChangeTransaction(pageNumber, pageSize)
      .then(res => setLoadingPage(false))
      .catch(err => setLoadingPage(false));
  };

  const handleModalToggle = () => {
    handle_modal_state();
  };

  const handleCancel = () => {
    reset_modal_state();
  };

  const { t } = useTranslate();

  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30, marginTop: 40 }}>
          {t("Input Management")}
        </Col>
        <DateFilter />
        <Spacer unit={20} />
        {loading ? (
          <div>
            <TableLoader />
          </div>
        ) : (
          <>
            <InnerTab
              tabs={[
                {
                  name: "Input Management",
                  isActive: table === Tables.InputManagement,
                  onClick: () => setTable(Tables.InputManagement),
                },
                {
                  name: "Transactions",
                  isActive: table === Tables.transaction,
                  onClick: () => setTable(Tables.transaction),
                },
              ]}
            />

            <div>
              <Col>
                <Row className="d-flex justify-content-center">
                  <Search />
                </Row>
              </Col>
              {table === Tables.InputManagement ? (
                <TableAndPagination
                  Table={InputManagementTable}
                  currentPage={currentPageIM}
                  handlePageSwitch={handlePageSwitchIM}
                  loadingPage={loadingPage}
                  totalDocumentCount={totalDocumentCountIM}
                  pageSize={pageSizeIM}
                  location={"inputManagement"}
                />
              ) : (
                table === Tables.transaction && (
                  <TableAndPagination
                    Table={transactionTable}
                    currentPage={currentPageTransaction}
                    handlePageSwitch={handlePageSwitchTransaction}
                    loadingPage={loadingPage}
                    totalDocumentCount={totalDocumentCountTransaction}
                    pageSize={pageSizeTransaction}
                    location={"transaction"}
                  />
                )
              )}
            </div>
          </>
        )}
      </Col>
      {props.modalOpen && clickState && (
        <CustomModalWrapper
          isOpen={props.modalOpen}
          toggleModal={handleModalToggle}
          setModal={handle_modal_state}
          HeaderTitle={"Create Input Subscription"}
        >
          <NewInputMgtModal handleCancel={handleCancel} toggleModal={handleModalToggle} submitSubscription={submitSubcription} />
        </CustomModalWrapper>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  inputManagement: state.inputManagement.inputManagementData.data || [],
  addSubscription: state.common.clickState,
  modalOpen: state.common.modalOpen,
  clickState: state.common.clickState,
  //input management table
  totalDocumentCountIM: state.inputManagement.inputManagementData.totalDocumentCount ?? 0,
  currentPageIM: state.inputManagement.pageNumberInput,
  pageSizeIM: state.inputManagement.inputManagementData.limit,
  table: state.inputManagement.table,

  //transaction table
  totalDocumentCountTransaction: state.transaction.transactionData.totalDocumentCount ?? 0,
  currentPageTransaction: state.transaction.pageNumberTransaction,
  pageSizeTransaction: state.transaction.transactionData.limit,
});

const mapDispatchToProps = dispatch => {
  return {
    getAllTransaction: type => dispatch(getAllTransaction(type)),
    handle_modal_state: () => dispatch(handle_modal_state()),
    reset_modal_state: () => dispatch(reset_modal_state()),
    handlePageChangeIM: (pageNumber, pageSize) => dispatch(handlePageChange(pageNumber, pageSize)),
    getAllInputManagement: () => dispatch(getAllInputManagement()),
    submitSubcription: (values, { setErrors, setSubmitting }, handleModalToggle) =>
      dispatch(initiateInputManagement(values, { setErrors, setSubmitting }, handleModalToggle)),
    updateLocation: location => dispatch(from_location(location)),
    handlePageChangeTransaction: (pageNumber, pageSize) => dispatch(handlePageChangeTransaction(pageNumber, pageSize)),
    setTable: table => dispatch(setTable(table)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputManagement);
