import React from "react";
import NotificationAlert from "react-notification-alert";

import propTypes from "prop-types";
import { connect } from "react-redux";
import { toastNotify } from "./action";

class ToastNotify extends React.Component {
  componentWillReceiveProps(nextProps) {
    this.refs.notify.notificationAlert({
      place: "tc",
      message: (
        <div>
          <span>{nextProps.msgDetails.msg}</span>
        </div>
      ),
      type: nextProps.msgDetails.type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 15,
    });
  }

  render() {
    return (
      <div>
        <NotificationAlert ref="notify" />
        <br />
      </div>
    );
  }
}

ToastNotify.propTypes = {
  toastNotify: propTypes.func.isRequired,
  msgDetails: propTypes.object,
};

const mapStateToProps = (state) => ({
  msgDetails: state.notifyData.msgOptions,
});

export default connect(mapStateToProps, { toastNotify })(ToastNotify);
