import { Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Form, ModalBody, ModalFooter, Row } from "reactstrap";
import * as Yup from "yup";
import { createStockOptions } from "../../../views/Dashboard/component/stock";
import { createUnitOfMeasurement } from "../../../views/Dashboard/component/unitofMeasurement";
import CustomSelectField from "../../Forms/customFields/CustomSelectField";
import CustomSelectLocation from "../../Forms/customFields/CustomSelectLocation";
import CustomTextField from "../../Forms/customFields/CustomTextField";
import LoaderButton from "../../Loader/LoaderButton";
import { outPutCategoryValues, outputCategoryObj } from "../../options/outputCategory";

const outputMgtSchema = Yup.object().shape({
  perUnitPrice: Yup.number().required("Required"),
  minimumRequestLimit: Yup.number().required("Required"),
  moistureContentPercentage: Yup.number().required("Required"),
  dirtRatioPercentage: Yup.number().required("Required"),
  qualityRequirement: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  typeOfGrain: Yup.string().required("Required"),
  discoloringPercentage: Yup.number().required("Required"),
  outputName: Yup.string().required("Required"),
});

const NewOutputMgtModal = ({ submitSubscription, toggleModal, memorizeWarehouseValue, handleCancel }) => {
  const [imagePreview, setImagePreview] = React.useState(""); 


  return (
    <Formik
      initialValues={{
        perUnitPrice: "",
        minimumRequestLimit: "",
        outputRequired: "",
        unitOfMeasurement: "",
        outputType: "",
        outputCategory: "",
        outputName: "",
        outputState: "",
        outputLga: "",
        qualityRequirement: "",
        imageUrl: "",
        description: "",
        moistureContentPercentage: "",
        dirtRatioPercentage: "",
        typeOfGrain: "",
        discoloringPercentage: "",
      }}
      validationSchema={outputMgtSchema}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        const updateValue = {
          ...values,
          imageUrl: imagePreview,
        };
        submitSubscription(
          updateValue,
          {
            setErrors,
            setStatus,
            setSubmitting,
          },
          toggleModal
        );
      }}
    >
      {({ isSubmitting, values, handleChange, handleSubmit, setFieldValue }) => {
        const { CustomLgaSelect, CustomStateSelect } = CustomSelectLocation(
          { label: "Output State", value: "outputState" },
          { 
            label: "Output Local Government", 
            value: "outputLga",
           
          },
          setFieldValue,
          values.outputState,
          values.outputLga
        );
        console.log(memorizeWarehouseValue);
       // Filter warehouses based on the selected LGA
const filteredOptions = memorizeWarehouseValue.filter(warehouseData => {
  // Check if the store and store.lga are defined
  if (warehouseData.store && warehouseData.store.lga) {
      // Check if the selected LGA is provided in values.outputLga
      if (values.outputLga) {
          // Compare the LGA from the store with the selected outputLga
          // Using toLowerCase() to make the comparison case-insensitive
          return warehouseData.store.lga.toLowerCase() === values.outputLga.toLowerCase();
      }
  }
  // If values.outputLga is not defined or there's no matching LGA, return false
  return false;
});

      
      
 
 
    console.log({filteredOptions});
        const combinedCategory = (outputCategoryObj.Fertilizer || []).concat(outputCategoryObj.Produce || []);
        const selectedImage = combinedCategory.find(preview => preview.value === values.outputType);
        if (selectedImage) {
          setImagePreview(selectedImage.imageTitle);
        }
        return (
          <Form>
            <ModalBody>
              <Row className="ca-font-regular mx-2">
                <Col md={4}>
                  <CustomTextField handleChange={handleChange} label="Unit Price (N)" name={"perUnitPrice"} type="number" />
                </Col>
                <Col md={4}>
                  <CustomTextField handleChange={handleChange} label="Minimum Request Limit" name={"minimumRequestLimit"} type="number" />
                </Col>

                <Col md={4}>
                  <CustomSelectField
                    label={"Output Required"}
                    onChange={option => setFieldValue("outputRequired", option.value)}
                    options={createStockOptions}
                  />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomSelectField
                    label={"Unit of Measurement"}
                    onChange={option => setFieldValue("unitOfMeasurement", option.value)}
                    options={createUnitOfMeasurement}
                  />
                </Col>
              </Row>
              <div className="my-4">
                <Row className="modal-header">
                  <Col className="form-header-color tab-font-size ca-font-medium">Output Subscription</Col>
                </Row>
              </div>
              <Row className="ca-font-regular mx-2">
                <Col md={4}>
                  <CustomSelectField
                    label={"Output Category"}
                    onChange={option => setFieldValue("outputCategory", option.value)}
                    options={outPutCategoryValues}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelectField
                    label={"Output Type"}
                    onChange={option => {
                      setFieldValue("outputType", option.value);
                    }}
                    options={outputCategoryObj[values.outputCategory]}
                  />
                </Col>
                <Col md={4}>
                  <CustomTextField handleChange={handleChange} label="Output Name" name={"outputName"} type="text" />
                </Col>
                <Col md={4} className="mt-3">
                  {CustomStateSelect}
                </Col>
               
                <Col md={4} className="mt-3">
                  {CustomLgaSelect}
                </Col> <Col md={4} className="mt-3">
                  <CustomSelectField
                    label={"warehouseId"}
                    onChange={option => setFieldValue("warehouseId", option?.value)}
                    options={filteredOptions.map(warehouse => ({
                      value: warehouse._id,
                      label: warehouse.store.name
                    }))}
                  />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label="Quality Requirement" name={"qualityRequirement"} type="text" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label="Description" name={"description"} type="text" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label="Mositure Content" name={"moistureContentPercentage"} type="number" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label="Dirt Ratio" name={"dirtRatioPercentage"} type="number" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label="Grain type" name={"typeOfGrain"} type="text" />
                </Col>
                <Col md={4} className="mt-3">
                  <CustomTextField handleChange={handleChange} label="Discoloring" name={"discoloringPercentage"} type="number" />
                </Col>
                {selectedImage !== undefined && (
                  <Col>
                    {values.outputType && (
                      <>
                        <img
                          src={selectedImage.imageTitle}
                          alt="Selected Output Type"
                          style={{ width: "100%", height: "auto", marginTop: "20px" }}
                        />
                      </>
                    )}
                  </Col>
                )}
              </Row>
            </ModalBody>
            <ModalFooter className="">
              <LoaderButton
                type="search"
                isLoading={isSubmitting}
                className="btn-primary"
                text="Create output"
                style={{
                  width: 140,
                  marginRight: "10px",
                  backgroundColor: "#3bc552",
                  borderRadius: "0px 8px",
                }}
                id="mc-embedded-subscribe"
                onClick={handleSubmit}
              />
              <Button className="form-btn-2 ca-font-regular" onClick={() => handleCancel()}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewOutputMgtModal;