import {
  SET_CROP_TYPE,
  SET_GRAPH_DATA,
  SET_GRAPH_DATE,
  SET_LOADING_STATE,
  SET_SERVICE_TYPE,
  RESET_ALL_PARAMS,
  SET_A_PARAM,
} from "./action";

const initialDate = {
  isLoading: false,
  transactionData: [],
  startDate: null,
  endDate: new Date(),
  cropType: null,
  serviceType: null,
  tracking:null
};

const reducer = (state = initialDate, action) => {
  switch (action.type) {
    case SET_GRAPH_DATE:
      return {
        ...state,
        startDate: action.payload?.startDate,
        endDate: action.payload?.endDate,
      };
    case SET_LOADING_STATE:
      return { ...state, isLoading: action.payload };
    case SET_GRAPH_DATA:
      return { ...state, transactionData: action.payload };
    case SET_CROP_TYPE:
      return { ...state, cropType: action.payload };
    case SET_SERVICE_TYPE:
      return { ...state, serviceType: action.payload };
    case SET_A_PARAM:
      return { ...state, ...action.payload };
    case RESET_ALL_PARAMS:
      return {
        ...state,
        startDate: null,
        endDate: new Date(),
        cropType: null,
        serviceType: null,
      };
    default:
      return state;
  }
};

export default reducer;
