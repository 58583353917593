import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import Spacer from "../../components/common/Spacer";
import Search from "../../components/Search";
import TransactionTable from "../DataTables/transactionTable";
import WarehouseInboundPagination from "../../components/common/Pagination";
import { connect } from "react-redux";
import {
  getAllTransaction,
  handlePageChange,
} from "../../features/transaction/actions";
import { from_location } from "../../features/common/actions";
import { TableLoader } from "../../components/common/TableLoader";
import LoadingAnimation from "../../components/Loader/Loader";
import DateFilter from "../../components/DatePicker/filter/dateFilter";
import useTranslate from "../../hooks/useTranslate";
function Transaction(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const {
    getAllTransaction,
    totalDocumentCount,
    pageSize,
    currentPage,
    handlePageChange,
    updateLocation,
  } = props;

  useEffect(() => {
    getAllTransaction()
      .then((res) => setLoading(false))
      .catch((err) => setLoading(false));
    updateLocation("transaction");
    // getAllBoundServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSwitch = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "warehouse") return null;
    setLoadingPage(true);
    handlePageChange(pageNumber, pageSize)
      .then((res) => setLoadingPage(false))
      .catch((err) => setLoadingPage(false));
  };
  const { t } = useTranslate();
  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30, marginTop: 40 }}>
          {t("Transaction")}
        </Col>
        <DateFilter />
        <Spacer unit={20} />
        {loading ? (
          <div>
            <TableLoader />
          </div>
        ) : (
          <div>
            <Col>
              <Row className="d-flex justify-content-center">
                <Search />
              </Row>
            </Col>
            <Row>
              {/* Table */}
              <Col className="table-responsive-md">
                <TransactionTable />
              </Col>
            </Row>
            <div style={{ marginBottom: "5px" }}>
              {loadingPage ? <LoadingAnimation /> : <></>}
            </div>
            <Row>
              <WarehouseInboundPagination
                total={totalDocumentCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageSwitch}
                location="warehouse"
              />
            </Row>
          </div>
        )}
      </Col>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // transactionData: state.transaction.transactionData.data || [],
  pageNumber: state.transaction.transactionData.skip,
  pageSize: state.transaction.transactionData.limit,
  totalDocumentCount: state.transaction.transactionData.totalDocumentCount,
  currentPage: state.transaction.pageNumberTransaction,
  // searching
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTransaction: () => dispatch(getAllTransaction()),
    handlePageChange: (pageNumber, pageSize) =>
      dispatch(handlePageChange(pageNumber, pageSize)),
    updateLocation: (location) => dispatch(from_location(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
