import {
  GET_ALL_TRANSACTION,
  HANDLE_PAGE_CHANGE_TRANSACTION,
  CHANGE_OFFSET_VALUE,
  SET_INFO_TO_DISPLAY,
  SEARCH_TRANSACTION,
  RESET_PAGINATION_VALUES_AGENTS,
  SET_SELECTED_PARAMS,
} from "./actions";

const initialState = {
  warehouseActive: false,
  pageSize: 10,
  pageNumberTransaction: 1,
  offset: 0,
  info: [],
  notificationTransactionData: {},
  transactionData: {},
  searching: false,
  serviceType: null,
  searchParams: [
    { value: "trackingReference", label: "Tracking Reference" },
    { value: "tracking", label: "Tracking" },
    { value: "agentPhone", label: "Agent Phone" },
    { value: "agentName", label: "Agent Name" },
    { value: "outputName", label: "Output Name" },
    { value: "inputName", label: "Input Name" },
    { value: "serviceType", label: "Service Type" },
  ],
  selectedSearchParam: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TRANSACTION:
      return {
        ...state,
        transactionData: action.payload,
        notificationTransactionData: action.payload,
        pageNumberTransaction: 1,
        selectedSearchParam: action.selectedSearchParam && {},
      };
    case SET_SELECTED_PARAMS:
      return {
        ...state,
        selectedSearchParam: action.payload,
      };
    case HANDLE_PAGE_CHANGE_TRANSACTION:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberTransaction: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberTransaction: pageNumberOld,
        };
      }
      break;
    case RESET_PAGINATION_VALUES_AGENTS:
      return { ...state, pageNumberTransaction: 1 };
    case SEARCH_TRANSACTION:
      return {
        ...state,
        transactionData: action.payload,
      };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY:
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize);
      return { ...state, info: infoToDisplay };
    default:
      return state;
  }
};
