import { toast } from "react-toastify";
import adminAxios from "../../api/agents";
import { authChecker } from "../../services/authService";
import { getDateParam, resetDateRange } from "../../utils/dateParams";

export const GET_ALL_ADMINS = "get_all_admins";
export const FILTER_ADMINS = "filter_admins";
export const HANDLE_PAGE_CHANGE = "handle_page_change";
export const SET_SEARCH_PAGE = "set_search_page";
export const SET_SELECT_PARAMS = "set_select_params";
export const RESET_PAGINATION = "reset_page";

export const createAdmin = async (
  values,
  { setSubmitting, setErrors },
  toggleModal,
  dispatch
) => {
  authChecker();
  try {
    const result = await adminAxios.post("/admins", values);
    toast.success(result.data.message);
    toggleModal();
    if (dispatch) {
      dispatch(getAllAdmins());
    }
  } catch (e) {
    toast.error(e.response.data.message || "Network Error");
  } finally {
    setSubmitting(false);
  }
};

export const getAllAdmins = () => (dispatch) => {
  authChecker();
  resetDateRange(dispatch);
  return adminAxios
    .get("/admins")
    .then((result) => {
      dispatch({
        type: GET_ALL_ADMINS,
        payload: result.data,
      });
      toast.success(result?.data?.message);
    })
    .catch((e) => {
      toast.error(e?.response?.data?.message || "Network Error");
    });
};

export const searchAdmins = (values) => (dispatch, getState) => {
  authChecker();
  const dateParams = getDateParam(getState().common);

  let { term, key } = values;
  let defaultKey = "state";

  let selectedParam = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    selectedParam = {};
  }
  const params = {
    search: term,
    // ...selectedParam,
    ...dateParams,
  };

  return adminAxios.get("/admins", { params }).then((result) => {
    dispatch({
      type: SET_SEARCH_PAGE,
      payload: result.data,
    });

    dispatch({
      type: SET_SELECT_PARAMS,
      payload: selectedParam,
    });
    dispatch({
      type: RESET_PAGINATION,
    });
    toast.success(result?.data?.message).catch((e) => {
      toast.error(e?.response?.data?.message || "Network Error");
    });
  });
};

export const handleAdminPageChange =
  (pageNumber, pageSize) => (dispatch, getState) => {
    authChecker();
    const { admin, common } = getState();
    const dateParams = getDateParam(common);

    const params = {
      skip: (pageNumber - 1) * pageSize,
      limit: pageSize,
      ...admin.selectedParams,
      ...dateParams,
    };
    return adminAxios
      .get("/admins", { params })
      .then((result) => {
        dispatch({
          type: SET_SEARCH_PAGE,
          payload: result.data,
        });
        dispatch({
          type: HANDLE_PAGE_CHANGE,
          payload: result.data.skip,
        });
      })
      .catch((err) => {
        toast.error(
          "Something went wrong fetching Farmers, try refreshing the page"
        );
      });
  };

export const editAdmin = async (
  values,
  { setSubmitting, setErrors },
  toggleModal,
  dispatch
) => {
  authChecker();
  try {
    const result = await adminAxios.patch("/admins", values);
    toast.success(result.data.message);
    toggleModal();
    if (dispatch) dispatch(getAllAdmins());
  } catch (e) {
    toast.error(e.response.data.message || "Network Error");
  } finally {
    setSubmitting(false);
  }
};
