import { Formik } from "formik";
import React from "react";
import { Button, Col, Form, ModalBody, ModalFooter, Row } from "reactstrap";
import LoaderButton from "../../Loader/LoaderButton";
import CustomModalWrapper from "../customModalWrapper";
import { useOptions } from "../../../hooks/useOptions";
import { createRoleOptions } from "../../../views/DataTables/component/roleOptions";
import CustomSelectField from "../../Forms/customFields/CustomSelectField";

const AgentView = ({
  isOpen,
  agent,
  toggleModal,
  headerTitle,
  action,
  showDefaultFooter = false,
  assignRole,
  initialValues = {
    userId: agent._id,
  },
}) => {
  const [selectedOption, handleSelectedOption] = useOptions();

  return (
    <CustomModalWrapper
      HeaderTitle={headerTitle}
      toggleModal={toggleModal}
      showDefaultFooter={showDefaultFooter}
      isOpen={isOpen}
      key={agent._id}
      setModal={toggleModal}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          action(
            values,
            { setSubmitting, setErrors },
            toggleModal,
            selectedOption
          );
        }}
      >
        {({ values, handleChange, isSubmitting, errors, handleSubmit }) => (
          <>
            <ModalBody>
              <Col>
                <Row className="mb-4 d-flex justify-content-around">
                  <Col className="d-flex">
                    <div className="mr-3">
                      <img
                        src={agent.profileImage}
                        width={134}
                        height={134}
                        alt="farmer profile"
                        className="img-circle"
                      />
                    </div>
                    <div className="farmer-img-info d-flex flex-column justify-content-center ca-font-regular">
                      <div className="form-header-color">
                        {`${agent.firstName} `}
                        {agent.lastName}
                      </div>
                      <div>{agent.phone}</div>
                      <div>
                        {agent.gender ? agent.gender?.toUpperCase() : "N/A"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 d-flex justify-content-around">
                  <Col>
                    <hr className="hr-style-top" />
                    <div className="form-header-color ca-font-regular sub-header-form m-1">
                      Agent Information
                    </div>
                    <hr className="hr-style" />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between ca-font-regular">
                  <Col>
                    <div className="view-profile-subheader">
                      Verified Account
                    </div>
                    <div className="view-profile-fieldstyle">
                      {agent.verified === true ? "Yes" : "No"}
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center flex-column">
                    <div className="view-profile-subheader">Role</div>
                    <div className="view-profile-fieldstyle">{agent.role}</div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between ca-font-regular mt-3">
                  <Col>
                    <div className="view-profile-subheader">Active</div>
                    <div className="view-profile-fieldstyle">
                      {agent.active === true ? "Yes" : "No"}
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center flex-column">
                    <div className="view-profile-subheader">Agent Locked</div>
                    <div className="view-profile-fieldstyle">
                      {agent.lock === true ? "Yes" : "No"}
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between ca-font-regular mt-3">
                  <Col>
                    <div className="view-profile-subheader">
                      Agent a Reseller?
                    </div>
                    <div className="view-profile-fieldstyle">
                      {agent.isReseller === true ? "Yes" : "No"}
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center flex-column">
                    <div className="view-profile-subheader">
                      Reseller Status Approved?
                    </div>
                    <div className="view-profile-fieldstyle">
                      {agent.resellerApproved === true ? "Yes" : "No"}
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between ca-font-regular mt-3">
                  <Col>
                    <div className="view-profile-subheader">Agent ID</div>
                    <div className="view-profile-fieldstyle">
                      {agent.agentId}
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center flex-column"></Col>
                </Row>
              </Col>
              {assignRole && (
                <Form>
                  <Col>
                    <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                      <Col className="mt-3">
                        <CustomSelectField
                          label={"Assign Role"}
                          onChange={handleSelectedOption}
                          options={createRoleOptions}
                          selectValue={selectedOption}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form>
              )}
            </ModalBody>
            {!showDefaultFooter && (
              <ModalFooter>
                <LoaderButton
                  className="form-btn-1 ca-font-regular"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  type="submit"
                  text={headerTitle}
                  style={{
                    marginRight: "10px",
                    width: "140px",
                    borderRadius: "0px 8px",
                    backgroundColor: "#3bc552",
                  }}
                  id="mc-embedded-subscribe"
                />
                <Button className="form-btn-2" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            )}
          </>
        )}
      </Formik>
    </CustomModalWrapper>
  );
};

export default AgentView;
