import React, { useState, useEffect, useMemo } from "react";
import { Navbar, NavbarBrand, Nav, Col, Button } from "reactstrap";
import logo from "../../assets/images/aos-logo.svg";
import { logout, fetchUserData } from "../../features/auth/actions";
import { handle_modal_state, reset_modal_state, setTransactionClicked } from "../../features/common/actions";
import { connect } from "react-redux";
import useTranslate from "../../hooks/useTranslate";
import CustomSelectLanguage from "../Forms/customFields/CustomSelectLanguage";
import { FaRegEnvelope } from "react-icons/fa";
import { FiBell } from "react-icons/fi";
import HeaderDropDown from "./headerDropDown";
import { modalNames } from "../Forms/CreateAdmin";
import { AllTransactions, getAllTransaction } from "../../features/transaction/actions";
import downloadCsv from "../../utils/csvdownload/downloadCSV";
import { transactionConverter } from "../../utils/csvdownload/converter";
import { AllSupplier } from "../../features/supplier/action";

function HeaderRemake(props) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    logout,
    userData: { firstName, lastName, email, profileImage, ...userData },
    fetchUserData,
    handle_modal_state,
    getAllTransaction,
    transactionNotificationData,
    transactionData,
    setTransactionClicked,
    location,
    state,
  } = props;
  const toggle = () => setIsOpen(prev => !prev);

  useEffect(() => {
    fetchUserData();
    reset_modal_state();
    getAllTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionList = useMemo(() => {
    return transactionNotificationData.slice(0, 6).map(transaction => {
      return {
        children: transaction.transactionReference,
        onClick: () => {
          handle_modal_state(modalNames.transactionView);
          setTransactionClicked(transaction);
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionData]);

  const { t } = useTranslate();
  return (
    <div>
      <Navbar color="#FFFFFF" light expand="lg" className="col navbarShow" fixed={"top"}>
        <Col lg={6}>
          <NavbarBrand href="/dashboard" className="navbar-logo-spacing navbar-logo-position">
            <img src={logo} width="140px" height="60px" alt="logo" />
          </NavbarBrand>
          <button
            className={isOpen ? "hamburger hamburger--spring is-active" : "hamburger hamburger--spring"}
            type="button"
            onClick={toggle}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </Col>
        <Col lg={6}>
          <div className="d-flex justify-content-end">
            <div className="d-flex align-items-center mr-1" style={{ gap: "10px" }}>
              <p>{t("language")}:</p>
              <CustomSelectLanguage />
            </div>
            {location === "transaction" && (
              <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                <Button
                  onClick={() => {
                    downloadCsv(AllTransactions, state, transactionConverter);
                  }}
                >
                  Export Transaction
                </Button>
              </div>
            )}
             {location === "supplier" && (
              <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                <Button
                  onClick={() => {
                    downloadCsv(AllSupplier, state, transactionConverter);
                  }}
                >
                  Export suppliers
                </Button>
              </div>
            )}

            <Nav navbar>
              <HeaderDropDown>
                <FaRegEnvelope className="nav-icons" />
              </HeaderDropDown>
              <HeaderDropDown dropDownList={transactionList}>
                <FiBell className="nav-icons" />
              </HeaderDropDown>
              <HeaderDropDown
                dropDownList={[
                  {
                    children: (
                      <div>
                        <div className="">{`${firstName} ${lastName}`}</div>
                        <div className="text-success mb-1">{`role: (${userData?.role})`}</div>
                        <div className="text-success">{email}</div>
                      </div>
                    ),
                  },
                  {
                    children: "Profile",
                    onClick: () => {
                      handle_modal_state(modalNames.userView);
                    },
                  },
                  {
                    children: "Edit Profile",
                    onClick: () => handle_modal_state(modalNames.editUserView),
                  },
                  userData.role === "superAdmin" && {
                    children: "Create User",
                    onClick: () => handle_modal_state(modalNames.createUserView),
                  },

                  {
                    children: "Logout",
                    onClick: () => logout(),
                  },
                ]}
              >
                <img src={profileImage} alt="profileImage" width="35px" className="navbar-img mr-3" />
              </HeaderDropDown>
            </Nav>
          </div>
        </Col>
      </Navbar>
    </div>
  );
}

const mapStateToProps = state => ({
  transactionNotificationData: state.transaction.notificationTransactionData?.data ?? [],
  transactionData: state.transaction.transactionData?.data ?? [],
  userData: state.auth.userData,
  modalOpen: state.common.modalOpen,
  location: state.common.location,
  state: state,
});
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    fetchUserData: () => dispatch(fetchUserData()),
    handle_modal_state: name => dispatch(handle_modal_state(name)),
    reset_modal_state: () => dispatch(reset_modal_state()),
    getAllTransaction: () => dispatch(getAllTransaction()),
    setTransactionClicked: transaction => dispatch(setTransactionClicked(transaction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRemake);
