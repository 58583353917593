import * as Yup from "yup"; 
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const managerSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10)
      .max(11)
      .required("Required"),
    email: Yup.string().email().required("Required"),
    password: Yup.string().required("Required"),
    lga: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
  });
  
  export default managerSchema;