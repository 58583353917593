import React from "react";
import useTranslate from "../../hooks/useTranslate";

const IndividualSidebar = ({ icon, name }) => {
  const { t } = useTranslate();
  return (
    <span className="text-capitalize ca-font-medium">
      <img src={icon} className="mx-3 sidebar-img" width="20px" alt="" />
      {t(name)}
    </span>
  );
};

export default IndividualSidebar;
