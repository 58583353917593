import React from "react";
import PropTypes from "prop-types";
import { Card, CardText, Row, Col } from "reactstrap";
import useTranslate from "../../hooks/useTranslate";

export default function CardDetail({
  value,
  imgSource,
  subtitle,
  imgClass,
  imgWidth,
  imgHeight,
  agentName,
  agentPhoneNumber,
  ...rest
}) {
  const { t } = useTranslate();
  return (
    <div>
      <Card body className="shadow rounded card-size">
        <Row>
          <Col xs="auto" className="d-flex align-items-center">
            <div className={imgClass || ""}>
              {imgSource && (
                <img
                  src={imgSource}
                  alt="icon"
                  width={imgWidth}
                  height={imgHeight}
                />
              )}
            </div>
          </Col>
          <Col className="card-text-container">
            <CardText className={`${rest.valStyle} mt-3`}>{value}</CardText>
          </Col>
        </Row>
        <CardText className={rest.subStyle}>{t(subtitle)}</CardText>
        <CardText className={rest.subStyle}>{agentName}</CardText>
        <CardText className={rest.subStyle}>{agentPhoneNumber}</CardText>
      </Card>
    </div>
  );
}

CardDetail.propTypes = {
  value: PropTypes.number,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  subtitle: PropTypes.string,
};
