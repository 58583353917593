import history from "../history";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

const TOKEN = "token";
export const authChecker = () => {
  if (localStorage.getItem(TOKEN)) {
    return true;
  }
  return false;
};

export const setToken = token => {
  localStorage.setItem(TOKEN, token);
};

export const removeToken = () => {
  return localStorage.removeItem(TOKEN);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN);
};

export const unauthorized = async err => {
  if (!err.response) {
    removeToken();
    localStorage.setItem("logout", Date.now());
    history.push("/auth/login");
    await toast.error(err.message);
    return;
  }
  const { status } = err.response;
  if (status === 401) {
    removeToken();
    localStorage.setItem("logout", Date.now());
    history.push("/auth/login");
    toast.error(err.response.data.message);
  }
};

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(TOKEN);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export const signOut = () => {
  removeToken();
  history.push("/auth/login");
  toast.error("Goodbye!");
};

export default {
  authChecker,
  getCurrentUser,
  setToken,
  removeToken,
  getToken,
  unauthorized,
  signOut,
};
