import PerfectScrollbar from "perfect-scrollbar";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CreateAdmin from "../components/Forms/CreateAdmin";
import HeaderRemake from "../components/Header/HeaderRemake";
import Sidebar from "../components/Sidebar/Sidebar";
import dashboardRoutes from "../routes/dashboardRoutes";

var ps;
class Dashboard extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {
    return (
      <div className="wrapper h-100">
        <div className="top-border-th"></div>

        <HeaderRemake {...this.props} />
        <div className="main-panel userDashboardBg" ref="mainPanel">
          <div className="pageWrapper">
            <Sidebar {...this.props} routes={dashboardRoutes} />
            <CreateAdmin />
            <Switch>
              {dashboardRoutes.map((prop, key) => {
                if (prop.hasSubMenu) {
                  return prop.views.map((prop2, key2) => {
                    return <Route path={prop2.path} component={prop2.component} key={key2} exact />;
                  });
                }
                if (prop.redirect) {
                  return <Redirect from={prop.path} to={prop.pathTo} key={key} exact />;
                }
                return <Route path={prop.path} component={prop.component} key={key} exact />;
              })}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
