import farmers from "../../api/farmers";
import { toast } from "react-toastify";
import { authChecker } from "../../services/authService";
export const HANDLE_PAGE_CHANGE_OVERVIEW = "HANDLE_PAGE_CHANGE";
export const GET_FARMER_INPUT = "GET_FARMER_INPUT";
export const GET_FARMER_OUTPUT = "GET_FARMER_OUTPUT";
export const GET_ALL_AGENTS_OVERVIEW = "GET_ALL_AGENTS_OVERVIEW";

export const getFarmerOutputs = arrayOfData => dispatch => {
  if (arrayOfData.length <= 0) {
    return;
  }
  let allFarmerOutputs = [];
  let output;
  for (let i = 0; i < arrayOfData.length; i++) {
    if (!arrayOfData[i]["subscription_plan_ouputs"]) {
      output = 0;
      allFarmerOutputs.push(Number(output));
    } else {
      output = arrayOfData[i]["subscription_plan_ouputs"]["output_quantity"];
      allFarmerOutputs.push(Number(output));
    }
  }
  let totalOutputs = allFarmerOutputs.reduce((totalOutput, currentOutput) => totalOutput + currentOutput);
  dispatch({
    type: GET_FARMER_OUTPUT,
    payload: totalOutputs,
  });
};

export const getAllAgentsOverview = () => dispatch => {
  authChecker();
  return farmers
    .get("/users")
    .then(agentData => {
      dispatch({
        type: GET_ALL_AGENTS_OVERVIEW,
        payload: agentData.data,
      });
    })
    .catch(err => {
      toast.error(err.message || "Network error");
    });
};

export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
  };
  return farmers
    .get("/users", { params })
    .then(agentData => {
      dispatch({
        type: GET_ALL_AGENTS_OVERVIEW,
        payload: agentData.data,
      });

      dispatch({
        type: HANDLE_PAGE_CHANGE_OVERVIEW,
        payload: agentData.data.skip,
      });
    })
    .catch(err => {
      toast.error(err.message || "Network error");
    });
};
