import moment from "moment";

export const transactionConverter = (rawData) => {
  const data = [...rawData];
  const csvRows = [];
  delete data[0].meta;
  delete data[0]._id;
  delete data[0].createdAt;
  delete data[0].updatedAt;
  delete data[0].responseBody;
  delete data[0].user;
  if (data[0].warehouse) {
    delete data[0].warehouse;
  }
  const keys = Object.keys(data[0]);
  csvRows.push(keys.join(","));
  data.forEach((row) => {
    let values = [];
    keys.forEach((key) => {
      const isDate = key === "paidAt" || key === "initiatedAt";
      values.push(isDate ? moment(row[key]) : row[key]);
    });
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
};
