import React from "react";

const DropDownComponent = ({ children, onClick }) => {
  return (
    <div onClick={onClick} className="header-dropdown__item">
      {children}
    </div>
  );
};
const HeaderDropDown = ({ children, dropDownList }) => {
  return (
    <button className="header-dropdown">
      {children}

      {dropDownList && dropDownList.length > 0 && (
        <div className={"header-dropdown__body"}>
          {dropDownList.map((item, index) => {
            if (item) {
              return (
                <DropDownComponent key={index} onClick={item.onClick}>
                  <div className="mx-4">{item.children}</div>
                </DropDownComponent>
              );
            }
            return null;
          })}
        </div>
      )}
    </button>
  );
};

export default HeaderDropDown;
