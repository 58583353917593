import React from "react";
import { Container, Row, Col } from "reactstrap";
import Loader from "../../components/Loader/Loader";

export default class Redirect extends React.Component {
  render() {
    return (
      <Container className="p-5 my-5 text-center">
        <Loader />

        <br />
        <br />

        <Row className="align-items-center">
          <Col className="mb-4">
            <span className="display-4 text-muted">
              Redirecting, please wait...
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}
