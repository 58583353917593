import React, { useState } from "react";
import { transactionHeadersArr } from "../../components/Table/headers";
import { useSelected, useModal } from "../../hooks";
import { connect } from "react-redux";
import { createTrackingOptions } from "./component/trackOptions";
import { useOptions } from "../../hooks/useOptions";
import {
  trackTransaction,
  upDateTransaction,
} from "../../features/transaction/actions";
import MainTransactionDetail from "../../components/modal/transactionModals/mainTransaction";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import TransactionEdit from "../../components/modal/transactionModals/transactionEdit";
import CustomTable from "../../components/Table";
import { transactionBody } from "../../components/Table/body";

const TransactionTable = ({ transactionData, ...props }) => {
  const [selected, handleClick] = useSelected(transactionData);
  const [modal, handleModalToggle, showModal] = useModal();
  const [selectedOption, handleSelectedOption] = useOptions();
  const [picked, setPicked] = useState("");
  const { trackUserTransaction, updateTransaction } = props;
  const handlePicked = (optionPicked, id) => {
    setPicked(optionPicked);
    handleClick(id);
    showModal();
  };

  return (
    <>
      <CustomTable
        bodyArray={transactionBody(transactionData)}
        dropDowns={{ editAccess: true, trackTransaction: true }}
        handlePicked={handlePicked}
        headerArr={transactionHeadersArr}
      />
      {!selected[0] || selected.length < 1 ? (
        ""
      ) : picked === "edit" ? (
        <CustomModalWrapper
          HeaderTitle={"Update Transaction"}
          isOpen={modal}
          setModal={handleModalToggle}
          toggleModal={handleModalToggle}
        >
          <TransactionEdit
            toggleModal={handleModalToggle}
            transaction={selected[0]}
            updateTransaction={updateTransaction}
          />
        </CustomModalWrapper>
      ) : picked === "trackTransaction" ? (
        <CustomModalWrapper
          HeaderTitle={"Track User Transaction"}
          isOpen={modal}
          setModal={handleModalToggle}
          toggleModal={handleModalToggle}
        >
          <MainTransactionDetail
            data={selected[0]}
            createTrackingOptions={createTrackingOptions}
            handleSelectedOption={handleSelectedOption}
            selectedOption={selectedOption}
            toggleModal={handleModalToggle}
            trackUserTransaction={trackUserTransaction}
          />
        </CustomModalWrapper>
      ) : (
        <CustomModalWrapper
          HeaderTitle={"View Transaction Details"}
          isOpen={modal}
          setModal={handleModalToggle}
          toggleModal={handleModalToggle}
        >
          <MainTransactionDetail
            data={selected[0]}
            toggleModal={handleModalToggle}
            trackUserTransaction={trackUserTransaction}
            viewTransaction
          />
        </CustomModalWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  transactionData: state.transaction.transactionData.data || [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    trackUserTransaction: (
      values,
      { setSubmitting, setErrors },
      handleModalToggle
    ) =>
      dispatch(
        trackTransaction(
          values,
          { setSubmitting, setErrors },
          handleModalToggle
        )
      ),
    updateTransaction: (
      values,
      { setSubmitting, setErrors },
      handleModalToggle
    ) =>
      dispatch(
        upDateTransaction(
          values,
          { setSubmitting, setErrors },
          handleModalToggle
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTable);
