import {
  GET_ALL_BOUND_SERVICE,
  TOGGLE_BOUND_STATE,
  HANDLE_PAGE_CHANGE,
  CHANGE_OFFSET_VALUE,
  SET_INFO_TO_DISPLAY
} from "./actions";

const initialState = {
  boundServiceActive: false,
  boundData: [],
  pageSize: 10,
  pageNumber: 1,
  offset: 0,
  info: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BOUND_STATE:
      const { boundServiceActive } = state;
      return { ...state, boundServiceActive: !boundServiceActive };
    case GET_ALL_BOUND_SERVICE:
      return { ...state, boundData: action.payload };
    case HANDLE_PAGE_CHANGE:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY:
      const infoToDisplay = action.payload.slice(
        state.offset,
        state.offset + state.pageSize
      );
      return { ...state, info: infoToDisplay };
    default:
      return state;
  }
};
