export const createTrackingOptions = [
    { value: "Acknowledged", label: "Acknowledged" },
    { value: "Dispatched", label: "Dispatched" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Rejected", label: "Rejected" },
    { value: "Approved", label: "Approved" },
    { value: "Processing", label: "Processing" },
  ];
  





