import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import Search from "../../components/Search";
import { from_location } from "../../features/common/actions";

import DateFilter from "../../components/DatePicker/filter/dateFilter";
import Spacer from "../../components/common/Spacer";
import { TableLoader } from "../../components/common/TableLoader";
import { getAllNegotiation, handlePageChange, setNegotiationTable } from "../../features/negotation/action";
import useTranslate from "../../hooks/useTranslate";
import TradeApprovalBuyTable from "../DataTables/TradeApprovalBuyTable";
import TradeApprovalSellTable from "../DataTables/TradeApprovalSellTable";
import InnerTab from "./component/innerTabs";
import TableAndPagination from "./component/tableAndPagination";

export const Tables = {
  buy: "buy",
  sell: "sell",
};

function InputManagement(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const {
    getAllNegotiationBuy,
    getAllNegotiationSell,
    totalDocumentCountBuy,
    currentPageBuy,
    pageSizeBuy,
    handlePageChangeBuy,
    totalDocumentCountSell,
    pageSizeSell,
    handlePageChangeSell,
    currentPageSell,
    updateLocation,
    setTable,
    table,
  } = props;

  useEffect(() => {
    (async () => {
      try {
        await getAllNegotiationBuy();
        await getAllNegotiationSell();
        updateLocation("negotiation");
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSwitchBuy = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "negotiation") return null;
    setLoadingPage(true);
    handlePageChangeBuy(pageNumber, pageSize)
      .then(res => setLoadingPage(false))
      .catch(err => setLoadingPage(false));
  };

  const handlePageSwitchSell = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "negotiation") return null;
    setLoadingPage(true);
    handlePageChangeSell(pageNumber, pageSize)
      .then(res => setLoadingPage(false))
      .catch(err => setLoadingPage(false));
  };

  const { t } = useTranslate();

  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30, marginTop: 40 }}>
          {t("Negotiation")}
        </Col>
        <DateFilter />
        <Spacer unit={20} />
        {loading ? (
          <div>
            <TableLoader />
          </div>
        ) : (
          <>
            <InnerTab
              tabs={[
                {
                  name: "Buy",
                  isActive: table === Tables.buy,
                  onClick: () => setTable(Tables.buy),
                },
                {
                  name: "Sell",
                  isActive: table === Tables.sell,
                  onClick: () => setTable(Tables.sell),
                },
              ]}
            />

            <div>
              <Col>
                <Row className="d-flex justify-content-center">
                  <Search />
                </Row>
              </Col>
              {table === Tables.buy ? (
                <TableAndPagination
                  Table={TradeApprovalBuyTable}
                  currentPage={currentPageBuy}
                  handlePageSwitch={handlePageSwitchBuy}
                  loadingPage={loadingPage}
                  totalDocumentCount={totalDocumentCountBuy}
                  pageSize={pageSizeBuy}
                  location={"negotiation"}
                />
              ) : (
                <TableAndPagination
                  Table={TradeApprovalSellTable}
                  currentPage={currentPageSell}
                  handlePageSwitch={handlePageSwitchSell}
                  loadingPage={loadingPage}
                  totalDocumentCount={totalDocumentCountSell}
                  pageSize={pageSizeSell}
                  location={"negotiation"}
                />
              )}
            </div>
          </>
        )}
      </Col>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    totalDocumentCountBuy: state?.negotiation?.negotiationDataBuy?.totalDocumentCount || 0,
    currentPageBuy: state?.negotiation?.pageNumber,
    pageSizeBuy: state?.negotiation?.negotiationDataBuy?.limit || 0,

    totalDocumentCountSell: state?.negotiation?.negotiationDataSell?.totalDocumentCount,
    pageSizeSell: state?.negotiation?.negotiationDataSell?.limit,
    currentPageSell: state?.negotiation?.pageNumber,
    table: state?.negotiation?.table,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllNegotiationBuy: () => dispatch(getAllNegotiation(Tables.buy)),
    getAllNegotiationSell: () => dispatch(getAllNegotiation(Tables.sell)),
    handlePageChangeBuy: (pageNumber, pageSize) => dispatch(handlePageChange(pageNumber, pageSize)),
    handlePageChangeSell: (pageNumber, pageSize) => dispatch(handlePageChange(pageNumber, pageSize)),
    updateLocation: location => dispatch(from_location(location)),
    setTable: table => dispatch(setNegotiationTable(table)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputManagement);
