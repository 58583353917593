import { toast } from "react-toastify";
import subscription from "../../api/subscription";
import { authChecker } from "../../services/authService";
export const CREATE_BOUND_SERVICE = "CREATE_BOUND_SERVICE";
export const TOGGLE_BOUND_STATE = "TOGGLE_BOUND_STATE";
export const GET_ALL_BOUND_SERVICE = "GET_ALL_BOUND_SERVICE";
export const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
export const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
export const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
export const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
export const CLEAR_BOUND_FORM = "CLEAR_BOUND_FORM";
export const ADD_BOUND_SERVICE = "ADD_BOUND_SERVICE";

export const toggleInboundState = () => dispatch => {
  dispatch({
    type: TOGGLE_BOUND_STATE,
  });
};

export const getAllBoundServices = () => dispatch => {
  authChecker();

  return subscription
    .get("https://thrive-commerce-api.herokuapp.com/thr/v1/trd-admin/resellers/request")
    .then(boundData => {
      dispatch({
        type: GET_ALL_BOUND_SERVICE,
        payload: boundData.data.data,
      });
    })
    .catch(() => {
      toast.error("Something went wrong fetching Inbound services, try refreshing the page");
    });
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;
  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};
export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  dispatch({
    type: HANDLE_PAGE_CHANGE,
    payload: pageNumber,
  });
  dispatch(handleOffset(pageNumber, pageSize));
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY,
    payload: arrayOfInfo,
  });
};
