import { useState } from "react";
export const useModal = () => {
  const [modal, setModal] = useState(false);

  const handleModalToggle = () => {
    setModal(prev => !prev);
  };

  const showModal = () => {
    handleModalToggle();
  };

  return [modal, handleModalToggle, showModal];
};
