import { Tables } from "../../views/Dashboard/TradeApproved";
import {
  GET_ALL_SUPPLIER,
  HANDLE_PAGE_CHANGE,
  RESET_PAGINATION_VALUES_AGENTS,
  SEARCH_SUPPLIER,
  SET_SELECTED_PARAMS,
} from "./action";

const initialState = {
  supplierData: {},
  pageNumber: 1,
  // eslint-disable-next-line no-sparse-arrays
  searchParams: [
    {
      value: "commodityType",
      label: "Commodity Type",
    },
    {
      value: "agentName",
      label: "Agent Name",
    },
    {
      value: "driverName",
      label: "Driver Name",
    },
  ],
  selectedSearchParam: {},
  table: Tables.buy,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUPPLIER:
      return {
        ...state,
        supplierData: action.payload,
        selectedSearchParam: {},
        pageNumber: 1,
      };

    case RESET_PAGINATION_VALUES_AGENTS:
      return {
        ...state,
        pageNumber: 1,
      };
    case SEARCH_SUPPLIER:
      return {
        ...state,
        supplierData: action.payload,
      };
    case SET_SELECTED_PARAMS:
      return {
        ...state,
        selectedSearchParam: action.payload,
      };
    case HANDLE_PAGE_CHANGE:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumber: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumber: pageNumberOld,
        };
      }
      break;
    default:
      return state;
  }
};
