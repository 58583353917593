const table = {
  "All Users": {
    en: "All Users",
    ha: "Duk Masu Amfani",
  },
  Name: {
    en: "Name",
    ha: "Suna",
  },
  Phone: {
    en: "Phone",
    ha: "Waya",
  },
  Gender: {
    en: "Gender",
    ha: "Jinsi",
  },
  State: {
    en: "State",
    ha: "Jiha",
  },
  District: {
    en: "District",
    ha: "Gundumar",
  },
  Action: {
    en: "Action",
    ha: "Aiki",
  },
  "Date Onboarded": {
    en: "Date Onboarded",
    ha: "Kwanan da Aka Shiga",
  },
  Street: {
    en: "Street",
    ha: "Titin",
  },
  "Output Type": {
    en: "Output Type",
    ha: "Nau'in fitarwa",
  },
  "Output Category": {
    en: "Output Category",
    ha: "Nau'in fitarwa",
  },
  "Output Name": {
    en: "Output Name",
    ha: "Sunan Fitowa",
  },
  "Output State": {
    en: "Output State",
    ha: "Jihar fitarwa",
  },
  "Output LGA": {
    en: "Output LGA",
    ha: "Farashin LGA",
  },
  "Output Reference": {
    en: "Output Reference",
    ha: "Maganar Fitarwa",
  },
  "Input Type": {
    en: "Input Type",
    ha: "Nau'in shigarwa",
  },
  "Input Category": {
    en: "Input Category",
    ha: "Nau'in shigarwa",
  },
  "Input Name": {
    en: "Input Name",
    ha: "Sunan shigarwa",
  },
  "Input State": {
    en: "Input State",
    ha: "Jihar shigarwa",
  },
  "Input LGA": {
    en: "Input LGA",
    ha: "Shigar LGA",
  },
  "Input Reference": {
    en: "Input Reference",
    ha: "Maganar shigarwa",
  },
  "Request Input or Output": {
    en: "Request Input or Output",
    ha: "Nemi Input ko Fitarwa",
  },
  Amount: {
    en: "Amount",
    ha: "Adadin",
  },
  "Amount Paid": {
    en: "Amount Paid",
    ha: "Adadin Da Aka Biya",
  },
  "Tracking Reference": {
    en: "Tracking Reference",
    ha: "Maganar Bibiya",
  },
  "Agent Name": {
    en: "Agent Name",
    ha: "Sunan Wakili",
  },
  Tracking: {
    en: "Tracking",
    ha: "Bibiya",
  },
  "Service Type": {
    en: "Service Type",
    ha: "Nau'in Sabis",
  },
  "Agent Phone": {
    en: "Agent Phone",
    ha: "Wayar Wakili",
  },
  "Company Name": {
    en: "Company Name",
    ha: "Sunan Kamfanin",
  },
  "Company Address": {
    en: "Company Address",
    ha: "Titin Kamfanin",
  },
  "First Name": {
    en: "First Name",
    ha: "Sunan rana",
  },
  "Last Name": {
    en: "Last Name",
    ha: "Sunan mahaifa",
  },
  "Reseller Status": {
    en: "Reseller Status",
    ha: "Matsayin mai siyarwa",
  },
  "Company Email": {
    en: "Company Email",
    ha: "Imel na Kamfanin",
  },
  "Date Requested": {
    en: "Date Requested",
    ha: "Ranar da aka nema",
  },
  Verified: {
    en: "Verified",
    ha: "Tabbatarwa",
  },
  "Time Requested": {
    en: "Time Requested",
    ha: "Lokacin da ake nema",
  },
  "Requested OTP Timer": {
    en: "Requested OTP Timer",
    ha: "Da ake bukata OTP Timer",
  },
  "Initiated At": {
    en: "Initiated At",
    ha: "An qaddamar A",
  },
  Address: {
    en: "Address",
    ha: "Titin",
  },
  "Warehouse ID": {
    en: "Warehouse ID",
    ha: "ID na Warehouse",
  },
  "Storage Capacity Trailer load": {
    en: "Storage Capacity Trailer load",
    ha: "Ma'ajiyar Capacity Trailer Load",
  },
  "Reseller Request": {
    en: "Reseller Request",
    ha: "Buƙatar mai siyarwa",
  },
  "All Tradr Reseller": {
    en: "All Tradr Reseller",
    ha: "Duk Mai sake siyarwar Tradr",
  },
  "Farmers Onboarded": {
    en: "Farmers Onboarded",
    ha: "Manoma sun hau",
  },
  "Agent Onboarded": {
    en: "Agent Onboarded",
    ha: "Wakili ya hau",
  },
};

export default table;
