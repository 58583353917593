import { TOAST_NOTIFY } from "./action";

const initialState = {
  msgOptions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOAST_NOTIFY:
      return {
        ...state,
        msgOptions: action.payload,
      };
    default:
      return state;
  }
};
