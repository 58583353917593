import { authChecker } from "../../services/authService";
import farmers from "../../api/farmers";
import { toast } from "react-toastify";
import { getDateParam, resetDateRange } from "../../utils/dateParams";

export const GET_ALL_FARMERS = "GET_ALL_FARMERS";
export const LOCK_USER = "LOCK_USER";
export const UNLOCK_USER = "UNLOCK_USER";
export const ASSIGN_ROLE = "ASSIGN_ROLE";
export const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
export const SEARCH = "SEARCHED";
export const HANDLE_PAGE_CHANGE_AGENT_DATE_RANGE = "HANDLE_PAGE_CHANGE_AGENT_DATE_RANGE";
export const GET_FARMERS_AGENT_ONBOARDED = "GET_FARMERS_AGENT_ONBOARDED";
export const HANDLE_PAGE_CHANGE_AGENTS_ONBOARD_WHO = "HANDLE_PAGE_CHANGE_AGENTS_ONBOARD_WHO";
export const SUCCESS_UNLOCK_USER = "SUCCESS_UNLOCK_USER";
export const CHANGE_SELECTED_PARAM_AGENT = "CHANGE_SELECTED_PARAM_AGENT";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET PAGINATION START VALUES";

export const getAllFarmers = () => dispatch => {
  resetDateRange(dispatch);
  authChecker();
  const params = { role: "agent" };
  return farmers
    .get("/users", { params })
    .then(farmerData => {
      dispatch({
        type: GET_ALL_FARMERS,
        payload: farmerData.data,
      });
    })

    .catch(() => {
      toast.error("Something went wrong fetching Farmers, try refreshing the page");
    });
};
export const getAgentFromID = async agentId => {
  try {
    authChecker();
    const agents = await farmers.get("/users", { params: { agentId } });
    return agents.data;
  } catch (e) {
    toast.error("Something went wrong fetching Agents, try refreshing the page");
    return null;
  }
};
/*****
 * Lock Agent
 *******/
export const lockAgent =
  (values, { setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    return farmers
      .put("/users/lock", values)
      .then(lockData => {
        toast.success(lockData.data.status + " " + lockData.data.message);
        handleModalToggle();
        dispatch({
          type: LOCK_USER,
          payload: lockData.data.data,
        });
        dispatch(getAllFarmers());
      })
      .catch(lockData => {
        toast.error(lockData.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

/*****
 * UnLock Agent
 *******/
export const unlockAgent =
  (values, { setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    return farmers
      .put("/users/unlock", values)
      .then(unlockData => {
        toast.success(unlockData.data.status + " " + unlockData.data.message);
        handleModalToggle();
        dispatch({
          type: UNLOCK_USER,
          payload: unlockData.data.data,
        });
        dispatch({
          type: SUCCESS_UNLOCK_USER,
          payload: unlockData.status,
        });
        dispatch(getAllFarmers());
      })
      .catch(unlockData => {
        toast.error(unlockData.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };
/***
 * AssignRole
 */
export const assignRole =
  (values, { setSubmitting, setErrors }, handleModalToggle) =>
  dispatch => {
    authChecker();
    return farmers
      .put("/users/role", values)
      .then(roleData => {
        handleModalToggle();
        toast.success(roleData.data.status + " " + roleData.data.message);
        dispatch({
          type: ASSIGN_ROLE,
          payload: roleData.data.data,
        });
        dispatch(getAllFarmers());
      })
      .catch(errData => {
        toast.error(errData.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  authChecker();
  const { farmer, common } = getState();
  const { selectedSearchParamsAgent } = farmer;
  const dateParams = getDateParam(common);

  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    role: "agent",
    ...selectedSearchParamsAgent,
    ...dateParams,
  };

  return farmers
    .get("/users", { params })
    .then(farmerData => {
      dispatch({
        type: SEARCH,
        payload: farmerData.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE,
        payload: farmerData.data.skip,
      });
    })
    .catch(err => {
      toast.error("Something went wrong fetching Farmers, try refreshing the page");
    });
};

// Search for a user by using keys
export const searchAgent = values => (dispatch, getState) => {
  authChecker();
  const dateParams = getDateParam(getState().common);
  let { term, key } = values;
  let defaultKey = "firstName";
  let selectedParams = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    selectedParams = {};
  }

  const params = {
    // role: "agent",
    search: term,
    // ...selectedParams,
    ...dateParams,
  };
  return farmers
    .get("/users", { params })
    .then(agentSearchresult => {
      dispatch({
        type: SEARCH,
        payload: agentSearchresult.data,
      });
      dispatch({
        type: CHANGE_SELECTED_PARAM_AGENT,
        payload: selectedParams,
      });
      dispatch({
        type: RESET_PAGINATION_VALUES_AGENTS,
      });
    })
    .catch(() => {});
};

// Getting Farmers that Agent onBoarded

export const getFarmersThatAgentsOnboarded = userID => dispatch => {
  authChecker();
  const params = {
    userId: userID,
  };
  return farmers
    .get("/agents/farmers", { params })
    .then(farmerOnboardedByAgent => {
      dispatch({
        type: GET_FARMERS_AGENT_ONBOARDED,
        payload: farmerOnboardedByAgent.data,
      });
    })
    .catch(() => {});
};

export const HandlePageAgentOnboard = (userID, pageNumber, pageSize) => dispatch => {
  authChecker();
  const params = {
    userId: userID,
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
  };
  return farmers
    .get("/agents/farmers", { params })
    .then(farmerOnboardedByAgent => {
      dispatch({
        type: GET_FARMERS_AGENT_ONBOARDED,
        payload: farmerOnboardedByAgent.data,
      });

      dispatch({
        type: HANDLE_PAGE_CHANGE_AGENTS_ONBOARD_WHO,
        payload: farmerOnboardedByAgent.data.skip,
      });
    })
    .catch(() => {});
};
