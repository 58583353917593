import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Search from "../../components/Search";
import Spacer from "../../components/common/Spacer";
import { connect } from "react-redux";
import { from_location } from "../../features/common/actions";
import AdminPagination from "../../components/common/Pagination";
import { TableLoader } from "../../components/common/TableLoader";
import DateFilter from "../../components/DatePicker/filter/dateFilter";
import useTranslate from "../../hooks/useTranslate";
import { getAllAdmins, handleAdminPageChange } from "../../features/admin/action";
import AdminTable from "../DataTables/adminTable";

function Admin(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { updateLocation, getAllAdmin, adminTotaldocumentCount, adminDataPageSize, adminCurrentPage, handlePageChange } = props;
  useEffect(() => {
    updateLocation("admin");
    getAllAdmin()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSwitch = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    handlePageChange(pageNumber, pageSize);
  };
  const { t } = useTranslate();
  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30 }}>
          {t("Admins")}
        </Col>
        <DateFilter />
        <Spacer unit={20} />
        {isLoading ? (
          <TableLoader />
        ) : (
          <>
            <Col>
              <Row className="d-flex justify-content-center">
                <Search />
              </Row>
            </Col>
            <Row>
              <Col className="table-responsive-md">
                <AdminTable />
              </Col>
            </Row>
            <Row>
              <AdminPagination
                total={adminTotaldocumentCount}
                pageSize={adminDataPageSize}
                currentPage={adminCurrentPage}
                onPageChange={handlePageSwitch}
                location="admin"
              />
            </Row>
          </>
        )}
      </Col>
    </div>
  );
}
const mapStateToProps = state => ({
  adminData: state.admin.allAdmins.data || [],
  adminTotaldocumentCount: state.admin.allAdmins.totalDocumentCount ?? 0,
  adminDataPageSize: state.admin.allAdmins.limit ?? 10,
  adminPageNumber: state.admin.allAdmins.skip,
  adminCurrentPage: state.admin.currentPage,
});
const mapDispatchToProps = dispatch => {
  return {
    updateLocation: location => dispatch(from_location(location)),
    getAllAdmin: () => dispatch(getAllAdmins()),
    handlePageChange: (pageNumber, pageSize) => dispatch(handleAdminPageChange(pageNumber, pageSize)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
