import React, { useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineDoubleRight, AiOutlineDoubleLeft } from "react-icons/ai";
import _ from "lodash";
import PropTypes from "prop-types";
import { Col } from "reactstrap";

function Pagination(props) {
  const { total, pageSize, onPageChange, currentPage, location } = props;
  const jumpToVal = useRef(null);
  const pagesCount = Math.ceil(total / pageSize);
  if (pagesCount <= 1) return null;
  let start = 1;
  let end = pagesCount + 1;
  if (pagesCount >= 3) {
    if (currentPage + 2 <= pagesCount) {
      start = currentPage;
      end = currentPage + 3;
    } else {
      //determines how many digit should be subbed from the current page when
      //currentPage + 2 is > than pagesCount
      const minusStart =
        pagesCount - currentPage ? pagesCount - currentPage : 2;

      start = currentPage - minusStart;
      end = pagesCount + 1;
    }
  }
  const pages = _.range(1, pagesCount + 1);
  // const pages = useMemo(() => {
  //   _.range(1, pagesCount + 1);
  // }, [pagesCount]);

  const renderedPages = _.range(start, end);
  return (
    <Col className="pagination-container">
      <nav className="pagination-position">
        <ul className="pagination justify-content-end">
          <li className="page-item">
            <span
              className="pagination-control"
              onClick={() => onPageChange(1, pageSize, pages, location)}
            >
              <AiOutlineDoubleLeft />
            </span>
            <span
              className="pagination-control"
              onClick={() =>
                onPageChange(currentPage - 1, pageSize, pages, location)
              }
            >
              <IoIosArrowBack />
            </span>
          </li>
          {renderedPages.map((page) => (
            <li key={page} className="page-item">
              <button
                className={
                  page === currentPage
                    ? "custom-page-item page-active"
                    : "custom-page-item"
                }
                onClick={() => {
                  onPageChange(page, pageSize, pages, location);
                }}
              >
                {page}
              </button>
            </li>
          ))}

          <li className="page-item">
            <span
              className="pagination-control"
              onClick={() =>
                onPageChange(currentPage + 1, pageSize, pages, location)
              }
            >
              <IoIosArrowForward />
            </span>
          </li>

          <li className="page-item">
            <span
              className="pagination-control"
              onClick={() =>
                onPageChange(pagesCount, pageSize, pages, location)
              }
            >
              <AiOutlineDoubleRight />
            </span>
          </li>
          <li className="pagination-jump">
            Jump To
            <select defaultValue={currentPage} ref={jumpToVal}>
              {pages.map((page) => {
                return (
                  <option value={page} key={page}>
                    {page}
                  </option>
                );
              })}
            </select>
            <button
              className="pagination-jump-btn"
              onClick={() => {
                onPageChange(
                  jumpToVal.current.value,
                  pageSize,
                  pages,
                  location
                );
                jumpToVal.current.value = null;
              }}
            >
              submit
            </button>
          </li>
        </ul>
        <p className="pagination-text">{`Page ${currentPage} of ${pagesCount}`}</p>
      </nav>
    </Col>
  );
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
};

export default React.memo(Pagination);
