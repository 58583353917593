import { authChecker } from "../../services/authService";
import subscription from "../../api/subscription";
import output from "../../api/output";
import { toast } from "react-toastify";
import { getDateParam, resetDateRange } from "../../utils/dateParams";

export const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION";
export const HANDLE_PAGE_CHANGE_OUTPUT_MANAGEMENT = "HANDLE_PAGE_CHANGE_OUTPUT_MANAGEMENT";
export const FILTER_SINGLE_SUBSCRIPTION = "FILTER_SINGLE_SUBSCRIPTION";
export const HANDLE_PAGE_CHANGE_FILTER_SINGLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE_FILTER_SINGLE_PAGE_CHANGE";
export const FILTER_SINGE_SUBSCRIPTION_SINGEDATE = "FILTER_SINGE_SUBSCRIPTION_SINGEDATE";
export const HANDLE_SINGLE_DATE_SUBSCRIPTION = "HANDLE_SINGLE_DATE_SUBSCRIPTION";
export const FILTER_SINGLE_SUBSCRIPTION_DATE_RANGE = "FILTER_SINGLE_SUBSCRIPTION_DATE_RANGE";
export const HANDLEPAGE_SWITCH_SEARCH_BY_DATE_RANGE = "HANDLEPAGE_SWITCH_SEARCH_BY_DATE_RANGE";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET PAGINATION VALUE";
export const SET_SELECTED_PARAMS = "SET SELECTED PARAMS";
export const SET_TABLE = "SET TABLE OUTPUT";

export const getAllSubscriptions = () => dispatch => {
  authChecker();
  resetDateRange(dispatch);
  return (
    output
      /// changed from resellers to Subscription.. Please create another action for Reselers
      .get("/outputs")
      .then(outputData => {
        dispatch({
          type: GET_ALL_SUBSCRIPTION,
          payload: outputData.data,
        });
      })
      .catch(() => {
        toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
      })
  );
};

export const updateOutputOffers = async (values, outputId, dispatch) => {
  authChecker();
  const body = { outputId, agents: values };
  return subscription.put("/outputs/offers", body).then(() => {
    if (dispatch) dispatch(getAllSubscriptions());
  });
};

export const setTableOutput = table => dispatch => {
  dispatch({
    type: SET_TABLE,
    payload: table,
  });
};

// Searching for a single output
export const filterSingleSubscriptions = values => (dispatch, getState) => {
  authChecker();
  let { term, key } = values;
  let defaultKey = "outputType";
  const dateParams = getDateParam(getState().common);

  let selectedParam = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    selectedParam = {};
  }

  const params = {
    search: term,
    ...dateParams,
  };

  return (
    output
      /// changed from resellers to Subscription.. Please create another action for Reselers
      .get("/outputs", { params })
      .then(outputData => {
        dispatch({
          type: FILTER_SINGLE_SUBSCRIPTION,
          payload: outputData.data,
        });
        dispatch({
          type: SET_SELECTED_PARAMS,
          payload: selectedParam,
        });
        dispatch({
          type: RESET_PAGINATION_VALUES_AGENTS,
        });
        if (!outputData.data.data.length) {
          toast.error("Output not found");
        }
      })
      .catch(() => {
        toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
      })
  );
};
export const submitSubcription =
  (values, { setErrors, setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    subscription
      .post("/outputs", values)
      .then(res => {
        handleModalToggle();
        if (res.status === 200) {
          toast.success(res.data.message || "Created output successfully");
        }
        dispatch(getAllSubscriptions());
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .then(() => {
        setSubmitting(false);
      });
    return {
      setSubmitting,
      setErrors,
    };
  };

export const updateSubscription =
  (values, { setErrors, setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();

    subscription
      .put("/outputs", values)
      .then(res => {
        handleModalToggle();
        if (res.status === 200) {
          toast.success(res.data.message || "Updated output successfully");
        }
        dispatch(getAllSubscriptions());
      })
      .catch(err => {
        toast.error(err.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
    return {
      setSubmitting,
      setErrors,
    };
  };

export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  const { subscription, common } = getState();
  const dateParams = getDateParam(common);
  const { selectedSearchParam } = subscription;
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    ...selectedSearchParam,
    ...dateParams,
  };
  return output
    .get("/outputs", { params })
    .then(outputData => {
      toast.success(outputData.data.status);
      dispatch({
        type: FILTER_SINGLE_SUBSCRIPTION,
        payload: outputData.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE_OUTPUT_MANAGEMENT,
        payload: outputData.data.skip,
      });
    })
    .catch(() => {
      toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
    });
};

// Deleting Output
export const deleteOutput =
  (values, { setErrors, setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    let { outputId } = values;
    subscription
      .delete("/outputs", { data: { outputId: outputId } })
      .then(res => {
        handleModalToggle();
        if (res.status === 200) {
          toast.success(res.message || "Output deleted");
        }
        dispatch(getAllSubscriptions());
      })
      .catch(err => {
        toast.error(err.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
    return {
      setSubmitting,
      setErrors,
    };
  };
