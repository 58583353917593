import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import Search from "../../components/Search";
import Spacer from "../../components/common/Spacer";
import { TableLoader } from "../../components/common/TableLoader";
import warehouseValue from "../../components/options/warehouse";
import { from_location, handle_modal_state, reset_modal_state } from "../../features/common/actions";
import { getAllBoundServices } from "../../features/inboundService/actions";
import {
  getAllSubscriptions,
  handlePageChange,
  setTableOutput,
  submitSubcription,
  updateSubscription,
} from "../../features/outputmanagement/action";
import { getAllWarehouse } from "../../features/warehouse/action";
import OutputManagementTable from "../DataTables/outputTableManagement";

import DateFilter from "../../components/DatePicker/filter/dateFilter";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import NewOutputMgtModal from "../../components/modal/outputMgtModals/newOutputMgtModal";
import {
  getAllTransaction,
  handlePageChange as handlePageChangeTransaction,
  transactionServiceTypes,
} from "../../features/transaction/actions";
import useTranslate from "../../hooks/useTranslate";
import transactionTable from "../DataTables/transactionTable";
import InnerTab from "./component/innerTabs";
import TableAndPagination from "./component/tableAndPagination";

export const outputTable = {
  OutputManagement: "outputManagement",
  transaction: "transactionOutput",
};
function OutputManagement(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const {
    getAllSubscriptions,
    pageSizeOM,
    handlePageChangeOM,
    currentPageOM,
    totalDocumentCountOM,

    getAllTransaction,
    totalDocumentCountTransaction,
    currentPageTransaction,
    pageSizeTransaction,
    handlePageChangeTransaction,

    reset_modal_state,
    handle_modal_state,
    submitSubcription,
    clickState,
    updateLocation,
    getAllWarehouse,
    warehouses,
    table,
    setTable,
  } = props;
  useEffect(() => {
    reset_modal_state();
    (async () => {
      try {
        await getAllSubscriptions();
        await getAllTransaction(transactionServiceTypes.output);
        await getAllWarehouse();
        updateLocation("outputManagement");
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("tyui", warehouses);

//   const storeValues = useMemo(() => {
//     // Check if the warehouses object exists and contains a non-empty data array
//     if (warehouses && Array.isArray(warehouses.data) && warehouses.data.length > 0) {
//         return warehouses.data.map(warehouse => warehouse.store);
//     }
//     // Return an empty array if there is no valid data array
//     return [];
// }, [warehouses]);


  const handlePageSwitch = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location === "subscription") {
      setLoadingPage(true);
      handlePageChangeOM(pageNumber, pageSize)
        .then(res => setLoadingPage(false))
        .catch(err => setLoadingPage(false));
    } else if (location === "transaction") {
      setLoadingPage(true);
      handlePageChangeTransaction(pageNumber, pageSize)
        .then(res => setLoadingPage(false))
        .catch(err => setLoadingPage(false));
    }
  };
  const handleModalToggle = () => {
    handle_modal_state();
  };
  const handleCancel = () => {
    reset_modal_state();
  };

  const { t } = useTranslate();
  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30, marginTop: 40 }}>
          {t("Output Management")}
        </Col>
        <DateFilter />
        <Spacer unit={20} />
        {loading ? (
          <div>
            <TableLoader />
          </div>
        ) : (
          <>
            <InnerTab
              tabs={[
                {
                  name: "Output Management",
                  isActive: table === outputTable.OutputManagement,
                  onClick: () => setTable(outputTable.OutputManagement),
                },
                {
                  name: "Transactions",
                  isActive: table === outputTable.transaction,
                  onClick: () => setTable(outputTable.transaction),
                },
              ]}
            />

            <div>
              <Col>
                <Row className="d-flex justify-content-center">
                  <Search />
                </Row>
              </Col>

              {table === outputTable.OutputManagement ? (
                <TableAndPagination
                  Table={OutputManagementTable}
                  currentPage={currentPageOM}
                  handlePageSwitch={handlePageSwitch}
                  loadingPage={loadingPage}
                  totalDocumentCount={totalDocumentCountOM}
                  pageSize={pageSizeOM}
                  location="subscription"
                />
              ) : (
                table === outputTable.transaction && (
                  <TableAndPagination
                    Table={transactionTable}
                    currentPage={currentPageTransaction}
                    handlePageSwitch={handlePageSwitch}
                    loadingPage={loadingPage}
                    totalDocumentCount={totalDocumentCountTransaction}
                    pageSize={pageSizeTransaction}
                    location="transaction"
                  />
                )
              )}
            </div>
          </>
        )}
      </Col>
      {props.modalOpen && clickState && (
        <CustomModalWrapper isOpen={props.modalOpen} HeaderTitle={"Create Output Subscription"} toggleModal={handleModalToggle}>
          <NewOutputMgtModal
            memorizeWarehouseValue={warehouses.data}
            handleCancel={handleCancel}
            submitSubscription={submitSubcription}
            toggleModal={handleModalToggle}
          />
        </CustomModalWrapper>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  subscription: state.subscription,
  addSubscription: state.common.clickState,
  modalOpen: state.common.modalOpen,
  clickState: state.common.clickState,
  warehouses: state.warehouse.warehouseData,
  pageSizeOM: state.subscription.subscriptionData.limit || [],
  totalDocumentCountOM: state.subscription.subscriptionData.totalDocumentCount ?? 0,
  currentPageOM: state.subscription.pageNumberOutput,

  //transaction
  totalDocumentCountTransaction: state.transaction.transactionData.totalDocumentCount ?? 0,
  currentPageTransaction: state.transaction.pageNumberTransaction,
  pageSizeTransaction: state.transaction.transactionData.limit,

  table: state.subscription.table,
});

const mapDispatchToProps = dispatch => {
  return {
    getAllTransaction: type => dispatch(getAllTransaction(type)),
    handle_modal_state: () => dispatch(handle_modal_state()),
    reset_modal_state: () => dispatch(reset_modal_state()),
    handlePageChangeOM: (pageNumber, pageSize) => dispatch(handlePageChange(pageNumber, pageSize)),
    getAllSubscriptions: () => dispatch(getAllSubscriptions()),
    submitSubcription: (values, { setErrors, setSubmitting }, handleModalToggle) => {
      dispatch(submitSubcription(values, { setErrors, setSubmitting }, handleModalToggle));
    },
    getAllWarehouse: () => dispatch(getAllWarehouse()),
    updateSubscription: (values, { setErrors, setSubmitting }) => dispatch(updateSubscription(values, { setErrors, setSubmitting })),
    getAllBoundServices: () => dispatch(getAllBoundServices()),
    updateLocation: location => dispatch(from_location(location)),
    setTable: table => dispatch(setTableOutput(table)),
    handlePageChangeTransaction: (pageNumber, pageSize) => dispatch(handlePageChangeTransaction(pageNumber, pageSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputManagement);
