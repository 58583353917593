import React from "react";
import { Col, Row } from "reactstrap";
import Pagination from "../../../components/common/Pagination";
import LoadingAnimation from "../../../components/Loader/Loader";

const TableAndPagination = ({
  Table,
  loadingPage,
  totalDocumentCount,
  pageSize,
  currentPage,
  handlePageSwitch,
  location,
}) => {
  return (
    <>
      <Row>
        <Col className="table-responsive-md">
          <Table />
        </Col>
      </Row>
      <div style={{ marginBottom: "5px" }}>
        {loadingPage ? <LoadingAnimation /> : <></>}
      </div>
      <Row>
        {totalDocumentCount > 10 && (
          <Pagination
            total={totalDocumentCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageSwitch}
            location={location}
          />
        )}
      </Row>
    </>
  );
};

export default TableAndPagination;
