import moment from "moment";
import React from "react";
import { Button, Col, Form, ModalBody, ModalFooter, Row } from "reactstrap";

import Axios from "axios";
import { Formik } from "formik";
import fileDownload from "js-file-download";
import CustomSelectField from "../../Forms/customFields/CustomSelectField";
import LoaderButton from "../../Loader/LoaderButton";
import currencyFormat from "../../lib/AmountFormat";

const MainTransactionDetail = ({
  data,
  createTrackingOptions,
  handleSelectedOption,
  selectedOption,
  toggleModal,
  trackUserTransaction,
  viewTransaction,
}) => {
  const handleDownload = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then(res => {
      fileDownload(res.data, filename);
    });
  };

  return (
    <ModalBody>
      <Formik
        initialValues={{
          transactionId: data._id,
          tracking: "",
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          values.tracking = selectedOption.value;
          trackUserTransaction(values, { setSubmitting, setErrors }, toggleModal);
        }}
      >
        {({ values, handleChange, isSubmitting, errors, handleSubmit }) => (
          <Form>
            <Col>
              <Row className="mb-4 d-flex justify-content-around">
                <Col className="d-flex">
                  <div className="farmer-img-info d-flex flex-column justify-content-center ca-font-regular w-100 align-items-center">
                    <div className="mr-3 mb-3">
                      {viewTransaction && data.outputImageUrl ? (
                        <img src={data.outputImageUrl} width={134} height={134} alt="farmer profile" className="img-circle" />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="form-header-color">{`${data?.agentName}`}</div>

                    <Row className="my-3 d-flex justify-content-between w-100">
                      <Col>
                        <div style={{ display: "flex" }}>
                          <div style={{ color: "#808080" }}>Agent Account:</div>
                          <div style={{ color: "#808080", paddingLeft: "10px" }}>{data?.agentAccount}</div>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <div style={{ color: "#808080", paddingRight: "10px" }}>Agent ID:</div>
                          <div style={{ color: "#808080" }}>{data?.agentId}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-3 d-flex justify-content-between w-100">
                      <Col>
                        <div style={{ display: "flex" }}>
                          <div style={{ color: "#808080" }}>Agent Phone:</div>
                          <div style={{ color: "#808080", paddingLeft: "10px" }}>{data?.agentPhone}</div>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <div style={{ color: "#808080", paddingRight: "10px" }}>Agent Email:</div>
                          <div style={{ color: "#808080" }}>{data?.agentEmail ?? "N/A"}</div>
                        </div>
                      </Col>
                    </Row>
                    {data.tracking === "Approved" && viewTransaction && (
                      <Row className="my-3 d-flex justify-content-between w-100">
                        <div className="w-100 d-flex justify-content-center" style={{ height: "300px", background: "#C4C4C4" }}>
                          <a href={data.gnrImage} style={{ height: "auto", width: "100%" }} download="Gnr">
                            <img
                              src={data.gnrImage}
                              alt="GNR_image"
                              className="w-100 h-100"
                              style={{
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                            />
                          </a>
                        </div>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="my-3 d-flex justify-content-around">
                <Col>
                  <hr className="hr-style-top" />
                  <div className="form-header-color ca-font-regular sub-header-form m-1 text-center">Transaction Activities</div>
                  <hr className="hr-style" />
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Requested Item</div>
                  <div className="view-profile-fieldstyle">{data.inputName || data.outputName || "Not avaliable"}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Units Purchased</div>
                  <div className="view-profile-fieldstyle">{data.units || data.estimatedUnits || 0}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Per Unit Price</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.perUnitPrice)}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Status</div>
                  <div className="view-profile-fieldstyle">{data.status}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Service Type</div>
                  <div className="view-profile-fieldstyle">{data.serviceType}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Transaction Reference</div>
                  <div className="view-profile-fieldstyle">{data.transactionReference}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Transaction ID</div>
                  <div className="view-profile-fieldstyle">{data.transactionId}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Transaction Type</div>
                  <div className="view-profile-fieldstyle">{data.transactionType}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Narration</div>
                  <div className="view-profile-fieldstyle">{data.narration}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Message</div>
                  <div className="view-profile-fieldstyle">{data.message}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Paid At</div>
                  <div className="view-profile-fieldstyle">{moment(data.trackingDate || data.initiatedAt).format("DD MMMM YYYY")}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Pre-Wallet-Balance</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.preWalletBalance || 0)}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Post-Wallet-Balance</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.postWalletBalance || 0)}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Transaction Narrative</div>
                  <div className="view-profile-fieldstyle">{data.transactionNarrative || "Not avaliable"}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Tracking Reference</div>
                  <div className="view-profile-fieldstyle">{data.trackingReference}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Transaction ID</div>
                  <div className="view-profile-fieldstyle">{data.transactionId}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Amount</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.amount || 0)}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Chargeable Amount</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.chargeableAmount || 0)}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Estimated Amount</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.estimatedAmount || 0)}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Amount Paid</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.amountPaid || 0)}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Agent Address</div>
                  <div className="view-profile-fieldstyle">{data.agentAddress}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Tracking</div>
                  <div className="view-profile-fieldstyle">{data.tracking || "Not Avaliable"}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">DeliveryLocation</div>
                  <div className="view-profile-fieldstyle">
                    {data.inputDeliveryLocation || data.outputPickupLocation || "Not Avaliable"}
                  </div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">warehouse Number</div>
                  <div className="view-profile-fieldstyle">{data.warehouseNumber || "Not Avaliable"}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Produce Weight(Kg)</div>
                  <div className="view-profile-fieldstyle">{data.outputWeight_in_unitOfMeasurement || "N/A"}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Dirt Ratio(%)</div>
                  <div className="view-profile-fieldstyle">{data.output_dirtRatioPercentage || "Not Avaliable"}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Mositure content(%)</div>
                  <div className="view-profile-fieldstyle">{data.outputWeight_in_unitOfMeasurement || "N/A"}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Dirt Ratio</div>
                  <div className="view-profile-fieldstyle">{data.output_moistureContentPercentage || "Not Avaliable"}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Grain Type</div>
                  <div className="view-profile-fieldstyle">{data.output_typeOfGrain || "Not Avaliable"}</div>
                </Col>{" "}
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Delivery Cost</div>
                  <div className="view-profile-fieldstyle">{currencyFormat(data.input_delivery_cost)}</div>
                </Col>
              </Row>

              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Tracking Date</div>
                  <div className="view-profile-fieldstyle">{moment(data.trackingDate || data.initiatedAt).format("DD MMMM YYYY")}</div>
                </Col>
                <Col className="d-flex align-items-center flex-column">
                  <div className="view-profile-subheader">Transaction Reference</div>
                  <div className="view-profile-fieldstyle">{data.transactionReference || "Not Avaliable"}</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3 mb-3">
                <Col className="d-flex align-items-center flex-column"></Col>
              </Row>
              {!viewTransaction && (
                <Row className="mb-3 d-flex justify-content-around ca-font-regular" style={{ paddingTop: 20 }}>
                  <Col className="d-flex align-items-center flex-column">
                    <CustomSelectField
                      label={"Track Transaction"}
                      onChange={handleSelectedOption}
                      options={createTrackingOptions}
                      selectValue={selectedOption}
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <ModalFooter>
              {!viewTransaction && (
                <LoaderButton
                  className="form-btn-1 ca-font-regular"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  type="submit"
                  text="Track Transaction"
                  style={{
                    marginRight: "10px",
                    width: "180px",
                    borderRadius: "0px 8px",
                    backgroundColor: "#3bc552",
                  }}
                  id="mc-embedded-subscribe"
                />
              )}
              {data.tracking === "Approved" && viewTransaction && (
                <Button
                  onClick={() => {
                    const url = data?.gnrImage?.split(".");
                    const fileExtension = url?.pop();

                    handleDownload(data?.gnrImage, `GNR_image.${fileExtension}`);
                  }}
                  className="form-btn-1"
                >
                  GNR image
                </Button>
              )}

              <Button className="form-btn-2 ca-font-regular" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalBody>
  );
};

export default React.memo(MainTransactionDetail);
