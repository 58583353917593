import { toast } from "react-toastify";
import subscription from "../../api/subscription";
import { authChecker } from "../../services/authService";
import { resetDateRange } from "../../utils/dateParams";

export const CHANGE_RESELLER_ACTIVE = "TOGGLE_RESELLER_ACTIVE";
export const GET_ALL_RESELLER = "CREATE_BOUND_SERVICE";
export const GET_ALL_RESELLER_REQUEST = "GET_ALL_RESELLER_REQUEST";
export const SUCESSFUL_APPROVAL = "SUCESSFUL_APPROVAL";
export const HANDLE_PAGE_CHANGE_GET_ALL_RESELLER = "HANDLE_PAGE_CHANGE_GET_ALL_RESELLER";
export const HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST = "HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST";
export const HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST_FILTER = "HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST_FILTER";

export const changeResellerActive = value => dispatch => {
  dispatch({
    type: CHANGE_RESELLER_ACTIVE,
    payload: value,
  });
};
export const getAllReseller = () => dispatch => {
  authChecker();
  resetDateRange(dispatch);
  return subscription
    .get("/resellers")
    .then(resellerData => {
      dispatch({
        type: GET_ALL_RESELLER,
        payload: resellerData.data,
      });
    })
    .catch(err => {
      toast.error("Something went wrong fetching Inbound services, try refreshing the page");
    });
};

export const getAllResellerRequest = () => dispatch => {
  authChecker();
  return subscription
    .get("/resellers/request")
    .then(resellerRequestData => {
      dispatch({
        type: GET_ALL_RESELLER_REQUEST,
        payload: resellerRequestData.data,
      });
    })
    .catch(() => {
      toast.error("Something went wrong fetching Inbound services, try refreshing the page");
    });
};
// handle company approval
export const handleCompanyApproval =
  (values, { setErrors, setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    subscription
      .put("/resellers/approval", values)
      .then(res => {
        handleModalToggle();
        if (res.data.status === "success") {
          toast.success(res.data.message);
          return Promise.resolve({ message: res.data.message });
        }
        dispatch({
          type: SUCESSFUL_APPROVAL,
          payload: res.data,
        });
      })
      .catch(err => {
        toast.error(err.response.data.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
    return {
      setSubmitting,
      setErrors,
    };
  };
// Query Reseller Request
export const getAllResellerFilter = newValue => dispatch => {
  authChecker();
  const params = {
    approved: newValue,
  };
  return subscription
    .get("/resellers/request", { params })
    .then(resellerData => {
      dispatch({
        type: GET_ALL_RESELLER_REQUEST,
        payload: resellerData.data,
      });
      if (resellerData.data.data.length <= 0) {
        toast.error("No company found with the search parameter");
      }
    })
    .catch(() => {
      toast.error("Something went wrong fetching Inbound services, try refreshing the page");
    });
};
// Handle Pagniation All Reseller
export const HandlePageChangeTradrReseller = (pageNumber, pageSize) => dispatch => {
  authChecker();
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
  };
  return (
    subscription
      /// changed from resellers to Subscription.. Please create another action for Reselers
      .get("/resellers", { params })
      .then(resellerData => {
        dispatch({
          type: GET_ALL_RESELLER,
          payload: resellerData.data,
        });
        dispatch({
          type: HANDLE_PAGE_CHANGE_GET_ALL_RESELLER,
          payload: resellerData.data.skip,
        });
      })
      .catch(() => {
        toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
      })
  );
};

// Handle Pagniation All Reseller
export const HandlePageChangeTradrResellerRQ = (pageNumber, pageSize, values) => dispatch => {
  authChecker();
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
  };
  return (
    subscription
      /// changed from resellers to Subscription.. Please create another action for Reselers
      .get("/resellers/request", { params })
      .then(resellerData => {
        dispatch({
          type: GET_ALL_RESELLER_REQUEST,
          payload: resellerData.data,
        });
        dispatch({
          type: HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST,
          payload: resellerData.data.skip,
        });
      })
      .catch(() => {
        toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
      })
  );
};
// handle change for filter
export const HandlePageChangeTradrResellerRQFilter = (pageNumber, pageSize) => dispatch => {
  authChecker();
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    approved: true,
  };
  return (
    subscription
      /// changed from resellers to Subscription.. Please create another action for Reselers
      .get("/resellers/request", { params })
      .then(resellerData => {
        dispatch({
          type: GET_ALL_RESELLER_REQUEST,
          payload: resellerData.data,
        });
        dispatch({
          type: HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST_FILTER,
          payload: resellerData.data.skip,
        });
      })
      .catch(() => {
        toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
      })
  );
};
