import React from "react";
import { lgaObj, stateVals } from "../../options/stateAndLga";
import CustomSelectField, { setSelectValue } from "./CustomSelectField";

const CustomState = ({ label, onChange, selectValue,...props }) => {
  return (
    <>
      <CustomSelectField
        label={label}
        options={stateVals}
        onChange={onChange}
        selectValue={selectValue}
        {...props}
      />
    </>
  );
};

const CustomLga = ({ label, state, onChange, selectValue,...props }) => {
  return (
    <CustomSelectField
      label={label}
      options={lgaObj[state]}
      onChange={onChange}
      selectValue={selectValue}
      {...props}
    />
  );
};
const CustomSelectLocation = (
  stateLabelKey,
  lgaLabelKey,
  setFieldValue,
  state,
  lga
) => {
  return {
    CustomLgaSelect: (
      <CustomLga
        label={lgaLabelKey.label}
        onChange={(option) => setFieldValue(lgaLabelKey.value, option.value)}
        selectValue={lgaObj[state] ? setSelectValue(lgaObj[state], lga) : ""}
        state={state}
        required={lgaLabelKey.required}
      />
    ),
    CustomStateSelect: (
      <CustomState
        label={stateLabelKey.label}
        onChange={(option) => setFieldValue(stateLabelKey.value, option.value)}
        selectValue={stateVals ? setSelectValue(stateVals, state) : ""}
        required={stateLabelKey.required}
      />
    ),
  };
};

export default CustomSelectLocation;
