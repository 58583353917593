import React, { useState, useRef, useEffect } from "react";
import useTranslate from "../../../hooks/useTranslate";
import CustomSelectField, { setSelectValue } from "./CustomSelectField";

const CustomSelectLanguage = () => {
  const [language, setLanguageState] = useState(null);
  const { i18n } = useTranslate();

  const options = useRef([
    { value: "en", label: "English" },
    { value: "ha", label: "Hausa" },
  ]);

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    window.localStorage.setItem("lang", lang);
    setLanguageState(setSelectValue(options.current, lang));
  };

  useEffect(() => {
    const storageLanguage = window.localStorage.getItem("lang");
    const value = setSelectValue(options.current, storageLanguage);
    setLanguageState(value);
  }, []);

  return (
    <CustomSelectField
      options={options.current}
      onChange={(e) => setLanguage(e.value)}
      selectValue={language}
    />
  );
};

export default CustomSelectLanguage;
