import {
  GET_ALL_AGENTS,
  CHANGE_PAGE_SIZE_AGENTS,
  HANDLE_PAGE_CHANGE_AGENTS,
  CHANGE_OFFSET_VALUE_AGENTS,
  SET_INFO_TO_DISPLAY_AGENTS,
  GET_ALL_FARMERS_BY_SEARCHING,
  CHANGE_SELECTED_SEARCH_PARAM,
  RESET_PAGINATION_VALUES_AGENTS,
} from "./actions";

const initialState = {
  agentData: [],
  pageSize: 10,
  pageNumberAgent: 1,
  offset: 0,
  info: [],
  searchParams: [
    { value: "firstName", label: "first name" },
    { value: "lastName", label: "last name" },
    { value: "phone", label: "Phone" },
    { value: "state", label: "State" },
    { value: "lga", label: "LGA" },
  ],
  selectedSearchParam: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_AGENTS:
      return {
        ...state,
        agentData: action.payload,
        pageNumberAgent: 1,
        selectedSearchParam: {},
      };
    case CHANGE_PAGE_SIZE_AGENTS:
      return { ...state, pageSize: action.payload };

    case CHANGE_SELECTED_SEARCH_PARAM:
      return { ...state, selectedSearchParam: action.payload };

    case HANDLE_PAGE_CHANGE_AGENTS:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberAgent: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberAgent: pageNumberOld,
        };
      }
      return { ...state };
    case CHANGE_OFFSET_VALUE_AGENTS:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY_AGENTS:
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize);
      return { ...state, info: infoToDisplay };
    case RESET_PAGINATION_VALUES_AGENTS:
      return { ...state, pageNumberAgent: 1, searchingPageNumber: 1 };
    case GET_ALL_FARMERS_BY_SEARCHING:
      return {
        ...state,
        agentData: action.payload,
      };
    default:
      return state;
  }
};
