import React, { useEffect, useMemo, useRef, useState } from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import LoadingAnimation from "../../components/Loader/Loader";
import { from_location } from "../../features/common/actions";
import LineGraph from "../../components/Charts/lineChart";
import CustomSelectField from "../../components/Forms/customFields/CustomSelectField";
import outputCategory from "../../components/jsonData/outPutCategory.json";
import inputCategory from "../../components/jsonData/inputCategory.json";
import CustomDateRange from "../../components/DatePicker/customDateRange";
import { getInitialData, onChange } from "../../features/overviewGraph/action";
import OverviewReportComponent from "./component/overviewReportComponent";
import currencyFormat from "../../components/lib/AmountFormat";

function Overview(props) {
  const [transactionAmount, setTransactionAmount] = useState(0);
  const { transactionData, getInitialData, onChange, startDate, endDate, isLoading, totalDocumentCount, averagePricePerTon } = props;
console.log({transactionData});
  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  let input = useRef([]);
  let output = useRef([]);
  useMemo(() => {
    inputCategory.map(item => (input.current = [...input.current, ...item.inputCategory.inputType]));
    outputCategory.map(item => (output.current = [...output.current, ...item.outputCategory.outputType]));
  }, []);

  const handleChange = (key, value) => {
    const change = { [key]: value };
    onChange(change);
  };

  const handleDateChange = value => {
    delete value?.selection?.key;

    onChange(value.selection);
  };

  const serviceTypeOptions = [
    {
      value: null,
      label: "All",
    },
    {
      value: "input",
      label: "Input",
    },
    {
      value: "output",
      label: "Output",
    },
    {
      value: "withdraw",
      label: "Withdraw",
    },
    {
      value: "fund",
      label: "Fund",
    },
  ];

  const trackingOptions = [
    {
      value: null,
      label: "All",
    },
    {
      value: "Approved",
      label: "approved",
    },
    {
      value: "Delivered",
      label: "delivered",
    },
    {
      value: "Processing",
      label: "processing",
    },
  ];
  const averageWeight = useMemo(() => {
    console.log({transactionAmount},{averagePricePerTon});
    const result = transactionAmount / averagePricePerTon;
    if (result) return result.toFixed(2);
  }, [transactionAmount, averagePricePerTon]);

  return (
    <div className="overview-content">
      <Row>
        {isLoading && <LoadingAnimation />}
        <div className={`${isLoading ? "d-none" : "d-flex"} w-100 justify-content-between align-items-center`} style={{ padding: "40px" }}>
          <div className="d-flex align-items-center" style={{ gap: "30px" }}>
            <div>
              <CustomSelectField
                label={"Crop Type"}
                onChange={option => {
                  handleChange("cropType", option.value);
                }}
                options={[
                  {
                    value: null,
                    label: "All",
                  },
                  ...input.current,
                  ...output.current,
                ]}
              />
            </div>
            <div>
              <label>Date Range:</label>
              <CustomDateRange
                startDate={startDate}
                endDate={endDate}
                right={false}
                onChange={value => {
                  handleDateChange(value);
                }}
              />
            </div>
          </div>

          <div className="d-flex align-items-center" style={{ gap: "30px" }}>
            <div>
              <CustomSelectField label={"Tracking"} onChange={option => handleChange("tracking", option.value)} options={trackingOptions} />
            </div>
            <div>
              <CustomSelectField
                label={"Service Type"}
                onChange={option => handleChange("serviceType", option.value)}
                options={serviceTypeOptions}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center flex-wrap w-100" style={{ gap: "10px", margin: "40px" }}>
          <OverviewReportComponent title={"Total Number of Transactions"} value={totalDocumentCount} />
          <OverviewReportComponent title={"Total Transaction Amount"} value={currencyFormat(transactionAmount)} />
          {/* <OverviewReportComponent title={"Average weight"} value={averageWeight ? `${averageWeight} Tons` : "N/A"} /> */}
          <OverviewReportComponent title={"Average Price Per Ton"} value={currencyFormat(averagePricePerTon)} />
        </div>
        <div className="w-100" style={{ height: "250px", marginTop: "30px" }}>
          <LineGraph
            data={transactionData}
            startDate={startDate ?? new Date("2022-04-01")}
            endDate={endDate}
            setAmount={setTransactionAmount}
          />
        </div>
      </Row>
    </div>
  );
}
const mapStateToProps = state => ({
  transactionData: state.overviewGraph.transactionData?.data ?? [],
  startDate: state.overviewGraph.startDate,
  endDate: state.overviewGraph.endDate,
  cropType: state.overviewGraph.cropType,
  serviceType: state.overviewGraph.serviceType,
  isLoading: state.overviewGraph.isLoading,
  totalDocumentCount: state.overviewGraph.transactionData?.totalDocumentCount ?? 0,
  totalWeight: state.overviewGraph.transactionData?.totalWeight ?? 0,
  averagePricePerTon: state.overviewGraph.transactionData?.averageUnitPrice ?? 0,
});

const mapDispatchToProps = dispatch => {
  return {
    updateLocation: location => dispatch(from_location(location)),

    getInitialData: () => dispatch(getInitialData()),
    onChange: value => dispatch(onChange(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
