import React from "react";
import DatePicker from "react-date-picker";
import { Col, Row } from "reactstrap";
import { setDateRange, setSingleDate } from "../../../features/common/actions";

import { connect } from "react-redux";
import CustomDateRange from "../customDateRange";
import useTranslate from "../../../hooks/useTranslate";

const DateFilter = ({
  startDate,
  endDate,
  singleDate,
  setDateRange,
  setSingleDate,
}) => {
  const handleSingleChangeDate = (date) => setSingleDate(date);
  const handleRangeSet = (range) => {
    const { startDate, endDate } = range.selection;
    setDateRange({ startDate, endDate });
  };
  const { t } = useTranslate();
  return (
    <Row className="d-flex justify-content-end">
      <Col className="d-flex col-3" style={{ flexDirection: "column" }}>
        <div>
          <p>{t("Date")}</p>
        </div>
        <div>
          <DatePicker
            onChange={handleSingleChangeDate}
            value={singleDate}
            format="dd.MM.y"
            clearIcon={null}
          />
        </div>
      </Col>
      <Col
        className="d-flex col-3"
        style={{ flexDirection: "column", gap: "1rem" }}
      >
        <div>{t("Date Range")}</div>
        <CustomDateRange
          endDate={endDate}
          startDate={startDate}
          onChange={handleRangeSet}
          right="50px"
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    singleDate: state.common.singleDate,
    startDate: state.common.startDate,
    endDate: state.common.endDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDateRange: (range) => {
      dispatch(setDateRange(range));
    },
    setSingleDate: (date) => {
      dispatch(setSingleDate(date));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
