import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { getAgentFromID } from "../../../features/farmers/actions";
import ResultComponent from "../../../views/DataTables/component/resultComponent";
import SelectedComponent from "../../../views/DataTables/component/selectedComponent";
import CustomHTMLTextField from "../../Forms/customFields/CustomHTMLTextField";
import LoaderButton from "../../Loader/LoaderButton";

const AssignAgent = ({ selectedObj, handleModalToggle, onSubmitAgents }) => {
  const [isLoading, setLoading] = useState(false);
  const [searchResult, setResult] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [selectedAgent, setSelectedAgent] = useState([]);
  const selectedAgentRef = useRef({});

  const timeout = useRef();

  const onSelectedAgent = agent => {
    selectedAgentRef.current[agent._id] = true;
    setSelectedAgent(prev => [...prev, agent]);
  };

  useEffect(() => {
    if (selectedObj?.agents) {
      selectedObj.agents.forEach(agent => {
        selectedAgentRef.current[agent._id] = true;
      });
      setSelectedAgent(selectedObj.agents ?? []);
    }
  }, [selectedObj]);

  const onSubmit = value => {
    (async () => {
      try {
        setLoading(true);
        const result = await getAgentFromID(value);
        setResult(result?.data ?? []);
        setLoading(false);
      } catch (e) {
        toast.error("Something went wrong fetching Agents, try refreshing the page");
      }
    })();
  };
  const onChange = e => {
    const value = e.target.value;
    setTextValue(value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (value) {
        onSubmit(value);
      }
    }, 1000);
  };

  const clearText = () => {
    if (timeout.current) clearTimeout(timeout.current);
    setTextValue("");
    setResult([]);
  };

  const dispatch = useDispatch();

  return (
    <>
      <ModalBody>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <CustomHTMLTextField value={textValue} handleChange={onChange} onClear={clearText} />
          {textValue.length > 0 && (
            <ResultComponent
              results={searchResult}
              loading={isLoading}
              dataFields={["firstName", "lastName", "agentId", "adminId"]}
              ref={selectedAgentRef}
              onSelect={onSelectedAgent}
            />
          )}
          {selectedAgent.length > 0 && (
            <SelectedComponent
              onDelete={agent => {
                setSelectedAgent(prev => {
                  const newValue = prev.filter(item => item._id !== agent._id);
                  return newValue;
                });
                delete selectedAgentRef.current[agent._id];
              }}
              dataFields={["firstName", "lastName", "agentId", "adminId"]}
              selected={selectedAgent}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <LoaderButton
          type="search"
          isLoading={isLoading}
          className="btn-primary"
          text="Update Agent"
          style={{
            marginRight: "10px",
            width: "150px",
            borderRadius: "0px 8px",
            backgroundColor: "#3bc552",
          }}
          id="mc-embedded-subscribe"
          onClick={() => {
            onSubmitAgents(Object.keys(selectedAgentRef.current), selectedObj._id, dispatch)
              .then(() => {
                toast.success("Agents updated successfully");
                handleModalToggle();
              })
              .catch(err => {
                toast.error(err.message ?? "Something went wrong updating agents");
                setLoading(false);
              });
          }}
        />
        <Button className="form-btn-2 ca-font-regular" onClick={() => handleModalToggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

export default AssignAgent;
