import admin from "../../api/admin";
import admin2 from "../../api/admin2";
import authService from "../../services/authService";
import history from "../../history";
import { toast } from "react-toastify";

export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const SIGN_OUT = "SIGN_OUT";
export const CREATE_ADMIN = "CREATE_ADMIN";

export const login =
  (data, { setErrors, setSubmitting }) =>
  dispatch => {
    admin
      .post("/login", data)
      .then(res => {
        if (res.status === 200) {
          authService.setToken(res.data.access_token);
          dispatch({ type: FETCH_USER_DATA, payload: res.data.data });
          history.push("/dashboard/");
        } else {
          authService.unauthorized("Session expired, please sign in again");
          return Promise.reject("Session expired, please sign in again");
        }
      })
      .catch(err => {
        setErrors({
          message: err.message || err.response.data.message || err.response.data.error,
        });
      })
      .then(() => {
        setSubmitting(false);
      });
    return {
      setSubmitting,
      setErrors,
    };
  };

export const logout = () => dispatch => {
  authService.signOut();
  dispatch({ type: SIGN_OUT });
};

export const fetchUserData = () => dispatch => {
  authService.authChecker()
    ? admin
        .get("/find")
        .then(res => {
          dispatch({ type: FETCH_USER_DATA, payload: res.data.data });
        })
        .catch(err => {
          authService.unauthorized(err);
          return Promise.reject(err);
        })
    : authService.signOut();
};

export const submitAdmincredentials =
  (values, { setSubmitting, setErrors }) =>
  dispatch => {
    admin2
      .post("/createSuperadmin", values)
      .then(res => {
        if (res.status === 200) {
          toast.success(res.data.message);
        }
      })
      .catch(() => {})
      .then(() => {
        setSubmitting(false);
      });

    return {
      setSubmitting,
      setErrors,
    };
  };
