import inputCategory from "../../components/jsonData/inputCategory.json";
import { nestedInputCategoryArrayToObject } from "../../utils/nestedInputCategory";
export const CategoryValues = inputCategory.map((item, id) => {
  return {
    value: item.inputCategory.name,
    label: item.inputCategory.name,
    catergoryId: id,
  };
});

export const categoryObj = nestedInputCategoryArrayToObject(
  inputCategory,
  "inputCategory",
  "inputType"
);