import outputCategory from "../../components/jsonData/outPutCategory.json";
import { nestedInputCategoryArrayToObject } from "../../utils/nestedInputCategory";
export const outPutCategoryValues = outputCategory.map((item, id) => {
  return {
    value: item.outputCategory.name,
    label: item.outputCategory.name,
    image: item.outputCategory.ImageTitle,
    catergoryId: id,
  };
});
export const outputCategoryObj = nestedInputCategoryArrayToObject(
  outputCategory,
  "outputCategory",
  "outputType"
);
