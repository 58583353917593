import { ErrorMessage, Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaRegEnvelope, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import LoaderButton from "../../../components/Loader/LoaderButton";
import { login } from "../../../features/auth/actions";
import Spacer from "../../common/Spacer";
import "./Login.scss";

const LoginForm = ({ login }) => {
  const [, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={{ phone: "", pin: "" }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        setIsLoading(true);
        login(values, { setErrors, setSubmitting });
      }}
    >
      {({ errors, isSubmitting }) => (
        <div className="login-form-style">
          <Form>
            <h1 className="form-header">Login to Tradr</h1>
            <Spacer unit={20} />
            <p>Enter your details below</p>
            <Spacer unit={54} />
            <Row className="d-flex flex-column form-group">
              <Col>
                <label htmlFor="phone" className="auth-label-size">
                  Tradr ID
                </label>
              </Col>
              <Col className="position-relative">
                <FaRegEnvelope className="position-absolute form-icon" style={{ top: 10, left: 25 }} />
                <Field name="phone" type="text" className="form-control pl-5" style={{ borderRadius: "0px 8px" }} />
                <span className="text-danger mt-1 mx-2">{errors.message}</span>
              </Col>
              <Col>
                <ErrorMessage name="phone" component="span" className="text-danger" />
              </Col>
            </Row>
            <br />
            <Row className="d-flex flex-column form-group">
              <Col>
                <label htmlFor="password" className="auth-label-size">
                  Password
                </label>
              </Col>
              <Col className="position-relative">
                <IoIosLock className="position-absolute form-icon" style={{ top: 10, left: 25 }} />
                <Field
                  name="pin"
                  type={showPassword ? "text" : "password"}
                  className="form-control pl-5"
                  style={{ borderRadius: "0px 8px" }}
                />
                {showPassword ? (
                  <FaRegEye
                    className="position-absolute show-password"
                    style={{ top: 10, right: 25 }}
                    onClick={() => handleShowPassword()}
                  />
                ) : (
                  <FaRegEyeSlash
                    className="position-absolute form-icon show-password"
                    style={{ top: 10, right: 25 }}
                    onClick={() => handleShowPassword()}
                  />
                )}
                <Col>
                  <ErrorMessage name="pin" component="span" className="text-danger" />
                </Col>
                <Row className="d-flex justify-content-end mr-1 mt-1">
                  <Link to="#" className="forgot-pword">
                    Forgot your password?
                  </Link>
                </Row>
              </Col>
              <Spacer unit={30} />
              <Col>
                <LoaderButton
                  type="submit"
                  isLoading={isSubmitting}
                  className="btn-primary"
                  text="Login"
                  style={{
                    marginRight: "10px",
                    width: "140px",
                    borderRadius: "0px 8px",
                    backgroundColor: "#3bc552",
                  }}
                  id="mc-embedded-subscribe"
                />
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    login: (values, { setErrors, setSubmitting }) => dispatch(login(values, { setErrors, setSubmitting })),
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
