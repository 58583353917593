import {
  GET_ALL_FARMERS,
  LOCK_USER,
  ASSIGN_ROLE,
  HANDLE_PAGE_CHANGE,
  UNLOCK_USER,
  SEARCH,
  GET_FARMERS_AGENT_ONBOARDED,
  HANDLE_PAGE_CHANGE_AGENTS_ONBOARD_WHO,
  SUCCESS_UNLOCK_USER,
  CHANGE_SELECTED_PARAM_AGENT,
  RESET_PAGINATION_VALUES_AGENTS,
  } from "./actions";

const initialState = {
  farmerData: [],
  pageSize: 10,
  pageNumberFarmers: 1,
  lockData: [],
  assignRoleDate: [],
  unlockData: [],
  agentsOnboardedByFarmers: [],
  agentsOnboardedByFarmersCurrentPage: 1,
  unLockSuccessful: 0,
  searchParamsAgent: [
    { value: "firstName", label: "first name" },
    { value: "lastName", label: "last name" },
    { value: "phone", label: "Phone" },
    { value: "agentId", label: "Agent ID" },
    { value: "state", label: "State" },
    { value: "lga", label: "LGA" },
  ],
  selectedSearchParamsAgent: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
   
    
    case GET_ALL_FARMERS:
      return {
        ...state,
        farmerData: action.payload,
        selectedSearchParamsAgent: {},
        pageNumberFarmers: 1,
  
      };
    case CHANGE_SELECTED_PARAM_AGENT:
      return {
        ...state,
        selectedSearchParamsAgent: action.payload,
      };

    case HANDLE_PAGE_CHANGE:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberFarmers: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberFarmers: pageNumberOld,
        };
      }
      return { ...state };
    case LOCK_USER:
      return { ...state, lockData: action.payload };
    case UNLOCK_USER:
      return { ...state, unlockData: action.payload };
    case ASSIGN_ROLE:
      return { ...state, assignRoleDate: action.payload };
    case SEARCH:
      return {
        ...state,
        farmerData: action.payload,
        pageNumberFarmers: 1,
      };
    case RESET_PAGINATION_VALUES_AGENTS:
      return {
        ...state,
        pageNumberFarmers: 1,
        pageNumber: 1,
        pageNumberSearchedByAgent: 1,
      };
    case GET_FARMERS_AGENT_ONBOARDED:
      return {
        ...state,
        agentsOnboardedByFarmers: action.payload,
      };
    case HANDLE_PAGE_CHANGE_AGENTS_ONBOARD_WHO:
      {
        const pageNumberOnboardWho = action.payload;
        if (pageNumberOnboardWho === 0) {
          let newPage = 1;
          return {
            ...state,
            agentsOnboardedByFarmersCurrentPage: newPage,
          };
        }
        if (pageNumberOnboardWho >= 10) {
          let newPage = pageNumberOnboardWho / 10;
          let newAgentPage = newPage + 1;
          return {
            ...state,
            agentsOnboardedByFarmersCurrentPage: newAgentPage,
          };
        }
      }
      return { ...state };
    case SUCCESS_UNLOCK_USER: {
      return {
        ...state,
        unLockSuccessful: action.payload,
      };
    }
    default:
      return state;
  }
};
