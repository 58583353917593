import React from "react";
import { agentOTPPin } from "../../components/Table/headers";
import { connect } from "react-redux";
import CustomTable from "../../components/Table";
import { agentOtpBody } from "../../components/Table/body";
const AgentOTPTable = ({ requestedOTP, pageNumber, pageSize, ...props }) => {
  return (
    <>
      <CustomTable
        bodyArray={agentOtpBody(requestedOTP)}
        headerArr={agentOTPPin}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  requestedOTP: state.agentOTP.allOTPRequested.data || [],
  pageNumber: state.agentOTP.allOTPRequested.skip,
  pageSize: state.agentOTP.allOTPRequested.limit,
  totalDocumentCount: state.agentOTP.allOTPRequested.totalDocumentCount,
  // for Range searched Date
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentOTPTable);
