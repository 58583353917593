import {
  ADD_BUTTON_CLICKED,
  MODAL_STATE,
  RESET_MODAL_STATE,
  FROM_LOCATION,
  SET_DATE_RANGE,
  SET_SINGLE_DATE,
  SET_TRANSACTION_CLICKED,
} from "./actions";

const initialState = {
  clickState: false,
  modalOpen: false,
  location: "",
  startDate: null,
  endDate: new Date(),
  singleDate: null,
  modalName: null,
  transactionSelected: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGLE_DATE:
      return {
        ...state,
        singleDate: action.payload,
        startDate: null,
        endDate: new Date(),
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        startDate: action.payload?.startDate,
        endDate: action.payload?.endDate,
        singleDate: null,
      };
    case ADD_BUTTON_CLICKED:
      return { ...state, clickState: action.payload };
    case MODAL_STATE:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        modalName: action.payload,
      };
    case FROM_LOCATION:
      return { ...state, location: action.payload };
    case SET_TRANSACTION_CLICKED:
      return { ...state, transactionSelected: action.payload };
    case RESET_MODAL_STATE:
      return {
        ...state,
        clickState: false,
        modalOpen: false,
        modalName: null,
      };
    default:
      return state;
  }
};
