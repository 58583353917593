import React from "react";
import { FiPlus } from "react-icons/fi";
import { add_button_toggle } from "../../features/common/actions";
import { connect } from "react-redux";

function AddEntry(props) {
  const { add_button_toggle, location } = props;
  const handleClickState = () => {
    add_button_toggle(true);
    if (props.handleModalShow) {
      props.handleModalShow();
    }
  };

  return (
    <div>
      {location === "agents" ? null : location ===
        "Farmers" ? null : location === "InputMangement" ? (
        <FiPlus onClick={handleClickState} className="add-entry-btn" />
      ) : location === "outputManagement" ? (
        <FiPlus onClick={handleClickState} className="add-entry-btn" />
      ) : location === "warehouse" ? (
        <FiPlus onClick={handleClickState} className="add-entry-btn" />
      ) : location === "transaction" ? null : location ===
        "reseller" ? null : location === "overview" ? null : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  location: state.common.location,
});

const mapDispatchToProps = (dispatch) => {
  return {
    add_button_toggle: (clickState) => dispatch(add_button_toggle(clickState)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEntry);
