import React from "react";

import { FaCircle } from "react-icons/fa";
export default function OptionBtn({ onClick }) {
  return (
    <span className="option-btn" onClick={onClick}>
      <FaCircle size={4} className="my-1" />

      <FaCircle size={4} />

      <FaCircle size={4} className="my-1" />
    </span>
  );
}
