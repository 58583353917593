import { authChecker } from "../../services/authService";
import { toast } from "react-toastify";
import supplier from "../../api/supplier";
import { getDateParam, resetDateRange } from "../../utils/dateParams";
import warehouse from "../../api/warehouse";
import admin from "../../api/supplier";

export const GET_ALL_SUPPLIER = "GET_ALL_SUPPLIER";
export const HANDLE_PAGE_CHANGE = "PAGE CHANGE SUPPLIER";
export const RESET_PAGINATION_VALUES_AGENTS = "REST PAGINATION VALUE SUPPLIER";
export const SET_SELECTED_PARAMS = "SET SELECTED PARAMS SUPPLIER";
export const SEARCH_SUPPLIER = "SEARCH SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";

export const getAllSupplier = () => dispatch => {
  authChecker();
  resetDateRange(dispatch);
  return supplier
    .get("/")
    .then(supplierData => {
      dispatch({
        type: GET_ALL_SUPPLIER,
        payload: supplierData.data,
      });
    })
    .catch(err => {
      toast.error("Something went wrong, try refreshing the page");
    });
};

export const FilterSupplier = values => (dispatch, getState) => {
  authChecker();
  let { term, key } = values;
  let defaultKey = "commodityType";

  let params = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    params = {};
  }
  const dateParam = getDateParam(getState().common);
  params = { search: term, ...dateParam };

  return supplier
    .get("/", { params })
    .then(supplierData => {
      dispatch({
        type: SEARCH_SUPPLIER,
        payload: supplierData.data,
      });
      dispatch({
        type: SET_SELECTED_PARAMS,
        payload: params,
      });
      dispatch({
        type: RESET_PAGINATION_VALUES_AGENTS,
      });
      if (!supplierData.data.data.length) {
        toast.error("No Negotiation Found");
      }
    })
    .catch(() => {
      toast.error("Something went wrong, try refreshing the page");
    });
};

export const upDateSupplier =
  (values, { setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    return supplier
      .patch("", values)
      .then(upDateData => {
        handleModalToggle();
        toast.success(upDateData.data.status + " " + upDateData.data.message);
        dispatch({
          type: UPDATE_SUPPLIER,
          payload: upDateData.data,
        });
        dispatch(getAllSupplier());
      })
      .catch(err => {
        toast.error(err.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  authChecker();
  const { common, supplier } = getState();
  const dateParam = getDateParam(common);
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    ...supplier.selectedSearchParam,
    ...dateParam,
  };

  return supplier
    .get("/", { params })
    .then(negotiationData => {
      dispatch({
        type: SEARCH_SUPPLIER,
        payload: negotiationData.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE,
        payload: negotiationData.data.skip,
      });
    })
    .catch(() => {
      toast.error("Something went wrong, try refreshing the page");
    });
};


export const AllSupplier = state => {
  authChecker();
  const { supplier, common } = state;
  const dateParams = getDateParam(common);
  const params = {
    skip: 0,
    limit: 0,
    ...supplier.selectedSearchParam,
    ...dateParams,
  };

  return admin.get("", { params });
};