import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Search from "../../components/Search";
import AgentTable from "../DataTables/agentTable";
import AgentPagination from "../../components/common/Pagination";
import Spacer from "../../components/common/Spacer";
import { createNewManager } from "../../features/manager/action";
import { connect } from "react-redux";
import { getAllFarmers } from "../../features/farmers/actions";
import { TableLoader } from "../../components/common/TableLoader";
import { useModal } from "../../hooks";
import { handlePageChange } from "../../features/farmers/actions";
import { from_location } from "../../features/common/actions";
import LoadingAnimation from "../../components/Loader/Loader";
import NewWarehouseManagerModal from "../../components/modal/warehouseModals/newWarehouseManagerModal";
import DateFilter from "../../components/DatePicker/filter/dateFilter";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import useTranslate from "../../hooks/useTranslate";

function Agents(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const {
    totalDocumentCount,
    pageSize,
    handlePageChange,
    currentPage,
    getFarmerData,
    updateLocation,
    createWarehouseManager,
  } = props;

  useEffect(() => {
    getFarmerData()
      .then((res) => setLoading(false))
      .catch((err) => setLoading(false));
    updateLocation("agents");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSwitch = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "agents") return null;
    setLoadingPage(true);
    handlePageChange(pageNumber, pageSize)
      .then((res) => setLoadingPage(false))
      .catch((err) => setLoadingPage(false));
  };
  const [modal, toggleModal, setModal] = useModal();
  const { t } = useTranslate();

  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30, marginTop: 40 }}>
          {t("Agent")}
        </Col>
        <DateFilter />
        <div className="d-flex justify-content-end"></div> <Spacer unit={20} />
        {modal && (
          <CustomModalWrapper
            isOpen={modal}
            toggleModal={toggleModal}
            HeaderTitle={" Create Warehouse Manager"}
            setModal={setModal}
          >
            <NewWarehouseManagerModal
              toggleModal={toggleModal}
              createWarehouseManager={createWarehouseManager}
            />
          </CustomModalWrapper>
        )}
        {loading ? (
          <div>
            <TableLoader />
          </div>
        ) : (
          <div>
            <Col>
              <Row className="d-flex justify-content-center">
                <Search />
              </Row>
              <div className="d-flex justify-content-end">
                <button className="btn-manager" onClick={setModal}>
                  {t("Add Manager")}
                </button>
              </div>
            </Col>
            <Row>
              <Col className="table-responsive-md">
                <AgentTable />
              </Col>
            </Row>
            <div style={{ marginBottom: "5px" }}>
              {loadingPage ? <LoadingAnimation /> : <></>}
            </div>
            <Row>
              {totalDocumentCount > 10 && (
                <AgentPagination
                  total={totalDocumentCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={handlePageSwitch}
                  location="agents"
                />
              )}
            </Row>
          </div>
        )}
      </Col>
    </div>
  );
}

const mapStateToProps = (state) => ({
  farmers: state.farmer.farmerData.data || [],
  pageNumber: state.farmer.farmerData.skip,
  pageSize: state.farmer.farmerData.limit,
  totalDocumentCount: state.farmer.farmerData.totalDocumentCount,
  currentPage: state.farmer.pageNumberFarmers,
  // Values for searchBy Date range
  unlockSuccessful: state.farmer.unLockSuccessful,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFarmerData: () => dispatch(getAllFarmers()),
    handlePageChange: (pageNumber, pageSize) =>
      dispatch(handlePageChange(pageNumber, pageSize)),
    updateLocation: (location) => dispatch(from_location(location)),
    createWarehouseManager: (values, setSubmitting, toggleModal) =>
      dispatch(createNewManager(values, setSubmitting, toggleModal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agents);
