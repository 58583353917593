import React, { useState } from "react";
import { connect } from "react-redux";
import CustomTable from "../../components/Table";
import { supplierBody } from "../../components/Table/body";
import { supplierHeader } from "../../components/Table/headers";
import ViewWeightTicket from "../../components/modal/TradeApproval/viewWeightTicket";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import ViewSupplier from "../../components/modal/supplierModals/SupplierViewModal";
import { upDateSupplier } from "../../features/supplier/action";
import { useModal, useSelected } from "../../hooks";
import { useOptions } from "../../hooks/useOptions";
import { createTrackingOptions } from "./component/trackOptions";

const SupplierTable = ({ supplierData, ...props }) => {
  const [selected, handleClick] = useSelected(supplierData);
  const [modal, handleModalToggle, showModal] = useModal();
  const [picked, setPicked] = useState("");
  const [selectedOption, handleSelectedOption] = useOptions();
  const { trackUserTransaction } = props;
  const handlePicked = (optionPicked, id) => {
    setPicked(optionPicked);
    handleClick(id);
    showModal();
  };

  return (
    <>
      <CustomTable
        bodyArray={supplierBody(supplierData)}
        headerArr={supplierHeader}
        handlePicked={handlePicked}
        dropDowns={{ weightTicket: true }}
      />
      {showModal && picked === "view" && (
        <CustomModalWrapper HeaderTitle={"View Supplier"} isOpen={modal} toggleModal={handleModalToggle}>
          <ViewSupplier
            supplier={selected[0]}
            createTrackingOptions={createTrackingOptions}
            handleSelectedOption={handleSelectedOption}
            selectedOption={selectedOption}
            toggleModal={handleModalToggle}
            trackUserTransaction={trackUserTransaction}
          />
        </CustomModalWrapper>
      )}
      {showModal && picked === "weightTicket" && (
        <CustomModalWrapper isOpen={modal} HeaderTitle={"View Weight Ticket"} toggleModal={handleModalToggle}>
          <ViewWeightTicket supplier={selected[0]} toggleModal={handleModalToggle} />
        </CustomModalWrapper>
      )}
    </>
  );
};
const mapStateToProps = state => ({
  modalOpen: state.common.modalOpen,
  clickState: state.common.clickState,
  supplierData: state?.supplier?.supplierData?.data,
  transactionData: state.transaction.transactionData.data || [],
});

const mapDispatchToProps = dispatch => {
  return {
    trackUserTransaction: (values, { setSubmitting, setErrors }, handleModalToggle) =>
      dispatch(upDateSupplier(values, { setSubmitting, setErrors }, handleModalToggle)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierTable);
