import SignIn from "../views/SignIn/SignIn";

const authRoutes = [
  {
    path: "/auth/login",
    name: "Log In",
    component: SignIn
  },
  // {
  //     path: "/sign/forgotpassword",
  //     name: "Forgot Password",
  //     component: ForgotPassword
  // },
  // {
  //     path: "/sign/resetpassword",
  //     name: "Reset Password",
  //     component: ResetPassword
  // },
  {
    redirect: true,
    path: "/",
    pathTo: "/auth/login",
    name: "Log In",
    component: SignIn
  }
];
export default authRoutes;
