import React, { useEffect, useMemo } from "react";
import { XAxis, YAxis, Tooltip, CartesianGrid, AreaChart, Area, ResponsiveContainer } from "recharts";
import { graphDataParser } from "../../utils/graphCheckers";
import currencyFormat from "../lib/AmountFormat";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div>
        <div
          style={{
            padding: "15px",
            backgroundColor: "#fff",
            borderRadius: "15px",
            minWidth: "150px",
            boxShadow: " 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06)",
          }}
          className="d-flex flex-column justify-content-center align-items-start position-relative"
        >
          <h4 style={{ margin: 0, fontSize: "18px", fontWeight: "bold" }}>{currencyFormat(payload[0].payload.amount)}</h4>
          <p style={{ margin: 0 }}>{payload[0]?.payload?.XAxis}</p>
          <div
            className="position-absolute"
            style={{
              borderRadius: "2px",
              backgroundColor: "#fff",
              height: "15px",
              width: "15px",
              left: "50%",
              bottom: 0,
              transform: "translate(-50%,40%) rotate(45deg)",
            }}
          >
            {" "}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const LineGraph = ({ data, startDate, endDate, setAmount }) => {
  const parsedData = useMemo(
    () => (data ? graphDataParser(data ?? [], startDate, endDate) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );
  useEffect(() => {
    setAmount(parsedData[2] ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, startDate, endDate]);

  return (
    <ResponsiveContainer className="onBoarderFarmers">
      <AreaChart
        data={parsedData[0]}
        margin={{
          top: 10,
          right: 50,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="50%" stopColor="#5FC544" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="amount" stroke=" #5FC544" strokeWidth={5} fill={`url(#colorUv)`} activeDot={{ r: 8 }} />

        <CartesianGrid stroke="rgba(0,0,0,0.1)" vertical={false} />
        <XAxis
          dataKey="date"
          tick={{ fill: "#7D7D7D", fontSize: 14 }}
          margin={{ left: 20, right: 20 }}
          stroke="#F3F6FF"
          dy={10}
          interval={parsedData[1]}
          width={150}
        />
        <YAxis
          tick={{ fill: "#7D7D7D", fontSize: 14 }}
          stroke="#F3F6FF"
          dx={-10}
          width={100}
          domain={["auto", "auto"]}
          allowDataOverflow={true}
          tickFormatter={value => `${currencyFormat(value / 1000000)}M`}
        />
        <Tooltip wrapperStyle={{ outline: "none" }} position={{ y: 0 }} content={<CustomTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default LineGraph;
