import React, { useEffect } from "react";

import { connect } from "react-redux";
import { submitAdmincredentials } from "../../features/auth/actions";
import { handle_modal_state, reset_modal_state } from "../../features/common/actions";
import AdminView from "../modal/adminViewModal/adminViewModal";
import CreateAdminModal from "../modal/adminViewModal/createAdminModal";
import EditProfile from "../modal/adminViewModal/editProfileModal";
import CustomModalWrapper from "../modal/customModalWrapper";
import MainTransactionDetail from "../modal/transactionModals/mainTransaction";

export const modalNames = {
  userView: "View Profile",
  editUserView: "Edit User Profile",
  createUserView: "Create User Profile",
  transactionView: "View Transaction",
};

const CreateAdmin = props => {
  const { handle_modal_state, modalOpen, modalName, clickState, reset_modal_state, transactionSelected, userData } = props;
  useEffect(() => {
    reset_modal_state();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalToggle = () => {
    handle_modal_state();
  };
  return (
    <div>
      {modalOpen &&
        !clickState &&
        (modalName === modalNames.userView ? (
          <CustomModalWrapper isOpen={modalOpen} HeaderTitle={"View Profile"} showDefaultFooter toggleModal={handleModalToggle}>
            <AdminView userObj={userData} />
          </CustomModalWrapper>
        ) : modalName === modalNames.editUserView ? (
          <CustomModalWrapper isOpen={modalOpen} HeaderTitle={"Edit Profile"} toggleModal={handleModalToggle}>
            <EditProfile editProfile toggleModal={handleModalToggle} userObj={userData} />
          </CustomModalWrapper>
        ) : modalName === modalNames.createUserView ? (
          <CustomModalWrapper isOpen={modalOpen} HeaderTitle={"Create Admin"} toggleModal={handleModalToggle}>
            <CreateAdminModal create={() => {}} toggleModal={handleModalToggle} />
          </CustomModalWrapper>
        ) : (
          modalName === modalNames.transactionView && (
            <CustomModalWrapper
              HeaderTitle={"View Transaction Details"}
              isOpen={modalOpen}
              setModal={handleModalToggle}
              toggleModal={handleModalToggle}
            >
              <MainTransactionDetail data={transactionSelected} toggleModal={handleModalToggle} viewTransaction />
            </CustomModalWrapper>
          )
        ))}
    </div>
  );
};

const mapStateToProps = state => ({
  modalOpen: state.common.modalOpen,
  clickState: state.common.clickState,
  userData: state.auth.userData,
  modalName: state.common.modalName,
  transactionSelected: state.common.transactionSelected,
});

const mapDispatchToProps = dispatch => {
  return {
    handle_modal_state: () => dispatch(handle_modal_state()),
    submitAdmincredentials: (values, { setSubmitting, setErrors }) =>
      dispatch(submitAdmincredentials(values, { setSubmitting, setErrors })),
    reset_modal_state: () => dispatch(reset_modal_state()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin);
