import React from "react";
import { IoMdClose } from "react-icons/io";

const selectedField = ({ selected = [], onDelete, dataFields = [] }) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100"
      style={{
        marginTop: "30px",
        padding: "10px",
        borderRadius: "8px",
      }}
    >
      {selected.map((item, index) => {
        return (
          <div
            key={Date.now() + index}
            className="d-flex justify-content-between align-items-center w-100"
            style={{
              borderBottom: "5px solid #eefff4",
              minHeight: "50px",
            }}
          >
            <div className="w-100 d-flex justify-content-between">
              {dataFields.map((val) => `${item[val]??""}   `)}
            </div>
            <IoMdClose
              className="form-icon "
              size={25}
              color={"#21c570"}
              onClick={() => onDelete(item)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(selectedField);
