import { authChecker } from "../../services/authService";
import { toast } from "react-toastify";
import negotiate from "../../api/negotiate";
import { getDateParam, resetDateRange } from "../../utils/dateParams";
import { Tables } from "../../views/Dashboard/TradeApproved";
export const GET_ALL_NEGOTIATION_BUY = "GET_ALL_NEGOTIATION_BUY";
export const GET_ALL_NEGOTIATION_SELL = "GET_ALL_NEGOTIATION_SEL";
export const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE_NEGOTIATION";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET PAGINATION VALUE NEGOTIATION";
export const SET_SELECTED_PARAMS = "SET SELECTED PARAMS";
export const SEARCH_NEGOTIATION = "SEARCH_NEGOTIATION";
export const SET_TABLE = "SET NEGOTIATION TABLE";

export const createWarehouse =
  (values, { setSubmitting, setErrors }, toggleModal) =>
  dispatch => {
    authChecker();
    return negotiate
      .post("/", values)
      .then(warehouseData => {
        toast.success(warehouseData.data.message);
        toggleModal();
        dispatch(getAllNegotiation());
      })
      .catch(err => {
        toast.error(err.response.data.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
  };

export const getAllNegotiation = table => (dispatch, getState) => {
  authChecker();
  resetDateRange(dispatch);
  const currentTable = getState().negotiation.table;
  return negotiate
    .get("/", {
      params: { category: table || currentTable },
    })
    .then(negotiationData => {
      if (table === Tables.buy) {
        dispatch({
          type: GET_ALL_NEGOTIATION_BUY,
          payload: negotiationData.data,
        });
      } else {
        dispatch({
          type: GET_ALL_NEGOTIATION_SELL,
          payload: negotiationData.data,
        });
      }
    })
    .catch(err => {
      toast.error("Something went wrong, try refreshing the page");
    });
};

export const setNegotiationTable = table => dispatch => {
  dispatch({
    type: SET_TABLE,
    payload: table,
  });
};
export const FilterNegotiation = values => (dispatch, getState) => {
  authChecker();
  let { term, key } = values;
  let defaultKey = "status";

  let params = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    params = {};
  }
  const dateParam = getDateParam(getState().common);
  params = { search: term, ...dateParam };

  return negotiate
    .get("/", { params })
    .then(negotiationData => {
      dispatch({
        type: SEARCH_NEGOTIATION,
        payload: negotiationData.data,
      });
      dispatch({
        type: SET_SELECTED_PARAMS,
        payload: params,
      });
      dispatch({
        type: RESET_PAGINATION_VALUES_AGENTS,
      });
      if (!negotiationData.data.data.length) {
        toast.error("No Negotiation Found");
      }
    })
    .catch(() => {
      toast.error("Something went wrong, try refreshing the page");
    });
};

export const updateEvent =
  (values, { setSubmitting }) =>
  dispatch => {
    authChecker();
    if (!values.event) return;
    return negotiate
      .patch("/event", values)
      .then(transactionData => {
        toast.success("Event Updated Successfully");
        dispatch(getAllNegotiation());
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
  };

export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  authChecker();
  const { common, negotiation } = getState();
  const dateParam = getDateParam(common);
  const params = {
    category: negotiation.table,
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    ...negotiate.selectedSearchParam,
    ...dateParam,
  };

  return negotiate
    .get("/", { params })
    .then(negotiationData => {
      dispatch({
        type: SEARCH_NEGOTIATION,
        payload: negotiationData.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE,
        payload: negotiationData.data.skip,
      });
    })
    .catch(() => {
      toast.error("Something went wrong, try refreshing the page");
    });
};
