import { Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import LoaderButton from "../../components/Loader/LoaderButton";
import CustomTable from "../../components/Table";
import { outputManagementBody } from "../../components/Table/body";
import { subscriptionHeaderArr } from "../../components/Table/headers";
import AssignAgent from "../../components/modal/agentModals/assignAgentModal";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import EditOutputMgtModal from "../../components/modal/outputMgtModals/editOutputMgtModal";
import ViewOutputMgtModal from "../../components/modal/outputMgtModals/viewOutputMgtModal";
import { deleteOutput, updateOutputOffers, updateSubscription } from "../../features/outputmanagement/action";
import { useModal, useSelected } from "../../hooks";
const OutputManagementTable = ({ subscriptionData, updateSubscription, deleteOutput }) => {
  const [selected, handleClick] = useSelected(subscriptionData);
  const [modal, handleModalToggle, showModal] = useModal();
  const [picked, setPicked] = useState("");

  const handlePicked = (optionPicked, id) => {
    setPicked(optionPicked);
    handleClick(id);
    showModal();
  };

  return (
    <>
      <CustomTable
        bodyArray={outputManagementBody(subscriptionData)}
        dropDowns={{ deleteAccess: true, editAccess: true }}
        handlePicked={handlePicked}
        headerArr={subscriptionHeaderArr}
      />
      {!selected[0] || selected.length < 1 ? (
        <></>
      ) : picked === "edit" ? (
        <>
          <CustomModalWrapper HeaderTitle={"Update Output"} isOpen={modal} toggleModal={handleModalToggle}>
            <EditOutputMgtModal outputObject={selected[0]} toggleModal={handleModalToggle} updateSubscription={updateSubscription} />
          </CustomModalWrapper>
        </>
      ) : picked === "assignAgentOffer" ? (
        <>
          <CustomModalWrapper HeaderTitle={"Assign Agent Offer"} isOpen={modal} toggleModal={handleModalToggle}>
            <AssignAgent selectedObj={selected[0]} handleModalToggle={handleModalToggle} onSubmitAgents={updateOutputOffers} />
          </CustomModalWrapper>
        </>
      ) : picked === "delete" ? (
        <>
          <Formik
            initialValues={{
              outputId: selected[0]._id,
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              deleteOutput(values, { setErrors, setSubmitting }, handleModalToggle);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <>
                <CustomModalWrapper
                  HeaderTitle={"Delete Output"}
                  isOpen={modal}
                  toggleModal={handleModalToggle}
                  showDefaultFooter
                  footerExtra={
                    <LoaderButton
                      type="search"
                      isLoading={isSubmitting}
                      className="btn-primary"
                      text="Delete output"
                      style={{
                        marginRight: "10px",
                        width: "150px",
                        borderRadius: "0px 8px",
                        backgroundColor: "#3bc552",
                      }}
                      id="mc-embedded-subscribe"
                      onClick={handleSubmit}
                    />
                  }
                >
                  <ViewOutputMgtModal outputObject={selected[0]} />
                </CustomModalWrapper>
              </>
            )}
          </Formik>
        </>
      ) : (
        <CustomModalWrapper HeaderTitle={"View output management"} isOpen={modal} toggleModal={handleModalToggle} showDefaultFooter>
          <ViewOutputMgtModal outputObject={selected[0]} />
        </CustomModalWrapper>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  pageSize: state.subscription.subscriptionData.limit,
  pageNumber: state.subscription.subscriptionData.skip,
  subscriptionData: state.subscription.subscriptionData.data || [],
  totalDocumentCount: state.subscription.subscriptionData.totalDocumentCount,
  data: state.subscription.info,
});

const mapDispatchToProps = dispatch => {
  return {
    // setDisplay: arrayOfData => dispatch(setDisplay(arrayOfData)), deleteOutput
    updateSubscription: (values, { setErrors, setSubmitting }, handleModalToggle) =>
      dispatch(updateSubscription(values, { setErrors, setSubmitting }, handleModalToggle)),
    deleteOutput: (values, { setErrors, setSubmitting }, handleModalToggle) =>
      dispatch(deleteOutput(values, { setErrors, setSubmitting }, handleModalToggle)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputManagementTable);
