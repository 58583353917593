import { toast } from "react-toastify";
import warehouse from "../../api/warehouse";
import { authChecker } from "../../services/authService";
import transaction from "../../api/transaction";

export const SET_GRAPH_DATE = "set_Graph_Date graph";
export const SET_LOADING_STATE = "set_loading_state graph";
export const SET_GRAPH_DATA = "set_graph_data graph";
export const SET_CROP_TYPE = "set_crop_type graph";
export const SET_SERVICE_TYPE = "set_service_type graph";
export const RESET_ALL_PARAMS = "reset_all_params graph";
export const SET_A_PARAM = "set_a_param graph";

export const getInitialData = () => dispatch => {
  authChecker();
  const params = {
    skip: 0,
    limit: 0,
  };
  dispatch({
    type: SET_LOADING_STATE,
    payload: true,
  });
  dispatch({
    type: RESET_ALL_PARAMS,
  });
  return transaction
    .get("transactions", { params })
    .then(data => {
      dispatch({
        type: SET_GRAPH_DATA,
        payload: data?.data,
      });
    })
    .catch(err => {
      toast.error(err.response.data.message ?? "something went wrong fetching data");
    })
    .finally(() => {
      dispatch({
        type: SET_LOADING_STATE,
        payload: false,
      });
    });
};

export const setDate = (startDate, endDate) => dispatch => {
  dispatch({
    type: SET_GRAPH_DATE,
    payload: {
      startDate,
      endDate,
    },
  });
};

export const onChange = value => (dispatch, getState) => {
  authChecker();
  const { startDate, endDate, cropType, serviceType, tracking } = getState().overviewGraph;

  let params = {
    skip: 0,
    limit: 0,
    startDate,
    endDate,
    cropType,
    serviceType,
    tracking,
  };
  if (!startDate || !endDate) {
    delete params.startDate;
    delete params.endDate;
  }
  params = { ...params, ...value };
  if (!params.startDate || !params.endDate) {
    delete params.startDate;
    delete params.endDate;
  }

  dispatch({
    type: SET_LOADING_STATE,
    payload: true,
  });

  return warehouse
    .get("transactions", { params })
    .then(data => {
      dispatch({
        type: SET_A_PARAM,
        payload: value,
      });
      dispatch({
        type: SET_GRAPH_DATA,
        payload: data.data,
      });
    })
    .catch(err => {
      toast.error(err.response.data.message ?? "something went wrong fetching data");
    })
    .finally(() => {
      dispatch({
        type: SET_LOADING_STATE,
        payload: false,
      });
    });
};
