import React from "react";
import SearchForm from "./SearchForm";
import AddEntry from "./AddEntry";
import { Col } from "reactstrap";

function Search(props) {
  return (
    <Col className="d-flex justify-content-end search">
      <SearchForm className="mr-auto" />
      <AddEntry />
    </Col>
  );
}

export default Search;
