import { SIGN_OUT, FETCH_USER_DATA } from "./actions";

const initialState = {
  userData: {
    _id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    gender: "",
    created_at: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DATA:
      return { ...state, userData: action.payload };
    case SIGN_OUT:
      return { ...state, "userData.token": null };
    default:
      return state;
  }
};
