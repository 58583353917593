import axios from "axios";
import authService from "../services/authService";

const API = process.env.REACT_APP_ADMIN + "/thr/v2/";

const output = axios.create({
  baseURL: API
});

output.defaults.headers.post["Content-Type"] = "application/json";
// admin.defaults.headers["Access-Control-Allow-Origin"] = "*";

output.interceptors.request.use(
  config => {
    const token = authService.getToken();
   
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
      config.headers["access_token"] = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

export default output;
