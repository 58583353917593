const formSelectorAndButton = {
  Date: {
    en: "Date",
    ha: "Kwanan wata",
  },
  "Date Range": {
    en: "Date Range",
    ha: "Rage kwanan wata",
  },
  "Search By": {
    en: "Search By",
    ha: "Bincika Ta",
  },
  Search: {
    en: "Search",
    ha: "Bincika",
  },
  Select: {
    en: "Select",
    ha: "Zaɓi",
  },
  select: {
    en: "select",
    ha: "zaɓi",
  },
  Filter: {
    en: "Filter",
    ha: "Tace",
  },
  filter: {
    en: "filter",
    ha: "tace",
  },
  language: {
    en: "Language",
    ha: "harshe",
  },
  "Add Manager": {
    en: "Add Manager",
    ha: "Ƙara Manager",
  },
};

export default formSelectorAndButton;
