import {
  HANDLE_PAGE_CHANGE_OVERVIEW,
  GET_FARMER_INPUT,
  GET_FARMER_OUTPUT,
  GET_ALL_AGENTS_OVERVIEW,
} from "./action";

const initialState = {
  pageSize: 10,
  pageNumberOverview: 1,
  allFarmerInputs: 0,
  allFarmerOutputs: 0,
  agentsOverview: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_PAGE_CHANGE_OVERVIEW:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberOverview: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberOverview: pageNumberOld,
        };
      }
      return { ...state };
    case GET_ALL_AGENTS_OVERVIEW:
      return {
        ...state,
        agentsOverview: action.payload,
        pageNumberOverview: 1,
      };
    case GET_FARMER_INPUT:
      return { ...state, allFarmerInputs: action.payload };
    case GET_FARMER_OUTPUT:
      return { ...state, allFarmerOutputs: action.payload };
    default:
      return state;
  }
};
