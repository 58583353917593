import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, ModalBody, ModalFooter, Row } from "reactstrap";
import LoaderButton from "../../Loader/LoaderButton";
import CustomTextField from "../../Forms/customFields/CustomTextField";
import CustomSelectLocation from "../../Forms/customFields/CustomSelectLocation";
import CustomDatePicker from "../../Forms/customFields/CustomDatePicker";

const TransactionEdit = ({ transaction, updateTransaction, toggleModal }) => {
  const [date, setDate] = useState(new Date());
  const [dateDispatch, setDateDispatch] = useState(new Date());

  const handleChangeDate = (date) => {
    setDate(new Date(date));
  };
  const handleDispatch = (dateDispatch) => {
    setDateDispatch(new Date(dateDispatch));
  };

  return (
    <Formik
      initialValues={{
        transactionId: transaction._id,
        status: transaction.status,
        state: transaction.state,
        lga: transaction.lga,
        amountPaid: transaction.amountPaid,
        amount: transaction.amount,
        chargeableAmount: transaction.chargeableAmount,
        estimatedAmount: transaction.estimatedAmount,
        dispatchDate: dateDispatch,
        deliveryDate: date,
        narration: transaction.narration,
        message: transaction.message,
        units: transaction.units,
        estimatedUnits: transaction.estimatedUnits,
        inputDeliveryLocation: transaction.inputDeliveryLocation,
        outputPickupLocation: transaction.outputPickupLocation,
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        updateTransaction(values, { setSubmitting, setErrors }, toggleModal);
      }}
    >
      {({
        values,
        handleChange,
        isSubmitting,
        errors,
        handleSubmit,
        setFieldValue,
      }) => {
        const { CustomLgaSelect, CustomStateSelect } = CustomSelectLocation(
          { value: "state", label: "State" },
          { value: "lga", label: "LGA" },
          setFieldValue,
          values.state,
          values.lga
        );
        return (
          <>
            <ModalBody>
              <Form>
                <Col>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label={"Status"}
                        name="status"
                        type="text"
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Amount Paid"
                        name={"amountPaid"}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>{CustomStateSelect}</Col>
                    <Col lg={5}>{CustomLgaSelect}</Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Amount"
                        name={"amount"}
                        type="text"
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Chargeable Amount"
                        name={"chargeableAmount"}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Estimated Amount"
                        name={"estimatedAmount"}
                        type="text"
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Narration"
                        name={"narration"}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular mt-3">
                    <Col lg={5}>
                      <CustomDatePicker
                        label="Dispatch Date"
                        onChange={handleDispatch}
                        value={dateDispatch}
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomDatePicker
                        label={"Delivery Date"}
                        onChange={handleChangeDate}
                        value={date}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Message"
                        name={"message"}
                        type="text"
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Units"
                        name={"units"}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Estimated Units"
                        name={"estimatedUnits"}
                        type="text"
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Input Delivery Location"
                        name={"inputDeliveryLocation"}
                        type="text"
                      />
                    </Col>
                    <Col lg={5} className="mt-3">
                      <CustomTextField
                        handleChange={handleChange}
                        label="Output Pickup Location"
                        name={"outputPickupLocation"}
                        type="text"
                      />
                    </Col>
                    <Col lg={5}></Col>
                  </Row>
                </Col>
              </Form>
            </ModalBody>
            <ModalFooter>
              <LoaderButton
                className="form-btn-1 ca-font-regular"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                type="submit"
                text="Update Transaction"
                style={{
                  marginRight: "10px",
                  width: "180px",
                  borderRadius: "0px 8px",
                  backgroundColor: "#3bc552",
                }}
                id="mc-embedded-subscribe"
              />
              <Button
                className="form-btn-2 ca-font-regular"
                onClick={toggleModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default TransactionEdit;
