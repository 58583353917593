import { toast } from "react-toastify";
import warehouse from "../../api/warehouse";
import { authChecker } from "../../services/authService";
import { getDateParam, resetDateRange } from "../../utils/dateParams";

export const GET_ALL_WAREHOUSE = "GET_ALL_WAREHOUSE";
export const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET PAGINATION VALUE";
export const SET_SELECTED_PARAMS = "SET SELECTED PARAMS";
export const SEARCH_WAREHOUSE = "SEARCH_WAREHOUSE";

export const createWarehouse =
  (values, { setSubmitting, setErrors }, toggleModal) =>
  dispatch => {
    authChecker();
    return warehouse
      .post("warehouses", values)
      .then(warehouseData => {
        toast.success(warehouseData.data.message);
        toggleModal();
        dispatch(getAllWarehouse());
      })
      .catch(err => {
        toast.error(err.response.data.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
  };

export const getAllWarehouse = () => dispatch => {
  authChecker();
  resetDateRange(dispatch);
  return warehouse
    .get("warehouses")
    .then(warehouseData => {

      dispatch({
        type: GET_ALL_WAREHOUSE,
        payload: warehouseData.data.data,
      });
    })
    .catch(err => {
      toast.error("Something went wrong, try refreshing the page");
    });
};
export const FilterSearch = values => (dispatch, getState) => {
  authChecker();
  let { term, key } = values;
  let defaultKey = "state";

  let params = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    params = {};
  }
  const dateParam = getDateParam(getState().common);
  params = { ...params, ...dateParam };

  return warehouse
    .get("warehouses", { params })
    .then(warehouseData => {
      dispatch({
        type: SEARCH_WAREHOUSE,
        payload: warehouseData.data,
      });
      dispatch({
        type: SET_SELECTED_PARAMS,
        payload: params,
      });
      dispatch({
        type: RESET_PAGINATION_VALUES_AGENTS,
      });
      if (!warehouseData.data.data.length) {
        toast.error("No Warehouse Found");
      }
    })
    .catch(err => {
      toast.error("Something went wrong, try refreshing the page");
    });
};

export const updateWarehouse =
  (values, { setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    return warehouse
      .put("warehouses", values)
      .then(transactionData => {
        handleModalToggle();
        toast.success(transactionData.data.status + " " + transactionData.data.message);
        dispatch(getAllWarehouse());
      })
      .catch(err => {
        toast.error(err.response.data.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
  };

export const deleteWarehouse =
  (values, { setErrors, setSubmitting }, handleModalToggle) =>
  dispatch => {
    let { warehouseId } = values;
    return warehouse
      .delete("warehouses", { data: { warehouseId: warehouseId } })
      .then(warehouseDeletedData => {
        handleModalToggle();
        toast.success(warehouseDeletedData.data.status + " " + warehouseDeletedData.data.message);
        dispatch(getAllWarehouse());
      })
      .catch(err => {
        toast.error(err.response.data.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

// pageChange Handling

export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  authChecker();
  const { warehouse, common } = getState();
  const dateParam = getDateParam(common);

  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    ...warehouse.selectedSearchParam,
    ...dateParam,
  };

  return warehouse
    .get("warehouses", { params })
    .then(warehouseData => {
      dispatch({
        type: SEARCH_WAREHOUSE,
        payload: warehouseData.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE,
        payload: warehouseData.data.skip,
      });
    })
    .catch(err => {
      toast.error("Something went wrong, try refreshing the page");
    });
};
