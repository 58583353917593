import React, { useState } from "react";
import { FiAtSign, FiEdit2, FiFile, FiInfo, FiLock, FiTrash, FiTruck, FiUnlock } from "react-icons/fi";
import OptionBtn from "../OptionBtn/OptionBtn";

import { TiTicket } from "react-icons/ti";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

export default function DropdownView({
  id,
  handlePicked,
  editAccess,
  deleteAccess,
  trackTransaction,
  lockUser,
  unlockUser,
  assignRole,
  negotiate,
  verifyCompany,
  viewFarmers,
  assignAgentOffer,
  weightTicket,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleEdit = id => {
    handlePicked("edit", id);
  };
  const handleDelete = id => {
    handlePicked("delete", id);
  };
  const handleView = id => {
    handlePicked("view", id);
  };
  const handleTransaction = id => {
    handlePicked("trackTransaction", id);
  };
  const handleLockUser = id => {
    handlePicked("lockuser", id);
  };
  const handleunLockUser = id => {
    handlePicked("unlockuser", id);
  };
  const handleRole = id => {
    handlePicked("assignRole", id);
  };
  const handleVerification = id => {
    handlePicked("verifyCompany", id);
  };
  const handleViewFarmers = id => {
    handlePicked("viewFarmers", id);
  };
  const handleAssignAgentOffer = id => {
    handlePicked("assignAgentOffer", id);
  };
  const handleNegotiate = id => {
    handlePicked("negotiate", id);
  };
  const handleViewWeightTicket = id => {
    handlePicked("weightTicket", id);
  };
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown direction="up" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        <OptionBtn />
      </DropdownToggle>
      <DropdownMenu className="drop-menu" right>
        {editAccess && (
          <DropdownItem onClick={() => handleEdit(id)} className="menu-opt">
            <FiEdit2 className="m-2" />
            <span>Edit</span>
          </DropdownItem>
        )}
        <DropdownItem onClick={() => handleView(id)} className="menu-opt">
          <FiFile className="m-2" />
          View
        </DropdownItem>
        {assignAgentOffer && (
          <DropdownItem onClick={() => handleAssignAgentOffer(id)} className="menu-opt">
            <FiInfo className="m-2" />
            <span>Assign Agent Offer</span>
          </DropdownItem>
        )}
        {deleteAccess && (
          <DropdownItem onClick={() => handleDelete(id)} className="menu-opt">
            <FiTrash className="m-2" />
            <span>Delete</span>
          </DropdownItem>
        )}
        {trackTransaction && (
          <DropdownItem onClick={() => handleTransaction(id)} className="menu-opt">
            <FiTruck className="m-2" />
            <span>Track Transaction</span>
          </DropdownItem>
        )}
        {lockUser && (
          <DropdownItem onClick={() => handleLockUser(id)} className="menu-opt">
            <FiLock className="m-2" />
            <span>Lock User</span>
          </DropdownItem>
        )}
        {unlockUser && (
          <DropdownItem onClick={() => handleunLockUser(id)} className="menu-opt">
            <FiUnlock className="m-2" />
            <span>unLock User</span>
          </DropdownItem>
        )}
        {assignRole && (
          <DropdownItem onClick={() => handleRole(id)} className="menu-opt">
            <FiAtSign className="m-2" />
            <span>Assign User Role</span>
          </DropdownItem>
        )}
        {verifyCompany && (
          <DropdownItem onClick={() => handleVerification(id)} className="menu-opt">
            <FiAtSign className="m-2" />
            <span>Verify Company</span>
          </DropdownItem>
        )}
        {negotiate && (
          <DropdownItem onClick={() => handleNegotiate(id)} className="menu-opt">
            <FiAtSign className="m-2" />
            <span>Negotiate</span>
          </DropdownItem>
        )}
        {weightTicket && (
          <DropdownItem onClick={() => handleViewWeightTicket(id)} className="menu-opt">
            <TiTicket className="m-2" />
            <span>Weight Ticket</span>
          </DropdownItem>
        )}
       
        {viewFarmers && (
          <DropdownItem onClick={() => handleViewFarmers(id)} className="menu-opt">
            <FiInfo className="m-2" />
            <span>View Farmers Onboarded</span>
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
