import React from "react";
import CustomTable from "../../Table";
import { agentOnboardedFarmerBody } from "../../Table/body";
import Card from "../../Card/Card";
import { Col, ModalBody, Row } from "reactstrap";
import AgentsFarmerOnboardedPagination from "../../common/Pagination";
import { agentsOnboardFarmer } from "../../Table/headers";
import LoadingAnimation from "../../Loader/Loader";

const FarmersAgentOnboardedModal = ({
  totalDocumentCount,
  farmerOnboarded,
  userName,
  lastName,
  phoneNumber,
  AgentsFarmer,
  isLoading,
  pageSize,
  currentPage,
  handlePageSwitch,
}) => {
  return (
    <ModalBody>
      <Row className="d-flex justify-content-center mt-5">
        <div className="card-right-distance">
          <Card
            subtitle="Farmers Onboarded"
            value={totalDocumentCount || 0}
            imgSource={farmerOnboarded}
            imgClass="farmer-onboarded"
            imgWidth={24}
            imgHeight={24}
            agentName={userName + " " + lastName}
            agentPhoneNumber={phoneNumber}
            valStyle="card-font-size ca-font-medium"
            subStyle="card-subtitle-1 ca-font-regular"
          />
        </div>
      </Row>

      <CustomTable
        bodyArray={agentOnboardedFarmerBody(AgentsFarmer)}
        dropDowns={false}
        headerArr={agentsOnboardFarmer}
      />
      <div>{isLoading && <LoadingAnimation />}</div>
      {totalDocumentCount && (
        <Row>
          <Col className="pagination-container">
            <AgentsFarmerOnboardedPagination
              total={totalDocumentCount || 0}
              pageSize={pageSize || 0}
              currentPage={currentPage}
              onPageChange={handlePageSwitch}
              location="agentsFarmerOnboarded"
            />
          </Col>
        </Row>
      )}
    </ModalBody>
  );
};

export default FarmersAgentOnboardedModal;
