import React, { useState } from "react";
import { adminHeaderArr } from "../../components/Table/headers";
import { useSelected, useModal } from "../../hooks";
import { connect } from "react-redux";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import CustomTable from "../../components/Table";
import { adminBody } from "../../components/Table/body";
import AdminView from "../../components/modal/adminViewModal/adminViewModal";
import EditProfile from "../../components/modal/adminViewModal/editProfileModal";

const AdminTable = ({ admins }) => {
  const [selected, handleClick] = useSelected(admins);
  const [modal, handleModalToggle, showModal] = useModal();
  const [picked, setPicked] = useState("");
  const handlePicked = (optionPicked, id) => {
    setPicked(optionPicked);
    handleClick(id);
    showModal();
  };
  return (
    <>
      <CustomTable
        bodyArray={adminBody(admins)}
        dropDowns={true}
        handlePicked={handlePicked}
        headerArr={adminHeaderArr}
      />
      {!selected[0] || selected.length < 1 ? (
        ""
      ) : modal && picked === "view" ? (
        <CustomModalWrapper
          isOpen={modal}
          HeaderTitle={"View Admin"}
          showDefaultFooter
          toggleModal={handleModalToggle}
        >
          <AdminView userObj={selected[0]} />
        </CustomModalWrapper>
      ) : (
        modal && (
          <CustomModalWrapper
            isOpen={modal}
            HeaderTitle={"Edit Profile"}
            toggleModal={handleModalToggle}
          >
            <EditProfile
              toggleModal={handleModalToggle}
              userObj={selected[0]}
            />
          </CustomModalWrapper>
        )
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  admins: state?.admin?.allAdmins?.data || [],
  //agents: state.agent.agentData.data || [],
  pageNumber: state?.admin?.allAdmins?.skip,
  pageSize: state.admin.allAdmins.limit,
  totalDocumentCount: state.admin.allAdmins.totalDocumentCount,
  currentPage: state.admin.currentPage,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
