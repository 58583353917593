import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
// import Button from "../../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";

const ErrorPage = () => (
  <Container className="p-5 error-page">
    <Row className="align-items-center">
      <Col className="text-center mb-4">
        <span className="display-1 text-primary font-weight-bold">OOPS!</span>
        <br />
        <span className="h4 text-muted">404 error</span>
      </Col>
      <Col className="mb-4">
        <span className="h5 text-primary">
          Sorry, we could not find the page
        </span>
        <br />
        <br />
        <span>
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </span>
        <br />
        <br />
        <Link to="/dashboard/">
          <Button round color="primary" className="font-weight-bold">
            Go to Dashboard
          </Button>
        </Link>
      </Col>
    </Row>
  </Container>
);

export default ErrorPage;
