import React, { forwardRef } from "react";
import LoadingAnimation from "../../../components/Loader/Loader";

const ResultComponent = (
  { results = [], loading, dataFields = [], onSelect },
  ref
) => {
  return (
    <div
      className="w-75 mt-2 d-flex flex-column justify-content-center align-items-center"
      style={{
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        minHeight: "100px",
        maxHeight: "400px",
        overflowY: "auto",
      }}
    >
      {loading ? (
        <LoadingAnimation />
      ) : (
        results.map((agent, index) => {
          return (
            <button
              key={index}
              style={{
                border: "none",
                borderBottom: "5px solid #eefff4",
                minHeight: "50px",
                backgroundColor: `${
                  ref.current[agent._id] ? "#eefff4" : "#fff"
                }`,
              }}
              className={
                "w-100 d-flex align-items-center justify-content-center"
              }
              onClick={() => {
                if (!ref.current[agent._id]) onSelect(agent);
              }}
            >
              {dataFields.map((val) => `${agent[val] ?? ""}   `)}
            </button>
          );
        })
      )}
    </div>
  );
};

export default React.memo(forwardRef(ResultComponent));
