import { combineReducers } from "redux";
import sampleReducer from "./sample/reducer";
import authReducer from "./auth/reducer";
import commonReducer from "./common/reducer";
import subscriptionReducer from "./outputmanagement/reducer";
import farmerReducer from "./farmers/reducer";
import overviewReducer from "./overview/reducer";
import agentReducer from "./agents/reducer";
import transactionReducer from "./transaction/reducer";
import inboundReducer from "./inboundService/reducer";
import inputManagementReducer from "./inputManagement/reducer";
import resellerReducer from "./reseller/reducer";
import notifyReducer from "./notification/reducer";
import warehouseReducer from "./warehouse/reducer";
import managerReducer from "./manager/reducer";
import agentOtpReducer from "./agentOtp/reducer";
import adminReducer from "./admin/reducer";
import negotiateReducer from "./negotation/reducer";
import overviewGraphReducer from "./overviewGraph/reducer";
import supplierReducer from "./supplier/reducer";
export default combineReducers({
  auth: authReducer,
  common: commonReducer,
  overviewGraph: overviewGraphReducer,
  subscription: subscriptionReducer,
  inputManagement: inputManagementReducer,
  farmer: farmerReducer,
  overview: overviewReducer,
  agent: agentReducer,
  transaction: transactionReducer,
  inboundService: inboundReducer,
  reseller: resellerReducer,
  notifyData: notifyReducer,
  warehouse: warehouseReducer,
  agentOTP: agentOtpReducer,
  sampleReducer,
  WarehouseManager: managerReducer,
  admin: adminReducer,
  negotiation: negotiateReducer,
  supplier: supplierReducer,
});
