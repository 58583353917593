import { ErrorMessage, Field } from "formik";
import React from "react";
import { Label } from "reactstrap";

const CustomTextField = ({
  label,
  type = "text",
  handleChange,
  name,
  required,
  ...props
}) => {
  return (
    <>
      {label && (
        <Label htmlFor={name}>
          {label}
          {required && (
            <span className="ml-1" style={{ color: "red" }}>
              *
            </span>
          )}
        </Label>
      )}
      <Field
        name={name}
        id={name}
        type={type}
        className="overview-form-field form-control"
        onChange={handleChange}
        {...props}
      />
      <ErrorMessage name={name} component="span" className="text-danger" />
    </>
  );
};
export default CustomTextField;
