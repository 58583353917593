import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Search from "../../components/Search";
import AgentOTPTable from "../DataTables/agentOTPTable";
import AgentPaginationOTP from "../../components/common/Pagination";
import Spacer from "../../components/common/Spacer";
import { connect } from "react-redux";
import { TableLoader } from "../../components/common/TableLoader";
import LoadingAnimation from "../../components/Loader/Loader";
import {
  handlePageChangeOTP,
  getAllOTPRequest,
} from "../../features/agentOtp/actions";
import { from_location } from "../../features/common/actions";
import DateFilter from "../../components/DatePicker/filter/dateFilter";
import useTranslate from "../../hooks/useTranslate";

function AgentsOTP(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const {
    totalDocumentCount,
    pageSize,
    handlePageChangeOTP,
    currentPage,
    getAllOTPRequest,
    updateLocation,
  } = props;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllOTPRequest()
      .then((res) => setLoading(false))
      .catch((err) => setLoading(false));
    updateLocation("agentsOTP");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSwitch = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "agentsOTP") return null;
    setLoadingPage(true);
    handlePageChangeOTP(pageNumber, pageSize)
      .then((res) => setLoadingPage(false))
      .catch((err) => setLoadingPage(false));
  };

  const { t } = useTranslate();
  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30, marginTop: 40 }}>
          {t("Agent OTP")}
        </Col>
        <DateFilter />
        <Spacer unit={20} />
        {loading ? (
          <div>
            <TableLoader />
          </div>
        ) : (
          <div>
            <Col>
              <Row className="d-flex justify-content-center">
                <Search />
              </Row>
            </Col>
            <Row>
              <Col className="table-responsive-md">
                <AgentOTPTable />
              </Col>
            </Row>
            <div style={{ marginBottom: "5px" }}>
              {loadingPage ? <LoadingAnimation /> : <></>}
            </div>
            <Row>
              <AgentPaginationOTP
                total={totalDocumentCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageSwitch}
                location="agentsOTP"
              />
            </Row>
          </div>
        )}
      </Col>
    </div>
  );
}

const mapStateToProps = (state) => ({
  requestedOTP: state.agentOTP.allOTPRequested.data || [],
  pageNumber: state.agentOTP.allOTPRequested.skip,
  pageSize: state.agentOTP.allOTPRequested.limit,
  totalDocumentCount: state.agentOTP.allOTPRequested.totalDocumentCount,
  currentPage: state.agentOTP.pageNumberOTP,
  screenLocation: state.common.location,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOTPRequest: () => dispatch(getAllOTPRequest()),
    handlePageChangeOTP: (pageNumber, pageSize) =>
      dispatch(handlePageChangeOTP(pageNumber, pageSize)),
    updateLocation: (location) => dispatch(from_location(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentsOTP);
