import React from "react";
import { Col } from "reactstrap";
import useTranslate from "../../../hooks/useTranslate";

const InnerTab = ({ tabs = [] }) => {
  const { t } = useTranslate();
  return (
    <Col>
      {tabs.map((tab, index) => {
        return (
          <button
            key={index}
            className={`remove-btn-style mr-3 ca-font-medium  ${
              tab.isActive && "default-tab-color"
            }`}
            onClick={() => tab.onClick()}
          >
            {t(tab.name)}
          </button>
        );
      })}
    </Col>
  );
};

export default InnerTab;
