import { Formik } from "formik";
import React from "react";
import { Button, Col, Form, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import LoaderButton from "../../Loader/LoaderButton";
import { categoryObj, CategoryValues } from "../../options/inputCategoryAndValues";
import { createStockOptions } from "../../../views/Dashboard/component/stock";
import { createUnitOfMeasurement } from "../../../views/Dashboard/component/unitofMeasurement";
import CustomSelectLocation from "../../Forms/customFields/CustomSelectLocation";
import CustomTextField from "../../Forms/customFields/CustomTextField";
import CustomSelectField, { setSelectValue } from "../../Forms/customFields/CustomSelectField";
const EditInputMgtModal = ({ inputObj, toggleModal, updateInput }) => {
  return (
    <Formik
      initialValues={{
        minimumRequestLimit: inputObj.minimumRequestLimit,
        inputType: inputObj.inputType,
        inputCategory: inputObj.inputCategory,
        inputName: inputObj.inputName,
        inputState: inputObj.inputState,
        inputLga: inputObj.inputLga,
        instock: inputObj.instock,
        perUnitPrice: inputObj.perUnitPrice,
        unitOfMeasurement: inputObj.unitOfMeasurement,
        perUnitPercentageCommission: inputObj.perUnitPercentageCommission,
        inputDistributionCompany: inputObj.inputDistributionCompany,
        totalUnitsInstock: inputObj.totalUnitsInstock,
        unitsRemaining: inputObj.unitsRemaining,
        description: inputObj.description,
        inputId: inputObj._id,
        isCustom: inputObj.isCustom ?? false,
      }}
      onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
        updateInput(values, { setErrors, setStatus, setSubmitting }, toggleModal);
      }}
    >
      {({ values, handleChange, isSubmitting, errors, handleSubmit, setFieldValue }) => {
        const { CustomLgaSelect, CustomStateSelect } = CustomSelectLocation(
          { value: "inputState", label: "Input State" },
          { value: "inputLga", label: "Input Lga" },
          setFieldValue,
          values.inputState,
          values.inputLga
        );
        return (
          <>
            <ModalBody>
              <Form>
                <Col>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label={"Minimum Request Limit"} name={"minimumRequestLimit"} />
                    </Col>
                    <Col lg={5}>
                      <Label htmlFor="instock">Input Stock</Label>
                      <CustomSelectField
                        label={"Input Stock"}
                        onChange={option => setFieldValue("instock", option.value)}
                        options={createStockOptions}
                        selectValue={setSelectValue(createStockOptions, values.instock)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomSelectField
                        label={"Input Category"}
                        onChange={option => setFieldValue("inputCategory", option.value)}
                        options={CategoryValues}
                        selectValue={setSelectValue(CategoryValues, values.inputCategory)}
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomSelectField
                        label={"Input Type"}
                        onChange={options => setFieldValue("inputType", options.value)}
                        options={categoryObj[values.inputCategory] ?? []}
                        selectValue={setSelectValue(categoryObj[values.inputCategory] ?? [], values.inputType)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label={"Input Name"} name={"inputName"} type={"text"} />
                    </Col>
                    <Col lg={5}>{CustomStateSelect}</Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>{CustomLgaSelect}</Col>
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label={"Per Unit Price"} name={"perUnitPrice"} type={"number"} />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular mt-3">
                    <Col lg={5}>
                      <CustomSelectField
                        label={"Unit Of Measurement"}
                        onChange={option => setFieldValue("unitOfMeasurement", option.value)}
                        options={createUnitOfMeasurement}
                        selectValue={setSelectValue(createUnitOfMeasurement, values.unitOfMeasurement)}
                      />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField
                        handleChange={handleChange}
                        label="Input Distribution Company"
                        name={"inputDistributionCompany"}
                        type={"text"}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular"></Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label={"Total Units Instock"} name={"totalUnitsInstock"} type={"text"} />
                    </Col>
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label={"Units Remaining"} name="unitsRemaining" type="text" />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-around ca-font-regular">
                    <Col lg={5}>
                      <CustomTextField handleChange={handleChange} label={"Description"} name="description" type="text" />
                    </Col>
                  </Row>
                </Col>
              </Form>
            </ModalBody>
            <ModalFooter>
              <LoaderButton
                type="search"
                isLoading={isSubmitting}
                className="btn-primary"
                text="Update Input"
                style={{
                  width: 140,
                  marginRight: "10px",
                  backgroundColor: "#3bc552",
                  borderRadius: "0px 8px",
                }}
                id="mc-embedded-subscribe"
                onClick={handleSubmit}
              />
              <Button className="form-btn-2 ca-font-regular" onClick={toggleModal}>
                {" "}
                Cancel{" "}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
export default EditInputMgtModal;
