import { Form, Formik } from "formik";
import { ModalBody, ModalFooter, Button } from "reactstrap";
import React from "react";
import LoaderButton from "../../Loader/LoaderButton";
import managerSchema from "../../../utils/schema/WarehouseManagerShema";
import CustomTextField from "../../Forms/customFields/CustomTextField";
import CustomSelectLocation from "../../Forms/customFields/CustomSelectLocation";
import CustomSelectField from "../../Forms/customFields/CustomSelectField";

const NewWarehouseManagerModal = ({ toggleModal, createWarehouseManager }) => {
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        lga: "",
        state: "",
        address: "",
        gender: "",
      }}
      validationSchema={managerSchema}
      onSubmit={(values, { setSubmitting }) => {
        createWarehouseManager(values, setSubmitting, toggleModal);
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        isSubmitting,
        handleSubmit,
      }) => {
        const { CustomLgaSelect, CustomStateSelect } = CustomSelectLocation(
          { value: "state", label: "State" },
          { value: "lga", label: "LGA" },
          setFieldValue,
          values.state,
          values.lga
        );
        return (
          <Form>
            <ModalBody>
              <div className="form-grid">
                <div className="form-grid__individual">
                  <CustomTextField
                    handleChange={handleChange}
                    label="First Name"
                    name={"firstName"}
                    type="text"
                  />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField
                    handleChange={handleChange}
                    label="Last Name"
                    name={"lastName"}
                    type="text"
                  />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField
                    handleChange={handleChange}
                    label="Phone Number"
                    name={"phone"}
                    type="text"
                  />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField
                    handleChange={handleChange}
                    label="Address"
                    name={"address"}
                    type="text"
                  />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField
                    handleChange={handleChange}
                    label="Email Address"
                    name={"email"}
                    type="email"
                  />
                </div>
                <div className="form-grid__individual">
                  <CustomTextField
                    handleChange={handleChange}
                    label="Password"
                    name={"password"}
                    type="password"
                  />
                </div>
                <div className="form-grid__individual">{CustomStateSelect}</div>
                <div className="form-grid__individual">{CustomLgaSelect}</div>
                <div className="form-grid__individual">
                  <CustomSelectField
                    label={"Gender"}
                    onChange={(option) => setFieldValue("gender", option.value)}
                    options={genderOptions}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <LoaderButton
                className="btn-primary"
                text="Create Manager"
                isLoading={isSubmitting}
                style={{
                  width: 180,
                  marginRight: "10px",
                  backgroundColor: "#3bc552",
                  borderRadius: "0px 8px",
                }}
                onClick={handleSubmit}
                id="mc-embedded-subscribe"
              />
              <Button
                className="form-btn-2 ca-font-regular"
                onClick={() => toggleModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewWarehouseManagerModal;
