export const ADD_BUTTON_CLICKED = "ADD_BUTTON_CLICKED";
export const MODAL_STATE = "MODAL_STATE";
export const RESET_MODAL_STATE = "RESET_MODAL_STATE";
export const FROM_LOCATION = "FROM_LOCATION";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_SINGLE_DATE = "SET_SINGLE_DATE";
export const SET_TRANSACTION_CLICKED = "SET_TRANSACTION_CLICKED";

export const setSingleDate = date => dispatch =>
  dispatch({
    type: SET_SINGLE_DATE,
    payload: date,
  });

export const setDateRange = dateRange => dispatch => {
  dispatch({ type: SET_DATE_RANGE, payload: dateRange });
};

export const handle_modal_state = modalName => dispatch => {
  dispatch({
    type: MODAL_STATE,
    payload: modalName,
  });
};

export const setTransactionClicked = transactionClicked => dispatch => {
  dispatch({
    type: SET_TRANSACTION_CLICKED,
    payload: transactionClicked,
  });
};

export const add_button_toggle = clickState => dispatch => {
  dispatch({
    type: ADD_BUTTON_CLICKED,
    payload: clickState,
  });
  dispatch(handle_modal_state());
};

export const reset_modal_state = () => dispatch => {
  dispatch({
    type: RESET_MODAL_STATE,
  });
};

export const from_location = location => dispatch => {
  dispatch({
    type: FROM_LOCATION,
    payload: location,
  });
};
