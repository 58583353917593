import React from "react";
import { Redirect, Route } from "react-router-dom";
import { authChecker } from "../services/authService";

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (authChecker()) {
        return <Component {...props} />;
      } else {
        let fromPath = props.location;
        return (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: fromPath }
            }}
          />
        );
      }
    }}
  />
);

const NoAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let fromPath = props.location;
      if (authChecker()) {
        return (
          <Redirect
            to={{
              pathname: "/dashboard/home",
              state: { from: fromPath }
            }}
          />
        );
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

export { AuthRoute, NoAuthRoute };
