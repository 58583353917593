import React from "react";
import { Label } from "reactstrap";
import { customStyles } from "../../../utils/dropdownIndicatorStyle";
import Select from "react-select";
import useTranslate from "../../../hooks/useTranslate";

export const setSelectValue = (options, value) => {
  return options.find((option) => option.value === value);
};
const CustomSelectField = ({
  label,
  options,
  onChange,
  selectValue,
  placeholder = "Select",
  required,
  ...props
}) => {
  const { t } = useTranslate();
  return (
    <>
      {label && <Label>{label}
          {required && (
            <span className="ml-1" style={{ color: "red" }}>
              *
            </span>
          )}</Label>}
      <Select
        styles={{
          container: customStyles.container,
          control: customStyles.control,
          dropdownIndicator: customStyles.dropdownIndicatorStyles,
        }}
        options={options}
        value={options ? selectValue : ""}
        onChange={onChange}
        placeholder={t(placeholder)}
        {...props}
      />
    </>
  );
};

export default CustomSelectField;
