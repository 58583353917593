import {
  GET_ALL_SUBSCRIPTION,
  HANDLE_PAGE_CHANGE_OUTPUT_MANAGEMENT,
  FILTER_SINGLE_SUBSCRIPTION,
  HANDLE_PAGE_CHANGE_FILTER_SINGLE_PAGE_CHANGE,
  RESET_PAGINATION_VALUES_AGENTS,
  SET_SELECTED_PARAMS,
  SET_TABLE,
} from "./action";

const initialState = {
  subscriptionData: [],
  subscription_plan_details: {},
  subscription_plan_inputs: [],
  subscription_plan_outputs: {},
  pageSize: 1,
  pageNumberOutput: 1,
  offset: 0,
  info: [],
  searchParams: [
    { value: "outputType", label: "output type" },
    { value: "outputCategory", label: "category" },
    { value: "outputName", label: "name" },
    { value: "outputState", label: "state" },
    { value: "outputLga", label: "lga" },
    { value: "outputReference", label: "reference" },
  ],
  selectedSearchParam: {},
  table: "outputManagement",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.payload,
        selectedSearchParam: {},
        pageNumberOutput: 1,
        table: "outputManagement",
      };
    case SET_SELECTED_PARAMS:
      return {
        ...state,
        selectedSearchParam: action.payload,
      };

    case SET_TABLE:
      return {
        ...state,
        table: action.payload,
      };
    case RESET_PAGINATION_VALUES_AGENTS:
      return {
        ...state,
        pageNumberOutput: 1,
      };
    case HANDLE_PAGE_CHANGE_OUTPUT_MANAGEMENT:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberOutput: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberOutput: pageNumberOld,
        };
      }
      return { ...state };
    case FILTER_SINGLE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.payload,
      };
    case HANDLE_PAGE_CHANGE_FILTER_SINGLE_PAGE_CHANGE:
      const filterSingleTerm = action.payload;
      if (filterSingleTerm === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          filteredBySingleSearchPageNumber: pageNumberNew,
        };
      }
      if (filterSingleTerm >= 10) {
        let pageNumberNew = filterSingleTerm / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          filteredBySingleSearchPageNumber: pageNumberOld,
        };
      }
      return { ...state };
    default:
      return state;
  }
};
