import { toast } from "react-toastify";
import farmers from "../../api/farmers";
import { authChecker } from "../../services/authService";
import { getDateParam, resetDateRange } from "../../utils/dateParams";
export const GET_ALL_OTP_REQUEST = "GET_ALL_OTP_REQUEST";
export const HANDLE_PAGE_CHANGE_OTP = "HANDLE_PAGE_CHANGE_OTP";
export const SET_SEARCHED_OTP_REQUEST = "SET_SEARCHED_OTP_REQUEST";
export const HANDLE_PAGE_CHANGE_DATE_RANGE = "HANDLE_PAGE_CHANGE_DATE_RANGE";
export const CHANGE_SELECTED_PARAM_OTP = "CHANGE_SELECTED_PARAM_OTP";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET PAGINATION START VALUES";
export const HANDLE_PAGE_CHANGE_SINGLE_DATE = "HANDLE_PAGE_CHANGE_SINGLE_DATE";
//newly written
export const SET_DATE = "SET_DATE";
export const SET_ALL_OTP_DATA = "SET_ALL_OTP_DATA";
export const SET_SINGLE_DATE = "SET_SINGLE_DATE";
//set START and END date

//Getting all OTP of Agents
export const getAllOTPRequest = () => dispatch => {
  authChecker();
  resetDateRange(dispatch);
  return farmers
    .get("/users/otp")
    .then(OTPrequest => {
      dispatch({
        type: GET_ALL_OTP_REQUEST,
        payload: OTPrequest.data,
      });
    })
    .catch(err => {
      toast.error("Something went wrong fetching All OTP Request, try refreshing the page");
    });
};
//Search for OTP of a user by using phone Number
export const getNewOTPSearch = values => (dispatch, getState) => {
  authChecker();
  const state = getState();
  const dateParams = getDateParam(state.common);
  let { term, key } = values;

  term = term.trim();
  if ((key === "verified" || !key) && term) {
    const trimmed = term.trim();
    term = trimmed.toLowerCase() === "true" ? "yes" : "no";
    term = trimmed.toLowerCase() === "yes" ? true : false;
  }
  let selectedParam = {
    [key ?? "verified"]: term,
  };

  if (term === "") {
    selectedParam = {};
  }
  const params = { ...selectedParam, ...dateParams };
  return farmers
    .get("/users/otp", { params })
    .then(OTPrequestSearched => {
      dispatch({
        type: SET_SEARCHED_OTP_REQUEST,
        payload: OTPrequestSearched.data,
      });
      dispatch({
        type: CHANGE_SELECTED_PARAM_OTP,
        payload: selectedParam,
      });
      dispatch({
        type: RESET_PAGINATION_VALUES_AGENTS,
      });
      if (OTPrequestSearched.data.data.length <= 0) {
        toast.error(`${key} not found`);
      }
    })
    .catch(() => {});
};

export const handlePageChangeOTP = (pageNumber, pageSize) => (dispatch, getState) => {
  authChecker();
  const stateAgentOTP = getState().agentOTP;
  const dateParams = getDateParam(getState().common);
  const selectedSearch = stateAgentOTP.selectedSearchParamsAgentOTP;
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    ...selectedSearch,
    ...dateParams,
  };

  return farmers
    .get("/users/otp", { params })
    .then(OTPrequest => {
      dispatch({
        type: SET_SEARCHED_OTP_REQUEST,
        payload: OTPrequest.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE_OTP,
        payload: OTPrequest.data.skip,
      });
    })
    .catch(err => {
      toast.error("Something went wrong fetching Farmers, try refreshing the page");
    });
};
