import { Form, Formik } from "formik";
import React from "react";
import { Button, Col, ModalBody, ModalFooter, Row } from "reactstrap";
import CustomSelectField from "../../Forms/customFields/CustomSelectField";
import LoaderButton from "../../Loader/LoaderButton";
import ModalColumn from "../modalColumn_tempt";

const ViewSupplier = ({ supplier, createTrackingOptions, handleSelectedOption, selectedOption, toggleModal, trackUserTransaction }) => {
  return (
    <Formik
      initialValues={{
        id: supplier?._id,
        tracking: selectedOption?.value || "",
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        values.tracking = selectedOption?.value || "";
        trackUserTransaction(values, { setSubmitting, setErrors }, toggleModal);
        window.location.reload();}}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form>
          <ModalBody>
            <Col>
              <Row className="d-flex justify-content-between ca-font-regular">
                <ModalColumn result={supplier?.agentName} title="Agent Name" />
                <ModalColumn result={supplier?.commodityType} title="Commodity Type" />
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <ModalColumn result={supplier?.driverName} title="Driver Name" />
                <ModalColumn result={supplier?.driverNumber} title="Driver Number" />
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <ModalColumn result={supplier?.quantity} title="Quantity" />
                <ModalColumn result={supplier?.truckNumber} title="Truck Number" />
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <ModalColumn result={supplier?.comment} title="Comment" />
                <ModalColumn result={supplier?.accountName} title="Account Name" />
              </Row>
              <Row className="d-flex justify-content-between ca-font-regular mt-3">
                <ModalColumn result={supplier?.bank} title="Bank" />
                <ModalColumn result={supplier?.accountNumber} title="Account Number" />
              </Row>

              <Row className="mb-3 d-flex justify-content-around ca-font-regular" style={{ paddingTop: 20 }}>
                <Col className="d-flex align-items-center flex-column">
                  <CustomSelectField
                    label={"Track Transaction"}
                    onChange={handleSelectedOption}
                    options={createTrackingOptions}
                    selectValue={selectedOption}
                  />
                </Col>
              </Row>
            </Col>
          </ModalBody>
          <ModalFooter>
            <LoaderButton
              className="form-btn-1 ca-font-regular"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              type="submit"
              text="Track Transaction"
              style={{
                marginRight: "10px",
                width: "180px",
                borderRadius: "0px 8px",
                backgroundColor: "#3bc552",
              }}
              id="mc-embedded-subscribe"
            />
            <Button className="form-btn-2 ca-font-regular" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ViewSupplier;
