import {
  GET_ALL_RESELLER,
  GET_ALL_RESELLER_REQUEST,
  SUCESSFUL_APPROVAL,
  HANDLE_PAGE_CHANGE_GET_ALL_RESELLER,
  HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST,
  HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST_FILTER,
  CHANGE_RESELLER_ACTIVE,
} from "./action";

const initialState = {
  resellerActive: true,
  reseller: [],
  resellerRequest: [],
  resellerAprproval: 0,
  pageNumberATR: 1,
  pageNumberATREQ: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_RESELLER:
      return { ...state, reseller: action.payload, pageNumberATR: 1 };
    case GET_ALL_RESELLER_REQUEST:
      return { ...state, resellerRequest: action.payload, pageNumberATREQ: 1 };
    case SUCESSFUL_APPROVAL:
      return {
        ...state,
        resellerAprproval: action.pay,
      };
    case HANDLE_PAGE_CHANGE_GET_ALL_RESELLER:
      const handlePageNumberATR = action.payload;
      if (handlePageNumberATR === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberATR: pageNumberNew,
        };
      }
      if (handlePageNumberATR >= 10) {
        let pageNumberNew = handlePageNumberATR / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberATR: pageNumberOld,
        };
      }
      break;
    case HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST:
      const handlePageNumberATREQ = action.payload;
      if (handlePageNumberATREQ === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberATREQ: pageNumberNew,
        };
      }
      if (handlePageNumberATREQ >= 10) {
        let pageNumberNew = handlePageNumberATREQ / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberATREQ: pageNumberOld,
        };
      }
      break;
    case HANDLE_PAGE_CHANGE_GET_ALL_RESELLER_REQUEST_FILTER:
      const handlePageNumberATREQFilter = action.payload;
      if (handlePageNumberATREQFilter === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberATREQFilter: pageNumberNew,
        };
      }
      if (handlePageNumberATREQFilter >= 10) {
        let pageNumberNew = handlePageNumberATREQFilter / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberATREQFilter: pageNumberOld,
        };
      }
      break;
    case CHANGE_RESELLER_ACTIVE:
      return {
        ...state,
        resellerActive: action.payload,
        pageNumberATR: 1,
        pageNumberATREQ: 1,
      };
    default:
      return state;
  }
};
