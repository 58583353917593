import { authChecker } from "../../services/authService";
import { toast } from "react-toastify";
import { resetDateRange } from "../../utils/dateParams";
import { getDateParam } from "../../utils/dateParams";
import transaction from "../../api/transaction";

export const TOGGLE_TRANSACTION_STATE = "TOGGLE_TRANSACTION_STATE";
export const GET_ALL_TRANSACTION = "GET_ALL_TRANSACTIONS";
export const TRACK_TRANSACTION = "TRACK_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
export const HANDLE_PAGE_CHANGE_TRANSACTION = "HANDLE_PAGE_CHANGE_TRANSACTION";
export const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
export const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
export const CLEAR_TRANSACTION_FORM = "CLEAR_TRANSACTION_FORM";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const SEARCH_TRANSACTION = "SEARCH_TRANSACTION";
export const FILTER_SINGE_TRANSACTION_SINGEDATE = "FILTER_SINGE_TRANSACTION_SINGEDATE";
export const SET_SELECTED_PARAMS = "set selected params";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET PAGINATION VALUES AGENTS";

export const transactionServiceTypes = { input: "input", output: "output" };

export const toggleTransactionState = () => dispatch => {
  dispatch({
    type: TOGGLE_TRANSACTION_STATE,
  });
};

export const getAllTransaction = type => dispatch => {
  authChecker();
  const params = {};
  if (type) {
    params.serviceType = type;
  }
  resetDateRange(dispatch);
  return transaction
    .get("transactions", { params })
    .then(transactionData => {
      dispatch({
        type: GET_ALL_TRANSACTION,
        payload: transactionData.data,
      });
    })
    .catch(err => {
      toast.error("Something went wrong, try refreshing the page");
    });
};
/***** Tracking Transaction *******/
export const trackTransaction =
  (values, { setSubmitting, setErrors }, handleModalToggle) =>
  (dispatch, getState) => {
    authChecker();
    const { common } = getState();
    return transaction
      .put("transactions/track", values)
      .then(trackTransaction => {
        handleModalToggle();
        toast.success(trackTransaction.data.message);
        dispatch({
          type: TRACK_TRANSACTION,
          payload: trackTransaction.data,
        });
        if (common.location === "InputMangement") dispatch(getAllTransaction(transactionServiceTypes.input));
        else if (common.location === "outputManagement") dispatch(getAllTransaction(transactionServiceTypes.output));
        else dispatch(getAllTransaction());
      })
      .catch(err => {
        toast.error(err.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

/***** Update Transaction *******/
export const upDateTransaction =
  (values, { setSubmitting, setErrors }, handleModalToggle) =>
  (dispatch, getState) => {
    authChecker();
    const { common } = getState();
    return transaction
      .put("transactions", values)
      .then(trackTransaction => {
        handleModalToggle();
        toast.success(trackTransaction.data.status + " " + trackTransaction.data.message);
        if (common.location === "InputMangement") dispatch(getAllTransaction(transactionServiceTypes.input));
        else if (common.location === "outputManagement") dispatch(getAllTransaction(transactionServiceTypes.output));
        else dispatch(getAllTransaction());
      })
      .catch(err => {
        toast.error(err.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };
/******End of Transaction Update **************/
export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;
  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};
export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  authChecker();
  const { transaction, common } = getState();
  const dateParams = getDateParam(common);
  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    ...transaction.selectedSearchParam,
    ...dateParams,
  };

  return transaction
    .get("transactions", { params })
    .then(transactionData => {
      dispatch({
        type: SEARCH_TRANSACTION,
        payload: transactionData.data,
      });
      dispatch({
      type: HANDLE_PAGE_CHANGE_TRANSACTION,
   
        payload: transactionData.data.skip,
      });
    })
    .catch(errData => {
      toast.error(errData.message);
    });
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY,
    payload: arrayOfInfo,
  });
};
/**
 * Searching for transaction using transaction reference
 */
export const searchTransactions = (values, type) => (dispatch, getState) => {
  authChecker();
  const dateParams = getDateParam(getState().common);

  let { term, key } = values;
  let defaultKey = "trackingReference";

  let selectedParam = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    selectedParam = {};
  }
  if (type) {
    selectedParam.serviceType = type;
  }
  const params = {
    // search: term,
    ...selectedParam,
    ...dateParams,
  };
  return transaction
    .get("transactions", { params })
    .then(searchTransaction => {
      dispatch({
        type: SEARCH_TRANSACTION,
        payload: searchTransaction.data,
      });
      dispatch({
        type: SET_SELECTED_PARAMS,
        payload: selectedParam,
      });
      dispatch({
        type: RESET_PAGINATION_VALUES_AGENTS,
      });
      if (!searchTransaction.data.data.length) {
        toast.error("Transaction not found for this user");
      }
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
};

export const AllTransactions = state => {
  authChecker();
  const { transaction, common } = state;
  const dateParams = getDateParam(common);
  const params = {
    skip: 0,
    limit: 0,
    ...transaction.selectedSearchParam,
    ...dateParams,
  };

  return transaction.get("transactions", { params });
};
