import React from "react";
import DropdownView from "../Dropdown/Dropdown";

const TableBody = ({ fields, dropDowns, handlePicked }) => {
  const bodyData = fields.map((item) => {
    const data = item?.data;

    return (
      <tr key={item.key}>
        {data?.body?.map((data, index) => {
          return (
            <td className="header-text" key={index}>
              {data}
            </td>
          );
        })}
        {data?.dropDowns && (
          <td className="px-0 header-text">
            <DropdownView
              id={item.key}
              handlePicked={handlePicked}
              editAccess={data?.dropDowns?.editAccess}
              negotiate={data?.dropDowns?.negotiate}
              assignAgentOffer={data?.dropDowns?.assignAgentOffer}
              deleteAccess={data?.dropDowns?.deleteAccess}
              trackTransaction={data?.dropDowns?.trackTransaction}
              lockUser={data?.dropDowns?.lockUser}
              unlockUser={data?.dropDowns?.unlockUser}
              assignRole={data?.dropDowns?.assignRole}
              verifyCompany={data?.dropDowns?.verifyCompany}
              viewFarmers={data?.dropDowns?.viewFarmers}
              weightTicket={data?.dropDowns?.weightTicket}
            />
          </td>
        )}
      </tr>
    );
  });

  return <tbody className="body">{bodyData}</tbody>;
};

export default TableBody;
