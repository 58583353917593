import dayjs from "dayjs";
import React from "react";
import { Col, ModalBody, Row } from "reactstrap";
import currencyFormat from "../../lib/AmountFormat";

const ViewOutputMgtModal = ({ outputObject }) => {
  return (
    <ModalBody>
      <Col>
        <Row className="mb-4 d-flex justify-content-around">
          <Col className="d-flex">
            <div className="farmer-img-info d-flex flex-column justify-content-center ca-font-regular">
              <div className="form-header-color">
                {`${outputObject.outputReference} `}
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div>
                  {" "}
                  <span>Created On : </span>
                </div>
                <div>
                  {dayjs(outputObject.meta.createdAt).format(
                    "YYYY-MM-DD (HH:mm:ss A)"
                  )}
                </div>
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div>
                  {" "}
                  <span>Updated On : </span>
                </div>
                <div>
                  {dayjs(outputObject.meta.updatedAt).format(
                    "YYYY-MM-DD (HH:mm:ss A)"
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular">
          <Col>
            <div className="view-profile-subheader">Minimum Request Limit</div>
            <div className="view-profile-fieldstyle">
              {outputObject.minimumRequestLimit + " bags"}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Output Type</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputType}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Output Category</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputCategory}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Output Name</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputName}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Output State</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputState}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Output Lga</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputLga}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Per Unit Price</div>
            <div className="view-profile-fieldstyle">
              {currencyFormat(outputObject.perUnitPrice)}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Unit Of Measurement</div>
            <div className="view-profile-fieldstyle">
              {outputObject.unitOfMeasurement}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col className="">
            <div className="view-profile-subheader">Weight</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputWeight_in_unitOfMeasurement + "Kg"}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Total Units Required</div>
            <div className="view-profile-fieldstyle">
              {outputObject.totalUnitsRequired + " bags"}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Units Remaining</div>
            <div className="view-profile-fieldstyle">
              {outputObject.unitsRemaining + " bags"}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Output Instock</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputRequired === true ? "Yes" : "No"}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Quality Requirement</div>
            <div className="view-profile-fieldstyle">
              {outputObject.qualityRequirement}{" "}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Description</div>
            <div className="view-profile-fieldstyle">
              {outputObject.description}{" "}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Moisture Content</div>
            <div className="view-profile-fieldstyle">
              {outputObject.moistureContentPercentage + " %"}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Discoloring</div>
            <div className="view-profile-fieldstyle">
              {outputObject.discoloringPercentage + "%"}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Dirt Ratio</div>
            <div className="view-profile-fieldstyle">
              {outputObject.dirtRatioPercentage + "%"}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Type Of Grain</div>
            <div className="view-profile-fieldstyle">
              {outputObject.typeOfGrain || "Not available"}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          {/* <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">KOR</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputKOR + "%"}
            </div>
          </Col> */}
        </Row>
        {/* <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Nut count</div>
            <div className="view-profile-fieldstyle">
              {outputObject.nutCount_in_unitOfMeasurement + "%"}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Defective</div>
            <div className="view-profile-fieldstyle">
              {outputObject.outputDefectivePercentage + "%"}
            </div>
          </Col>
        </Row> */}
      </Col>
    </ModalBody>
  );
};

export default ViewOutputMgtModal;
