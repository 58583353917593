import Dashboard from "../layouts/Dashboard";
import Redirect from "../views/pages/Redirect";
import Sign from "../layouts/Sign";

const indexRoutes = [
  { auth: true, path: "/dashboard", name: "Home", component: Dashboard },
  { noAuth: true, path: "/", name: "Sign", component: Sign },
  { path: "/redirect", name: "Redirect", component: Redirect }
];

export default indexRoutes;
