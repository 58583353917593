import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFarmersThatAgentsOnboarded, HandlePageAgentOnboard } from "../../../features/farmers/actions";
import farmerOnboarded from "../../../assets/icons/farmer-onboarded.svg";
import { TableLoaderFarmer } from "../../../components/common/TableLoader";
import CustomModalWrapper from "../../../components/modal/customModalWrapper";
import FarmersAgentOnboardedModal from "../../../components/modal/farmerViewModals/farmersAgentOnboarded";

function FarmersAgentOnboarded({ isOpen, toggle, key, close, userID, userName, lastName, phoneNumber, ...props }) {
  const { allFarmersAgentOnboarded, AgentsFarmer, totalDocumentCount, currentPage, pageSize, HandlePageAgentOnboard } = props;
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    allFarmersAgentOnboarded(userID)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  const handlePageSwitch = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "agentsFarmerOnboarded") return null;
    setIsLoading(true);
    HandlePageAgentOnboard(userID, pageNumber, pageSize)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  };
  return (
    <CustomModalWrapper HeaderTitle={"Farmers onboarded by Agent"} showDefaultFooter isOpen={isOpen} toggleModal={toggle}>
      {loading ? (
        <TableLoaderFarmer />
      ) : (
        <FarmersAgentOnboardedModal
          AgentsFarmer={AgentsFarmer}
          currentPage={currentPage}
          farmerOnboarded={farmerOnboarded}
          handlePageSwitch={handlePageSwitch}
          isLoading={isLoading}
          totalDocumentCount={totalDocumentCount}
          pageSize={pageSize}
          userName={userName}
          lastName={lastName}
          phoneNumber={phoneNumber}
        />
      )}
    </CustomModalWrapper>
  );
}
const mapStateToProps = state => ({
  AgentsFarmer: state.farmer.agentsOnboardedByFarmers.data || [],
  totalDocumentCount: state.farmer.agentsOnboardedByFarmers.totalDocumentCount,
  pageNumber: state.farmer.agentsOnboardedByFarmers.skip,
  pageSize: state.farmer.agentsOnboardedByFarmers.limit,
  currentPage: state.farmer.agentsOnboardedByFarmersCurrentPage,
});
const mapDispatchToProps = dispatch => {
  return {
    allFarmersAgentOnboarded: userID => dispatch(getFarmersThatAgentsOnboarded(userID)),
    HandlePageAgentOnboard: (userID, pageNumber, pageSize) => dispatch(HandlePageAgentOnboard(userID, pageNumber, pageSize)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FarmersAgentOnboarded);
