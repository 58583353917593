import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import {
  getAllNegotiation,
  updateEvent,
} from '../../../features/negotation/action';
import { negotiateOptions } from '../../../views/Dashboard/component/negotationStages';
import CustomSelectField, {
  setSelectValue,
} from '../../Forms/customFields/CustomSelectField';
import LoaderButton from '../../Loader/LoaderButton';
import ModalColumn from '../modalColumn_tempt';

const Negotiate = ({ buyObj, toggleModal }) => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        id: buyObj?._id,
        event: buyObj?.status,
      }}
      //validationSchema={managerSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(updateEvent(values, { setSubmitting })).then(() => {
          toggleModal();
          window.location.reload();
          
        });
      }}
    >
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
        return (
          <Form>
            <ModalBody>
              <Col>
                <Row className='d-flex justify-content-between ca-font-regular'>
                  <ModalColumn
                    result={
                      buyObj?.input?.inputName || buyObj?.output?.outputName
                    }
                    title='Product Name'
                  />
                  <ModalColumn result={buyObj?.units} title='Total unit' />
                </Row>
                <Row className='d-flex justify-content-between ca-font-regular mt-3'>
                  <ModalColumn
                    result={buyObj?.originalPricePerBag}
                    title='Original Price Per Bag'
                  />
                  <ModalColumn
                    result={buyObj?.negotiationPricePerBag}
                    title='Negotiated Price Per Bag'
                  />
                </Row>
                <Row className='d-flex justify-content-between ca-font-regular mt-3'>
                  <ModalColumn
                    result={buyObj?.approved ? 'Yes' : 'No'}
                    title='is Approved'
                  />
                  <ModalColumn
                    result={moment(buyObj?.updatedAt).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}
                    title='last Updated At'
                  />
                </Row>
                {buyObj?.approved && buyObj?.approvedBy && (
                  <Row className='d-flex justify-content-between ca-font-regular mt-3'>
                    <ModalColumn
                      result={`${buyObj?.approvedBy?.firstName} ${buyObj?.approvedBy?.lastName}`}
                      title='Approved By'
                    />
                    <ModalColumn
                      result={buyObj?.approvedBy?.email}
                      title='Approvers Email'
                    />
                  </Row>
                )}
              </Col>
              <div className='form-grid'>
                <div className='form-grid__individual'>
                  <CustomSelectField
                    label={'status'}
                    onChange={(options) =>
                      setFieldValue('event', options.value)
                    }
                    options={negotiateOptions}
                    selectValue={setSelectValue(negotiateOptions, values.event)}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <LoaderButton
                className='btn-primary'
                text='Update Event'
                isLoading={isSubmitting}
                style={{
                  width: 180,
                  marginRight: '10px',
                  backgroundColor: '#3bc552',
                  borderRadius: '0px 8px',
                }}
                onClick={handleSubmit}
                id='mc-embedded-subscribe'
              />
              <Button
                className='form-btn-2 ca-font-regular'
                onClick={() => toggleModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Negotiate;
