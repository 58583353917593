import React from "react";
import { Table } from "reactstrap";
import NoResult from "./noResult";
import TableBody from "./tableBody";
import TableHeader from "./TableHeader";

const CustomTable = ({ headerArr, bodyArray, dropDowns, handlePicked }) => {
  return (
    bodyArray.length > 0 ? (
    <Table striped hover className="table bg-white mt-4 shadow" borderless>
      <thead>
        <tr>
          <TableHeader headerToRender={headerArr} className="headers" />
        </tr>
      </thead>
      <TableBody
        dropDowns={dropDowns}
        fields={bodyArray}
        handlePicked={handlePicked}
      />
    </Table>):<NoResult/>
  );
};

export default CustomTable;
