import {
  GET_ALL_ADMINS,
  FILTER_ADMINS,
  HANDLE_PAGE_CHANGE,
  RESET_PAGINATION,
  SET_SEARCH_PAGE,
  SET_SELECT_PARAMS,
} from "./action";

const initialState = {
  allAdmins: {},
  currentPage: 1,
  searchParams: [
    {
      value: "state",
      label: "state",
    },
    {
      value: "lga",
      label: "local government",
    },
    {
      value: "role",
      label: "role",
    },
    {
      value: "name",
      label: "name",
    },
    {
      value: "adminId",
      label: "id",
    },
  ],
  selectedParams: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADMINS:
      return {
        ...state,
        allAdmins: action.payload,
        selectedParams: {},
        currentPage: 1,
      };
    case FILTER_ADMINS:
      return { ...state, allAdmins: action.payload };
    case RESET_PAGINATION:
      return { ...state, currentPage: 1 };
    case SET_SEARCH_PAGE:
      return { ...state, allAdmins: action.payload };
    case HANDLE_PAGE_CHANGE:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          currentPage: pageNumberNew,
        };
      }

      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          currentPage: pageNumberOld,
        };
      }
      return { ...state };
    case SET_SELECT_PARAMS:
      return { ...state, selectedParams: action.payload };
    default:
      return state;
  }
};

export default reducer;
