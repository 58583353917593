import React, { useState } from "react";
import { ModalBody } from "reactstrap";
import { BsDownload } from "react-icons/bs";

const WeightTicket = ({ supplier }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showDownloadIcon, setShowDownloadIcon] = useState(false);

  const images = supplier?.waybillUrl || [];
  const imageUrl = images[currentImageIndex] || "https://placehold.co/600x400?text=No+Weight+Ticket";

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleImageDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = imageUrl;
    downloadLink.download = `WeightTicket-${currentImageIndex + 1}`;
    downloadLink.click();
  };

  return (
    <ModalBody>
      <div className="mr-3 mb-3">
        <div className="image-container">
          <div
            className="image-wrapper"
            style={{ position: "relative" }}
            onMouseEnter={() => setShowDownloadIcon(true)}
            onMouseLeave={() => setShowDownloadIcon(false)}
          >
            <img
              src={imageUrl}
              width={600}
              height={400}
              alt="Weight Ticket"
              className="img-square"
            />
            {showDownloadIcon && (
              <div
                className="download-icon"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                }}
                onClick={handleImageDownload}
              >
                <BsDownload size={30} color="white" />
              </div>
            )}
          </div>
        </div>
        {images.length > 1 && (
          <div className="image-thumbnails" style={{ paddingTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                width={80}
                height={60}
                alt={`Thumbnail ${index}`}
                className={`thumbnail ${index === currentImageIndex ? "active" : ""}`}
                onClick={() => handleImageChange(index)}
                style={{ cursor: "pointer", marginRight: "5px" }}
              />
            ))}
          </div>
        )}
      </div>
    </ModalBody>
  );
};

export default React.memo(WeightTicket);
