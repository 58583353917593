import { setDateRange } from "../features/common/actions";

export const resetDateRange = (dispatch) => {
  dispatch(setDateRange({ startDate: null, endDate: new Date() }));
};
export const getDateParam = (state) => {
  const { startDate, endDate, singleDate } = state;
  const params={}
  if (singleDate) {
    params.date = singleDate;
  }
  if (startDate && endDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  return params;
};
