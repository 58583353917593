// import components for sidebar and other views here
// norma

import finance from "../assets/icons/money-stack.svg";
import overview_fallback from "../assets/icons/overview-fallback.svg";
import overview from "../assets/icons/overview.svg";
import subscription from "../assets/icons/subscription.svg";
import warehouse from "../assets/icons/truck-loading.svg";
import users from "../assets/icons/users-alt.svg";
import Overview from "../views/Dashboard/Overview";
import Supplier from "../views/Dashboard/Supplier.js";
import TradeApproved from "../views/Dashboard/TradeApproved";
import admin from "../views/Dashboard/admin";
import Farmers from "../views/Dashboard/agent";
import AgentsOTP from "../views/Dashboard/agentOTP";
import InputManagement from "../views/Dashboard/inputManagement";
import OutputManagement from "../views/Dashboard/outPutManagement";
import Transaction from "../views/Dashboard/transaction";
import ErrorPage from "../views/pages/404Page";

const dashboardRoutes = [
  {
    path: "/dashboard/home",
    name: "Overview",
    icon: overview,
    fallback_icon: overview_fallback,
    component: Overview,
  },
  {
    path: "/dashboard/admins/management",
    name: "Admins",
    icon: users,
    component: admin,
  },
  {
    path: "/dashboard/agents",
    name: "agents",
    icon: users,
    component: Farmers,
  },
  {
    path: "/dashboard/agentOTP",
    name: "Agent OTP",
    icon: users,
    component: AgentsOTP,
  },
  {
    path: "/dashboard/negotiate",
    name: "negotiate",
    icon: finance,
    component: TradeApproved,
  },
  {
    path: "/dashboard/supplier",
    name: "supplier",
    icon: users,
    component: Supplier,
  },
  // {
  //   path: "/dashboard/farmers",
  //   name: "farmers",
  //   icon: tree,
  //   component: Agents,
  // },
  {
    path: "/dashboard/transaction",
    name: "Transaction",
    icon: finance,
    component: Transaction,
  },
  // {
  //   path: "/dashboard/reseller/management",
  //   name: "Reseller Request",
  //   icon: warehouse,
  //   component: ResellerManagement,
  // },
  {
    path: "/dashboard/input/management",
    name: "Input MGT",
    icon: warehouse,
    component: InputManagement,
  },
  {
    path: "/dashboard/output/management",
    name: "Output MGT",
    icon: subscription,
    component: OutputManagement,
  },
  // {
  //   path: "/dashboard/warehouse/management",
  //   name: "Warehouse MGT",
  //   icon: warehouse,
  //   component: Warehouse,
  // },
  {
    invisible: true,
    redirect: true,
    path: "/dashboard/",
    pathTo: "/dashboard/home",
    name: "Dashboard",
  },
  {
    invisible: true,
    component: ErrorPage,
  },
  //routes that have no tag on the sidebar should have a prop of invisible:true
];

export default dashboardRoutes;
