import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import Search from "../../components/Search";
import { from_location } from "../../features/common/actions";
import { connect } from "react-redux";

import Spacer from "../../components/common/Spacer";
import { TableLoader } from "../../components/common/TableLoader";
import DateFilter from "../../components/DatePicker/filter/dateFilter";
import useTranslate from "../../hooks/useTranslate";
import TableAndPagination from "./component/tableAndPagination";
import { getAllSupplier, handlePageChange } from "../../features/supplier/action";
import SupplierTable from "../DataTables/SupplierTable";

export const Tables = {
  buy: "buy",
  sell: "sell",
};

function Supplier(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const { getAllSupplier, totalDocumentCount, currentPage, pageSize, handlePageChange, updateLocation } = props;

  useEffect(() => {
    (async () => {
      try {
        await getAllSupplier();
        updateLocation("supplier");
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSwitch = (pageNumber, pageSize, pages, location) => {
    if (pageNumber < 1) return null;
    if (pageNumber > pages.length) return null;
    if (location !== "supplier") return null;
    setLoadingPage(true);
    handlePageChange(pageNumber, pageSize)
      .then(res => setLoadingPage(false))
      .catch(err => setLoadingPage(false));
  };

  const { t } = useTranslate();

  return (
    <div className="overview-content">
      <Col>
        <Col className="ca-font-medium" style={{ fontSize: 30, marginTop: 40 }}>
          {t("Supplier")}
        </Col>
        <DateFilter />
        <Spacer unit={20} />
        {loading ? (
          <div>
            <TableLoader />
          </div>
        ) : (
          <>
            <div>
              <Col>
                <Row className="d-flex justify-content-center">
                  <Search />
                </Row>
              </Col>

              <TableAndPagination
                Table={SupplierTable}
                currentPage={currentPage}
                handlePageSwitch={handlePageSwitch}
                loadingPage={loadingPage}
                totalDocumentCount={totalDocumentCount}
                pageSize={pageSize}
                location={"supplier"}
              />
            </div>
          </>
        )}
      </Col>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    totalDocumentCount: state?.supplier?.supplierData?.totalDocumentCount || 0,
    currentPage: state?.supplier?.pageNumber,
    pageSize: state?.supplier?.supplierData?.limit || 0,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllSupplier: () => dispatch(getAllSupplier()),
    handlePageChange: (pageNumber, pageSize) => dispatch(handlePageChange(pageNumber, pageSize)),

    updateLocation: location => dispatch(from_location(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
