import React from "react";
import { BiSearchAlt } from "react-icons/bi";

const NoResult = () => {
  return (
    <div
      className="w-100 h-100 d-flex justify-content-center align-items-center bg-white flex-column "
      style={{ marginTop: "100px" }}
    >
      <BiSearchAlt size={200} />
      <div className="no-result-text d-flex flex-column align-items-center">
        <h3>No Result Found</h3>
        <p>
          Try changing the filters or search term and try again. If you still
          don't find what you're looking for{" "}
        </p>
      </div>
    </div>
  );
};

export default NoResult;
