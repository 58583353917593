import React from "react";
import useTranslate from "../../hooks/useTranslate";

export default function TableHeader({ headerToRender }) {
  const { t } = useTranslate();
  const headers = headerToRender.map((header) => {
    return (
      <th key={header.id} className="header-text">
        {t(header.headerName)}
      </th>
    );
  });

  return <>{headers}</>;
}
