import React, { useState } from "react";
import { connect } from "react-redux";
import CustomTable from "../../components/Table";
import { tradeApprovalBuy } from "../../components/Table/body";
import { tradeApproval } from "../../components/Table/headers";
import Negotiate from "../../components/modal/TradeApproval/Negotiate";
import ViewNegotiations from "../../components/modal/TradeApproval/ViewTradeApproval";
import ViewWeightTicket from "../../components/modal/TradeApproval/viewWeightTicket";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import { useModal, useSelected } from "../../hooks";

const TradeApprovalTable = ({ negotiationData }) => {
  const [selected, handleClick] = useSelected(negotiationData);
  const [modal, handleModalToggle, showModal] = useModal();
  const [picked, setPicked] = useState("");
  const handlePicked = (optionPicked, id) => {
    setPicked(optionPicked);
    handleClick(id);
    showModal();
  };
  return (
    <>
      <CustomTable bodyArray={tradeApprovalBuy(negotiationData)} headerArr={tradeApproval} handlePicked={handlePicked} />
      {showModal && picked === "view" && (
        <CustomModalWrapper HeaderTitle={"View Negotiation"} isOpen={modal} toggleModal={handleModalToggle} showDefaultFooter>
          <ViewNegotiations buyObj={selected[0]} />
        </CustomModalWrapper>
      )}
      {showModal && picked === "negotiate" && (
        <CustomModalWrapper isOpen={modal} HeaderTitle={"Negotiate"} toggleModal={handleModalToggle}>
          <Negotiate buyObj={selected[0]} toggleModal={handleModalToggle} />
        </CustomModalWrapper>
      )}

     
    </>
  );
};
const mapStateToProps = state => ({
  modalOpen: state.common.modalOpen,
  clickState: state.common.clickState,
  negotiationData: state?.negotiation?.negotiationDataBuy?.data,
});

const mapDispatchToProps = _dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeApprovalTable);
