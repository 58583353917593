import {
  GET_ALL_INPUT_MANAGEMENT,
  UPDATE_INPUT_MANAGEMENT,
  HANDLE_PAGE_CHANGE_INPUT,
  FILTER_INPUT_SEARCH_TERM,
  SET_SELECTED_PARAMS,
  RESET_PAGINATION_VALUES_AGENTS,
  SET_TABLE,
} from "./action";

const initialState = {
  inputManagementData: [],
  pageNumberInput: 1,
  searchParams: [
    { value: "inputType", label: "Input Type" },
    { value: "inputCategory", label: "Category" },
    { value: "inputName", label: "Name" },
    { value: "inputState", label: "state" },
    { value: "inputLga", label: "lga" },
    { value: "inputReference", label: "Reference" },
  ],
  selectedSearchParam: {},
  table: "inputManagement",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INPUT_MANAGEMENT:
      return {
        ...state,
        inputManagementData: action.payload,
        selectedSearchParam: {},
        pageNumberInput: 1,
        table: "inputManagement",
      };
    case SET_SELECTED_PARAMS:
      return {
        ...state,
        selectedSearchParam: action.payload,
      };

    case SET_TABLE:
      return {
        ...state,
        table: action.payload,
      };

    case RESET_PAGINATION_VALUES_AGENTS:
      return {
        ...state,
        pageNumberInput: 1,
      };
    case UPDATE_INPUT_MANAGEMENT:
      return { ...state, allUpdatedInput: action.payload };
    case HANDLE_PAGE_CHANGE_INPUT:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberInput: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberInput: pageNumberOld,
        };
      }
      return { ...state };
    case FILTER_INPUT_SEARCH_TERM:
      return {
        ...state,
        inputManagementData: action.payload,
      };

    default:
      return state;
  }
};
