import moment from "moment";

const convertDate = date => {
  const newDate = new Date(date);
  return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
};
const daysBetweenDays = function (initialDate, finalDate) {
  let a = {};
  for (let d = new Date(initialDate); d <= new Date(finalDate); d.setDate(d.getDate() + 1)) {
    a[convertDate(d)] = { amount: 0, metricTon: 0, XAxis: "" };
  }
  return a;
};

export const graphDataParser = (data, startDate, endDate) => {
  const dateObj = daysBetweenDays(startDate, endDate);
  let totalAmount = 0;
  data.forEach(item => {
    const itemDate = new Date(item?.initiatedAt);
    const dateString = convertDate(itemDate);
    totalAmount += item?.amount || 0;

    if (dateObj[dateString]) {
      dateObj[dateString].amount = (dateObj[dateString].amount || 0) + (item?.amount || 0);
    }
  });
  const dateObjList = Object.keys(dateObj);
  const interval = Math.ceil(dateObjList.length / 10);
  const newData = dateObjList.map((item, index) => {
    const XAxis = moment(new Date(item)).format("MMMM Do, YYYY");
    return {
      ...dateObj[item],
      date: item,
      XAxis,
    };
  });

  return [newData, interval, totalAmount];
};
