import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { searchAdmins } from "../../features/admin/action";
import { getNewOTPSearch } from "../../features/agentOtp/actions";
import { filterFarmersBySearching } from "../../features/agents/actions";
import { searchAgent } from "../../features/farmers/actions";
import { filterInputSearchTerm } from "../../features/inputManagement/action";
import { FilterNegotiation } from "../../features/negotation/action";
import { filterSingleSubscriptions } from "../../features/outputmanagement/action";
import { FilterSupplier } from "../../features/supplier/action";
import { searchTransactions, transactionServiceTypes } from "../../features/transaction/actions";
import { FilterSearch } from "../../features/warehouse/action";
import useTranslate from "../../hooks/useTranslate";
import { Tables } from "../../views/Dashboard/inputManagement";
import { outputTable } from "../../views/Dashboard/outPutManagement";
import LoadingAnimation from "../Loader/Loader";
import LoaderButton from "../Loader/LoaderButton";

const SearchForm = props => {
  const {
    location,
    searchedOtp,
    searchedAgent,
    seachingForFarmers,
    searchingForSingleOutput,
    searchingTransactions,
    filterInputSearchTerm,
    agentSearchParams,
    agentOTPsearchParams,
    farmersSearchParams,
    transactionSearchParams,
    inputMgtSearchParams,
    inputMgtTable,
    outputMgtSearchParams,
    warehouseSearchParams,
    filterWarehouse,
    adminsSearchParams,
    searchAdmins,
    outputMgtTable,
    negotiationSearchParams,
    filterNegotiations,
    filterSupplier,
    supplierSearchParam,
  } = props;
  const [loading, setLoading] = useState(props.loading);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    //OTPRequested()
    if (location === "agentsOTP") {
      setOptions(agentOTPsearchParams);
    } else if (location === "admin") {
      setOptions(adminsSearchParams);
    } else if (location === "agents") {
      setOptions(agentSearchParams);
    } else if (location === "Farmers") {
      setOptions(farmersSearchParams);
    } else if (location === "InputMangement") {
      if (inputMgtTable === Tables.InputManagement) {
        setOptions(inputMgtSearchParams);
      } else if (inputMgtTable === Tables.transaction) {
        const removeServiceType = transactionSearchParams.filter(item => item.value !== "serviceType");
        setOptions(removeServiceType);
      }
    } else if (location === "outputManagement") {
      if (outputMgtTable === outputTable.OutputManagement) {
        setOptions(outputMgtSearchParams);
      } else if (outputMgtTable === outputTable.transaction) {
        const removeServiceType = transactionSearchParams.filter(item => item.value !== "serviceType");
        setOptions(removeServiceType);
      }
    } else if (location === "transaction") {
      setOptions(transactionSearchParams);
    } else if (location === "warehouse") {
      setOptions(warehouseSearchParams);
    } else if (location === "reseller") {
      //@todo: this needs more work
      setOptions([
        { value: "company name", label: "company name" },
        { value: "company address", label: "companyAddress" },
        { label: "name", value: "name" },
        { label: "company email", value: "companyEmail" },
        { label: "resellerStatus", value: "reseller status" },
      ]);
    } else if (location === "negotiation") {
      setOptions(negotiationSearchParams);
    } else if (location === "supplier") {
      setOptions(supplierSearchParam);
    }
  }, [
    supplierSearchParam,
    adminsSearchParams,
    agentOTPsearchParams,
    agentSearchParams,
    farmersSearchParams,
    inputMgtSearchParams,
    inputMgtTable,
    location,
    negotiationSearchParams,
    outputMgtSearchParams,
    outputMgtTable,
    transactionSearchParams,
    warehouseSearchParams,
  ]);

  const { t } = useTranslate();
  return (
    <Formik
      initialValues={{
        term: "",
        key: options[0],
      }}
      onSubmit={values => {
        if (location === "agentsOTP") {
          setLoading(true);
          searchedOtp(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        } else if (location === "agents") {
          setLoading(true);
          searchedAgent(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        } else if (location === "Farmers") {
          setLoading(true);
          seachingForFarmers(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        } else if (location === "InputMangement") {
          setLoading(true);
          if (inputMgtTable === Tables.InputManagement) {
            filterInputSearchTerm(values)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          } else if (inputMgtTable === Tables.transaction) {
            searchingTransactions(values, transactionServiceTypes.input)
              .then(res => setLoading(false))
              .catch(err => setLoading(false));
          }
        } else if (location === "outputManagement") {
          setLoading(true);
          if (outputMgtTable === outputTable.OutputManagement) {
            searchingForSingleOutput(values)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          } else if (outputMgtTable === outputTable.transaction) {
            searchingTransactions(values, transactionServiceTypes.output)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          }
        } else if (location === "transaction") {
          setLoading(true);
          searchingTransactions(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        } else if (location === "warehouse") {
          setLoading(true);
          filterWarehouse(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        } else if (location === "admin") {
          setLoading(true);
          searchAdmins(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        } else if (location === "negotiation") {
          setLoading(true);
          filterNegotiations(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        } else if (location === "supplier") {
          setLoading(true);
          filterSupplier(values)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        }
      }}
    >
      {props => (
        <Form>
          {loading ? (
            <div style={{ position: "absolute", top: "36%", left: "55%" }}>
              <LoadingAnimation />
            </div>
          ) : (
            <></>
          )}

          <div className="filter-container ">
            <Col>
              <div className="position-relative d-flex">
                <FiSearch className="position-absolute form-icon" style={{ top: 13.5, left: 25 }} />
                <Field
                  name="term"
                  className="search-form-style"
                  placeholder={t("Search")}
                  style={{
                    paddingLeft: "50px",
                  }}
                />
                {props.values.term !== "" && (
                  <IoMdClose
                    className="position-absolute form-icon "
                    style={{ top: 8, right: 25 }}
                    size={25}
                    color={"#21c570"}
                    onClick={() => {
                      props.setFieldValue("term", "");
                      props.submitForm();
                    }}
                  />
                )}

                <ErrorMessage name="term" component="span" />
              </div>
            </Col>
            <Col>
              <div>
                <LoaderButton
                  type="search"
                  isLoading={loading}
                  className="btn-primary"
                  text="Search"
                  round
                  style={{
                    width: 100,
                    backgroundColor: "#3bc552",
                  }}
                  id="mc-embedded-subscribe"
                  onClick={props.handleSubmit}
                />
              </div>
            </Col>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  location: state.common.location,
  loading: state.transaction.searching,
  adminsSearchParams: state.admin.searchParams,
  agentSearchParams: state.farmer.searchParamsAgent,
  agentOTPsearchParams: state.agentOTP.searchParamsAgentOTP,
  farmersSearchParams: state.agent.searchParams,
  transactionSearchParams: state.transaction.searchParams,

  inputMgtSearchParams: state.inputManagement.searchParams,
  inputMgtTable: state.inputManagement.table,

  outputMgtSearchParams: state.subscription.searchParams,
  outputMgtTable: state.subscription.table,

  warehouseSearchParams: state.warehouse.searchParams,
  negotiationSearchParams: state.negotiation.searchParams,
  supplierSearchParam: state.supplier.searchParams,
});

const mapDispatchToProps = dispatch => {
  return {
    searchedOtp: values => dispatch(getNewOTPSearch(values)),
    searchAdmins: values => dispatch(searchAdmins(values)),
    searchedAgent: values => dispatch(searchAgent(values)),
    seachingForFarmers: values => dispatch(filterFarmersBySearching(values)),
    searchingForSingleOutput: values => dispatch(filterSingleSubscriptions(values)),
    searchingTransactions: (values, type) => dispatch(searchTransactions(values, type)),
    filterInputSearchTerm: values => dispatch(filterInputSearchTerm(values)),
    filterWarehouse: values => dispatch(FilterSearch(values)),
    filterNegotiations: values => dispatch(FilterNegotiation(values)),
    filterSupplier: values => dispatch(FilterSupplier(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
