import React from "react";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";

import PerfectScrollbar from "perfect-scrollbar";

import IndividualSidebar from "./individualSidebar";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active active-icon-class" : "";
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { role } = this.props.userData;
    return (
      <div className="sidebar font-weight-boldish" data-color="thrive-gradient">
        {/* <div className="logo text-center border-right">
                    <Link to="/dashboard/home" className="text-primary">
                        <img src={logo} className="m-2" width="75px" alt="logo" />
                    </Link>
                </div> */}

        <div className="sidebar-wrapper overflow-visible" ref="sidebar">
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.invisible) return null;
              // if (prop.redirect)
              //     return null;
              if (role !== "superAdmin" && prop.name.toLowerCase() === "admins") return null;
              return (
                <li className={this.activeRoute(prop.path) + (prop.pro ? " active active-pro" : "") + "my-4"} key={key}>
                  <NavLink to={prop.path} className="nav-link" activeClassName="active">
                    <IndividualSidebar icon={prop.icon} name={prop.name} />
                  </NavLink>
                  {prop.hasOwnProperty("views") ? (
                    <ul className="nav flex-column pl-5 mb-4">
                      {prop.views.map(menuSub => {
                        return (
                          <li
                            className={this.activeRoute(menuSub.path) + (menuSub.pro ? " active active-pro" : "")}
                            key={"menuSub" + menuSub.key}
                          >
                            <NavLink exact to={menuSub.path} className="nav-link" activeClassName="active">
                              <span className="menu-item-label text-capitalize" style={{ fontSize: "12px" }}>
                                <img src={menuSub.icon} className="mx-3" width="20px" alt="" />
                                {menuSub.name}
                              </span>
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <span></span>
                  )}
                </li>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: state?.auth?.userData,
  };
};
export default connect(mapStateToProps, null)(Sidebar);
