import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import table from "./data/table";
import sidebar from "./data/sidebar";
import formSelectorAndButton from "./data/formAndButton";

const SeparateEnglishAndHausa = (data) => {
  let english = {};
  let hausa = {};
  for (let key of Object.keys(data)) {
    english[key] = data[key]?.en;
    hausa[key] = data[key]?.ha;
  }
  return [english, hausa];
};
const [englishTable, hausaTable] = SeparateEnglishAndHausa(table);
const [englishSidebar, hausaSidebar] = SeparateEnglishAndHausa(sidebar);
const [englishFormSelectorAndButton, hausaFormSelectorAndButton] =
  SeparateEnglishAndHausa(formSelectorAndButton);

// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      ...englishTable,
      ...englishSidebar,
      ...englishFormSelectorAndButton,
    },
  },
  ha: {
    translation: {
      ...hausaTable,
      ...hausaSidebar,
      ...hausaFormSelectorAndButton,
    },
  },
};

const check = window.localStorage.getItem("lang");
i18next.use(initReactI18next).init({
  resources,
  lng: check ?? "en",
  supportedLngs: ["en", "ha"],
  fallbackLng: check ?? "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
