import React from "react";
import { Col } from "reactstrap";

const ModalColumn = ({ title, result }) => {
  return (
    <Col>
      <div className="view-profile-subheader">{title}</div>
      <div className="view-profile-fieldstyle">{result}</div>
    </Col>
  );
};
export default ModalColumn;
