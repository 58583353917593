import React from "react";

const OverviewReportComponent = ({ title, value }) => {
  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        minHeight: "116px",
        minWidth: "210px",
        borderRadius: "8px",
        padding: "20px",
      }}
      className="d-flex flex-column justify-content-center align-items-start"
    >
      <h3 style={{ fontSize: "14px", marginBottom: 0 }}>{title}</h3>
      <h1 style={{ fontSize: "36px", fontWeight: "700", marginBottom: 0 }}>
        {value ?? 0}
      </h1>
    </div>
  );
};

export default OverviewReportComponent;
