import React, { useState, useEffect } from "react";
import { inPutManagementHeaderArr } from "../../components/Table/headers";
import { useSelected, useModal } from "../../hooks";
import { Formik } from "formik";
import { connect } from "react-redux";
import LoaderButton from "../../components/Loader/LoaderButton";
import {
  updateInputManagement,
  deleteInputManagement,
  updateInputOffers,
} from "../../features/inputManagement/action";
import CustomModalWrapper from "../../components/modal/customModalWrapper";
import ViewInputModal from "../../components/modal/inputMgtModals/viewInputModal";
import EditInputMgtModal from "../../components/modal/inputMgtModals/editInputMgtModal";
import CustomTable from "../../components/Table";
import { inputManagementBody } from "../../components/Table/body";
import AssignAgent from "../../components/modal/agentModals/assignAgentModal";
const InputManagementTable = ({
  inputManagement,
  //  setDisplay,
  data,
  pageNumber,
  pageSize,
  ...props
}) => {
  const [selected, handleClick] = useSelected(inputManagement);
  const [modal, handleModalToggle, showModal] = useModal();
  const [picked, setPicked] = useState("");
  const { updateInput, deleteInput } = props;
  useEffect(() => {
    //  handleDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputManagement, pageNumber, pageSize]);

  const handlePicked = (optionPicked, id) => {
    setPicked(optionPicked);
    handleClick(id);
    showModal();
  };

  return (
    <>
      <CustomTable
        bodyArray={inputManagementBody(inputManagement)}
        dropDowns={{ deleteAccess: true, editAccess: true }}
        handlePicked={handlePicked}
        headerArr={inPutManagementHeaderArr}
      />
      {!selected[0] || selected.length < 1 ? (
        <></>
      ) : picked === "edit" ? (
        <>
          <CustomModalWrapper
            isOpen={modal}
            HeaderTitle={"update Input"}
            toggleModal={handleModalToggle}
          >
            <EditInputMgtModal
              inputObj={selected[0]}
              toggleModal={handleModalToggle}
              updateInput={updateInput}
            />
          </CustomModalWrapper>
        </>
      ) : picked === "delete" ? (
        <>
          <Formik
            initialValues={{
              inputId: selected[0]._id,
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              deleteInput(
                values,
                { setErrors, setSubmitting },
                handleModalToggle
              );
            }}
          >
            {({ values, handleChange, isSubmitting, errors, handleSubmit }) => (
              <>
                <CustomModalWrapper
                  showDefaultFooter
                  HeaderTitle={"Delete Output"}
                  isOpen={modal}
                  toggleModal={handleModalToggle}
                  footerExtra={
                    <LoaderButton
                      type="search"
                      isLoading={isSubmitting}
                      className="btn-primary"
                      text="Delete Input"
                      style={{
                        width: 140,
                        marginRight: "10px",
                        backgroundColor: "#3bc552",
                        borderRadius: "0px 8px",
                      }}
                      id="mc-embedded-subscribe"
                      onClick={handleSubmit}
                    />
                  }
                >
                  <ViewInputModal inputObj={selected[0]} />
                </CustomModalWrapper>
              </>
            )}
          </Formik>
        </>
      ) : picked === "assignAgentOffer" ? (
        <>
          <CustomModalWrapper
            HeaderTitle={"Assign Agent Offer"}
            isOpen={modal}
            toggleModal={handleModalToggle}
          >
            <AssignAgent
              selectedObj={selected[0]}
              handleModalToggle={handleModalToggle}
              onSubmitAgents={updateInputOffers}
            />
          </CustomModalWrapper>
        </>
      ) : (
        <>
          <CustomModalWrapper
            showDefaultFooter
            HeaderTitle={"View Input Management"}
            isOpen={modal}
            toggleModal={handleModalToggle}
          >
            <ViewInputModal inputObj={selected[0]} />
          </CustomModalWrapper>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  pageSize: state.inputManagement.inputManagementData.limit,
  pageNumber: state.inputManagement.inputManagementData.skip,
  totalDocumentCount: state.subscription.subscriptionData.totalDocumentCount,
  inputManagement: state.inputManagement.inputManagementData.data || [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    // setDisplay: arrayOfData => dispatch(setDisplay(arrayOfData)),
    updateInput: (values, { setErrors, setSubmitting }, handleModalToggle) =>
      dispatch(
        updateInputManagement(
          values,
          { setErrors, setSubmitting },
          handleModalToggle
        )
      ),
    deleteInput: (values, { setErrors, setSubmitting }, handleModalToggle) =>
      dispatch(
        deleteInputManagement(
          values,
          { setErrors, setSubmitting },
          handleModalToggle
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputManagementTable);
