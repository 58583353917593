import React from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";

const CustomModalWrapper = ({
  isOpen,
  setModal,
  showDefaultFooter,
  toggleModal,
  HeaderTitle,
  children,
  footerExtra,
  ...props
}) => {
  const closeBtn = (
    <FaRegWindowClose
      className="text-danger mt-2 close-btn"
      onClick={setModal ?? toggleModal}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="modal-position"
      backdrop="static"
      contentClassName="overview-modal"
      {...props}
    >
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        cssModule={{ "modal-title": "modal-title sub-modal-header" }}
      >
        <span className="form-header-color ca-font-medium ml-2 tab-font-size modal-header-subcription">
          {HeaderTitle}
        </span>
      </ModalHeader>
      {children}
      {showDefaultFooter && (
        <ModalFooter>
          {footerExtra}
          <Button
            className="form-btn-2 ca-font-regular"
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default CustomModalWrapper;
