import moment from "moment";
import React from "react";
import { createStaticRanges, DateRangePicker } from "react-date-range";

const CustomDateRange = ({
  startDate,
  endDate,
  onChange,
  right=true,
  staticRanges = createStaticRanges([
    {
      label: "Month to Date",
      range: () => ({
        startDate: moment().subtract(1, "months").toDate(),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 3 Months",
      range: () => ({
        startDate: moment().subtract(3, "months").toDate(),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 6 Months",
      range: () => ({
        startDate: moment().subtract(6, "months").toDate(),
        endDate: new Date(),
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: moment().subtract(12, "months").toDate(),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 2 Years",
      range: () => ({
        startDate: moment().subtract(24, "months").toDate(),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 4 Years",
      range: () => ({
        startDate: moment().subtract(48, "months").toDate(),
        endDate: new Date(),
      }),

    },
    {
        label: "reset",
        range: () => ({
          startDate: null,
          endDate: new Date(),
        }),
        
      },
  ]),
  
}) => {
  const startDateFormat = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : "----.--.--";
  const endDateFormat = endDate
    ? moment(endDate).format("YYYY-MM-DD")
    : "----.--.--";

    const style={
      zIndex: "10",
      border: "solid 1px black",
    }
    
    if(right){
      style.right="0px"
    }
    else{
      style.left="0px"
    }
  return (
    <div className="dateRange" tabIndex="0" autoFocus>
      <div
        className="d-flex justify-content-around"
        style={{ padding: "6px 3px", gap: "1rem" }}
      >
        <span>{startDateFormat}</span>
        <span>{endDateFormat}</span>
      </div>
      <div
        className="position-absolute dateRangePicker"
        style={style}
      >
        <DateRangePicker
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          rangeColors={["#21c570"]}
          ranges={[
            {
              startDate,
              endDate,
              key: "selection",
            },
          ]}
          onChange={onChange}
          staticRanges={staticRanges}
          //inputRanges={[]}
        />
      </div>
    </div>
  );
};

export default CustomDateRange;
