import {
  GET_ALL_OTP_REQUEST,
  HANDLE_PAGE_CHANGE_OTP,
  SET_SEARCHED_OTP_REQUEST,
  CHANGE_SELECTED_PARAM_OTP,
  RESET_PAGINATION_VALUES_AGENTS,
  SET_ALL_OTP_DATA,
 } from "./actions";

const initialState = {
  pageSize: 10,
  allOTPRequested: [],
  pageNumberOTP: 1,
  searchParamsAgentOTP: [
    { value: "verified", label: "verified" },
    { value: "phone", label: "Phone" },
    { value: "verifyPhoneOtp", label: "otp" },
  ],
  selectedSearchParamsAgentOTP: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SELECTED_PARAM_OTP:
      return { ...state, selectedSearchParamsAgentOTP: action.payload };
    case SET_SEARCHED_OTP_REQUEST:
      return {
        ...state,
        allOTPRequested: action.payload,
        pageNumberOTP: 1,
        };
    case SET_ALL_OTP_DATA:
      return {
        ...state,
        allOTPRequested: action.payload,
        pageNumberOTP: 1,
      };

    case HANDLE_PAGE_CHANGE_OTP:
      const pageNumberOTPRequested = action.payload;
      if (pageNumberOTPRequested === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumberOTP: pageNumberNew,
        };
      }
      if (pageNumberOTPRequested >= 10) {
        let pageNumberNew = pageNumberOTPRequested / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumberOTP: pageNumberOld,
        };
      }
      return { ...state };
    case GET_ALL_OTP_REQUEST:
      return {
        ...state,
        allOTPRequested: action.payload,
        selectedSearchParamsAgentOTP: {},
        pageNumberOTP: 1,
      };
    case RESET_PAGINATION_VALUES_AGENTS:
      return {
        ...state,
        pageNumberOTP: 1,
      };
    default:
      return state;
  }
};
