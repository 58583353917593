import { authChecker } from "../../services/authService";
import subscription from "../../api/subscription";
import output from "../../api/output";
import { toast } from "react-toastify";
import { getDateParam, resetDateRange } from "../../utils/dateParams";

export const GET_ALL_INPUT_MANAGEMENT = "GET_ALL_INPUT_MANAGEMENT";
export const UPDATE_INPUT_MANAGEMENT = "UPDATE_INPUT_MANAGEMENT";
export const DELETE_INPUT_MANAGEMENT = "DELETE_INPUT_MANAGEMENT";
export const HANDLE_PAGE_CHANGE_INPUT = "HANDLE_PAGE_CHANGE_INPUT";
export const FILTER_INPUT_SEARCH_TERM = "FILTER_INPUT_SEARCH_TERM";
export const RESET_PAGINATION_VALUES_AGENTS = "RESET PAGINATION VALUES AGENT";
export const SET_SELECTED_PARAMS = "SET SELECT PARAMS";
export const SET_TABLE = "SET TABLE";

export const updateInputOffers = async (values, inputId, dispatch) => {
  const body = { inputId, agents: values };
  authChecker();
  return subscription.put("/inputs/offers", body).then(() => {
    if (dispatch) dispatch(getAllInputManagement());
  });
};

export const getAllInputManagement = () => dispatch => {
  authChecker();
  resetDateRange(dispatch);
  return output
    .get("/inputs")
    .then(inData => {
      dispatch({
        type: GET_ALL_INPUT_MANAGEMENT,
        payload: inData.data,
      });
    })
    .catch(() => {
      toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
    });
};
/*****
 * updating input Management
 *  ****/
export const updateInputManagement =
  (values, { setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    return subscription
      .patch("/inputs", values)
      .then(upDateData => {
        handleModalToggle();
        toast.success(upDateData.data.status + " " + upDateData.data.message);
        dispatch({
          type: UPDATE_INPUT_MANAGEMENT,
          payload: upDateData.data,
        });
        dispatch(getAllInputManagement());
      })
      .catch(err => {
        toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
      })
      .then(() => {
        setSubmitting(false);
      });
  };
/*****
 * Deleting Input
 * ****/
export const deleteInputManagement =
  (values, { setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    let { inputId } = values;
    return subscription
      .delete("/inputs", { data: { inputId: inputId } })
      .then(deletedData => {
        handleModalToggle();
        toast.success(deletedData.data.status + " " + deletedData.data.message);
        dispatch({
          type: DELETE_INPUT_MANAGEMENT,
          payload: deletedData.data,
        });
        dispatch(getAllInputManagement());
      })
      .catch(err => {
        toast.error(err.response.data.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };
/*****
 * End of Deleting
 */

export const setTable = table => dispatch => {
  dispatch({
    type: SET_TABLE,
    payload: table,
  });
};

/*****
 * PageChange Handling
 * *****/
export const handlePageChange = (pageNumber, pageSize) => (dispatch, getState) => {
  authChecker();
  const { common, inputManagement } = getState();

  const { selectedSearchParam } = inputManagement;
  const dateParams = getDateParam(common);

  const params = {
    skip: (pageNumber - 1) * pageSize,
    limit: pageSize,
    ...selectedSearchParam,
    ...dateParams,
  };
  return output
    .get("/inputs", { params })
    .then(inData => {
      dispatch({
        type: FILTER_INPUT_SEARCH_TERM,
        payload: inData.data,
      });
      dispatch({
        type: HANDLE_PAGE_CHANGE_INPUT,
        payload: inData.data.skip,
      });
    })
    .catch(err => {
      toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
    });
};
/*******
 * End Of Handlepage Change
 */

/****
 * HandleSubmission of Input
 */
export const initiateInputManagement =
  (values, { setErrors, setSubmitting }, handleModalToggle) =>
  dispatch => {
    authChecker();
    return subscription
      .post("/inputs", values)
      .then(res => {
        handleModalToggle();
        if (res.status === 200) {
          toast.success(res.data.message || "Created input successfully");
        }
        dispatch(getAllInputManagement());
      })
      .catch(err => {
        toast.error(err.response.message || err.message || "Network Error");
      })
      .then(() => {
        setSubmitting(false);
      });
  };

// Searching for Input Item
export const filterInputSearchTerm = values => (dispatch, getState) => {
  authChecker();
  let { term, key } = values;
  let defaultKey = "inputType";
  const dateParams = getDateParam(getState().common);

  let selectedParams = {
    [key ?? defaultKey]: term,
  };
  if (!term.trim()) {
    selectedParams = {};
  }

  const params = {
    search: term,
    ...dateParams,
  };
  return (
    output
      /// changed from resellers to Subscription.. Please create another action for Reselers
      .get("/inputs", { params })
      .then(inputData => {
        dispatch({
          type: FILTER_INPUT_SEARCH_TERM,
          payload: inputData.data,
        });
        dispatch({
          type: SET_SELECTED_PARAMS,
          action: selectedParams,
        });
        dispatch({
          type: RESET_PAGINATION_VALUES_AGENTS,
        });
        if (!inputData.data.data.length) {
          toast.error("Input not found");
        }
      })
      .catch(() => {
        toast.error("Something went wrong fetching Subscriptions, try refreshing the page");
      })
  );
};
