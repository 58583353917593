import React from "react";
import DatePicker from "react-date-picker";
import { Label } from "reactstrap";

const CustomDatePicker = ({ label, onChange, value }) => {
  return (
    <>
      {label && <Label htmlFor="deliveryDate">{label}</Label>}
      <div>
        <DatePicker onChange={onChange} value={value} clearIcon={null} />
      </div>
    </>
  );
};

export default CustomDatePicker;
