import {SET_ALL_MANAGER,CREATE_NEW_MANAGER,SET_LOADING_STATE} from "./action"

const INITIAL_STATE={
    allManager:[],
    isLoading:false
}

const reducer=(state=INITIAL_STATE,action)=>{
    switch(action.type){
        case SET_ALL_MANAGER:
            return {...state,allManager:action.payload};

        case CREATE_NEW_MANAGER:
            const allManager= [...state.allManager].push(action.payload);
            return {...state,allManager:allManager};

        case SET_LOADING_STATE:
            return {...state,isLoading:action.payload}

        default:
            return state
    }
}

export default reducer;