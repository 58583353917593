import { Tables } from "../../views/Dashboard/TradeApproved";
import {
  GET_ALL_NEGOTIATION_BUY,
  GET_ALL_NEGOTIATION_SELL,
  HANDLE_PAGE_CHANGE,
  RESET_PAGINATION_VALUES_AGENTS,
  SET_SELECTED_PARAMS,
  SEARCH_NEGOTIATION,
  SET_TABLE,
} from "./action";

const initialState = {
  negotiationDataBuy: {},
  negotiationDataSell: {},
  pageNumber: 1,
  // eslint-disable-next-line no-sparse-arrays
  searchParams: [
    { value: "status", label: "Status" },
    { value: "approved", label: "Approved" },
    {
      value: "commodityName",
      label: "Commodity Name",
    },
    ,
    {
      value: "customerName",
      label: "Customer Name",
    },
    ,
    // {
    //   value: "category",
    //   label: "Category",
    // },
  ],
  selectedSearchParam: {},
  table: Tables.buy,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NEGOTIATION_BUY:
      return {
        ...state,
        negotiationDataBuy: action.payload,
        selectedSearchParam: {},
        pageNumber: 1,
      };
    case GET_ALL_NEGOTIATION_SELL:
      return {
        ...state,
        negotiationDataSell: action.payload,
        selectedSearchParam: {},
        pageNumber: 1,
      };
    case SET_TABLE:
      return {
        ...state,
        selectedSearchParam: {},
        pageNumber: 1,
        table: action.payload,
      };
    case RESET_PAGINATION_VALUES_AGENTS:
      return {
        ...state,
        pageNumber: 1,
      };
    case SEARCH_NEGOTIATION:
      return {
        ...state,
        negotiationDataBuy:
          state?.table === Tables.buy
            ? action.payload
            : state.negotiationDataBuy,
        negotiationDataSell:
          state?.table === Tables.sell
            ? action.payload
            : state.negotiationDataSell,
      };
    case SET_SELECTED_PARAMS:
      return {
        ...state,
        selectedSearchParam: action.payload,
      };
    case HANDLE_PAGE_CHANGE:
      const pageNumber = action.payload;
      if (pageNumber === 0) {
        let pageNumberNew = 1;
        return {
          ...state,
          pageNumber: pageNumberNew,
        };
      }
      if (pageNumber >= 10) {
        let pageNumberNew = pageNumber / 10;
        let pageNumberOld = pageNumberNew + 1;
        return {
          ...state,
          pageNumber: pageNumberOld,
        };
      }
      break;
    default:
      return state;
  }
};
