export const nestedInputCategoryArrayToObject = (
  array,
  keyField,
  secondaryField
) => {
  return array.reduce((obj, item) => {
    obj[item[keyField]["name"]] = item[keyField][secondaryField];
    return obj;
  }, {});
};
