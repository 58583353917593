export const customStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      outlineColor: state.isFocused ? "#3BC552" : "hsl(0,0%,80%)",
    };
  },
  control: provided => {
    const height = "40px";
    const width = "100%";
    const minWidth = "150px";
    const minHeight = "40px";

    return {
      ...provided,
      height,
      width,
      minHeight,
      minWidth,
    };
  },
  dropdownIndicatorStyles: (provided, state) => {
    let color = "#3BC552";
    return { ...provided, color };
  },
};
