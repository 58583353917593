import React from "react";
import Button from "../../components/CustomButton/CustomButton.jsx";
import useTranslate from "../../hooks/useTranslate.js";

const LoadingAnimation = () => (
  <div className="spinner_date">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  className,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslate();
  return (
    <Button className={className} disabled={disabled || isLoading} {...props}>
      {isLoading && <LoadingAnimation />}
      {!isLoading ? t(text) : null}
    </Button>
  );
};

export default LoaderButton;
