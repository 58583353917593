export const TOAST_NOTIFY = "TOAST_NOTIFY";

// default function to display redux action format
export const toastNotify = (msg, type) => dispatch => {
  let msgOptions = {
    msg,
    type,
  };
  // action object format being return to a reducer
  dispatch({
    type: TOAST_NOTIFY,
    payload: msgOptions,
  });
};
