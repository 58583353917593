export const overviewHeaderArr = [
  { id: 1, headerName: "ID" },
  { id: 2, headerName: "Name" },
  // { id: 3, headerName: "Date" },
  { id: 4, headerName: "Phone" },
  { id: 5, headerName: "Gender" },
  { id: 6, headerName: "State" },
  { id: 7, headerName: "LGA" },
  { id: 8, headerName: "District" },
  { id: 9, headerName: "Action" },
];

export const adminHeaderArr = [
  { id: 1, headerName: "Name" },
  { id: 3, headerName: "Role" },
  { id: 3, headerName: "Phone" },
  { id: 4, headerName: "Gender" },
  { id: 5, headerName: "State" },
  { id: 6, headerName: "Lga" },
  { id: 7, headerName: "Action" },
];

export const agentHeaderArr = [
  { id: 1, headerName: "ID" },
  { id: 2, headerName: "Name" },
  { id: 4, headerName: "Phone" },
  { id: 5, headerName: "Gender" },
  { id: 6, headerName: "State" },
  { id: 7, headerName: "LGA" },
  { id: 8, headerName: "District" },
  { id: 9, headerName: "Action" },
];

export const farmerHeaderArr = [
  { id: 1, headerName: "Name" },
  { id: 2, headerName: "Date Onboarded" },
  { id: 3, headerName: "Phone" },
  { id: 4, headerName: "Gender" },
  { id: 5, headerName: "State" },
  { id: 6, headerName: "Lga" },
  { id: 7, headerName: "Street" },
  { id: 8, headerName: "Action" },
];

export const agentsOnboardFarmer = [
  { id: 1, headerName: "Name" },
  { id: 2, headerName: "Date Onboarded" },
  { id: 3, headerName: "Phone" },
  { id: 4, headerName: "Gender" },
  { id: 5, headerName: "State" },
  { id: 6, headerName: "Lga" },
  { id: 7, headerName: "Street" },
];

export const subscriptionHeaderArr = [
  // { id: 1, headerName: "ID" },
  { id: 1, headerName: "Output Type" },
  { id: 2, headerName: "Output Category" },
  { id: 3, headerName: "Output Name" },
  { id: 4, headerName: "Output State" },
  { id: 5, headerName: "Output LGA" },
  { id: 6, headerName: "Output Reference" },
  { id: 7, headerName: "Action" },
];
export const inPutManagementHeaderArr = [
  // { id: 1, headerName: "ID" },
  { id: 1, headerName: "Input Type" },
  { id: 2, headerName: "Input Category" },
  { id: 3, headerName: "Input Name" },
  { id: 4, headerName: "Input State" },
  { id: 5, headerName: "Input LGA" },
  { id: 6, headerName: "Input Reference" },
  { id: 7, headerName: "Action" },
];

export const transactionHeadersArr = [
  // { id: 1, headerName: "ID" },
  { id: 1, headerName: "Reference ID" },
  { id: 2, headerName: "Input purchased/Output supplied" },
  { id: 3, headerName: "Amount Paid" },
  { id: 4, headerName: "Negotiated Amount" },
  { id: 5, headerName: "Agent Name" },
  { id: 6, headerName: "Quantity purchased" },
  { id: 7, headerName: "Status" },
  { id: 8, headerName: "Agent Phone" },
  { id: 9, headerName: "Action" },
];
export const ResellerTableArr = [
  { id: 1, headerName: "Company Name" },
  { id: 2, headerName: "Company Address" },
  { id: 3, headerName: "First Name" },
  { id: 4, headerName: "Last Name" },
  { id: 5, headerName: "Reseller Status" },
  { id: 6, headerName: "Phone" },
  { id: 7, headerName: "Company Email" },
  { id: 8, headerName: "Date Requested" },
  { id: 9, headerName: "Action" },
];
export const requestHeaderArr = [
  { id: 1, headerName: "Company Name" },
  { id: 2, headerName: "Company Address" },
  { id: 3, headerName: "First Name" },
  { id: 4, headerName: "Last Name" },
  { id: 5, headerName: "Reseller Status" },
  { id: 6, headerName: "Phone" },
  { id: 7, headerName: "Company Email" },
  { id: 8, headerName: "Date Requested" },
  { id: 9, headerName: "Action" },
];

export const agentOTPPin = [
  { id: 1, headerName: "Verified" },
  { id: 2, headerName: "Time Requested" },
  { id: 3, headerName: "Phone" },
  { id: 4, headerName: "OTP" },
  { id: 5, headerName: "Requested OTP Timer" },
  { id: 6, headerName: "Initiated At" },
];

export const tradeApproval = [
  { id: 1, headerName: "Status" },
  { id: 2, headerName: "Product Name" },
  { id: 3, headerName: "Agent" },
  { id: 4, headerName: "Last Updated" },
  { id: 5, headerName: "Action" },
];

export const supplierHeader = [
  { id: 1, headerName: "Agent Name" },
  { id: 2, headerName: "Driver Name" },
  { id: 3, headerName: "Truck Number" },
  { id: 4, headerName: "Commodity Type" },
  { id: 5, headerName: "Quantity" },
  { id: 6, headerName: "Last Updated" },
  { id: 7, headerName: "Tracking" },
  { id: 8, headerName: "Action" },
];

export const warehouseHeaderArr = [
  { id: 1, headerName: "State" },
  { id: 2, headerName: "LGA" },
  { id: 3, headerName: "Address" },
  { id: 4, headerName: "District" },
  { id: 5, headerName: "Street" },
  { id: 6, headerName: "Warehouse ID" },
  { id: 7, headerName: "Storage Capacity Trailer load" },
  { id: 8, headerName: "Action" },
];
