import React from "react";
import ReactDOM from "react-dom";
import { Route, Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer, Slide } from "react-toastify";
import history from "./history";
import ScrollToTop from "./ScrollToTop";
import { AuthRoute, NoAuthRoute } from "./routes/AuthRoute";
import store from "./store";
import ToastNotify from "./features/notification/notification";
import indexRoutes from "./routes/index";
import "./services/i18n";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/thrive.scss";
import "./index.scss";
import "typeface-montserrat";
import "./assets/scss/fonts/CalibreMedium.otf";
import "./assets/scss/fonts/CalibreRegular.otf";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

ReactDOM.render(
  <Provider store={store}>
    <div>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            {indexRoutes.map((prop, key) => {
              if (prop.auth === true) {
                return (
                  <AuthRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              } else if (prop.noAuth === true) {
                return (
                  <NoAuthRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              }
              return (
                <Route path={prop.path} key={key} component={prop.component} />
              );
            })}
          </Switch>
        </ScrollToTop>
      </Router>
      <ToastNotify me="hello" />
      <ToastContainer
        position="top-center"
        hideProgressBar
        transition={Slide}
        className="toast-style"
      />
    </div>
  </Provider>,
  document.getElementById("root")
);
