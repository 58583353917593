import React from "react";
import { Col, ModalBody, Row } from "reactstrap";
import ModalColumn from "../modalColumn_tempt";

const AdminView = ({ userObj }) => {
  return (
    <ModalBody>
      <div>
        <Col>
          <Row className="d-flex justify-content-between ca-font-regular">
            <ModalColumn result={userObj?.firstName} title="First Name" />
            <ModalColumn result={userObj?.lastName} title="Last Name" />
          </Row>
          <Row className="d-flex justify-content-between ca-font-regular mt-3">
            <ModalColumn result={userObj?.role} title="Role" />
            <ModalColumn result={userObj?.email} title="Email" />
          </Row>
          <Row className="d-flex justify-content-between ca-font-regular mt-3">
            <ModalColumn result={userObj?.state} title="state" />
            <ModalColumn result={userObj?.lga} title="local government" />
          </Row>

          <Row className="d-flex justify-content-between ca-font-regular mt-3">
            <ModalColumn result={userObj?.phone} title="Phone" />
          </Row>
        </Col>
      </div>
    </ModalBody>
  );
};

export default AdminView;
