const download = function (data, name) {
  const today = new Date();
  const fileName = name ?? `tradr-transactions_${today.toISOString()}`;
  const blob = new Blob([data], { type: "text/csv" });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a");

  // Passing the blob downloading url
  a.setAttribute("href", url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", `${fileName}.csv`);

  // Performing a download with click
  a.click();
};

const downloadCsv = async function (func, state, converter) {
  try {
    const result = await func(state);
    const formatted = converter(result?.data?.data);
    download(formatted);
  } catch (err) {}
};

export default downloadCsv;
