import React from "react";
import { Col, Container, Row } from "reactstrap";
import logo from "../../assets/images/aos-logo.svg";
import LoginForm from "../../components/Forms/Login/LogIn";
import "./SignIn.scss";
export default function SignIn({ location }) {
  return (
    <Container fluid>
      <Row className="flex-nowrap h-100">
        <Col lg={5} sm={5} className="bg-Img sign-cover-img" style={{ height: "100vh" }}>
          {/* <Spacer unit={44} /> */}
          <Row className="logo-container">
            {/* <Spacer left={90} /> */}
            <Col className="logo-spacing">
              <img src={logo} alt="thrive-aos-logo" width={150} />
            </Col>
          </Row>
          {/* <Spacer unit={500} /> */}
          <Row className="d-flex cover-img-text flex-column">
            <Col className="text-black foot-info ">Invest in a Food Secured Future</Col>
            <Col xs={8} className="text-black foot-body">
              We are thrilled to welcome you to our bustling hub where the dashboard keeps you updated.
            </Col>
            <Col xs={8} className="text-black foot-body">
              We strongly believe that informed decisions lead to bountiful harvests and successful trades.
            </Col>
          </Row>
        </Col>

        <Col
          sm={10}
          lg={5}
          className={`d-flex justify-content-center align-items-start ml-5 flex-column login-container ${
            window.innerWidth > 1599 ? "mt-5" : ""
          }`}
        >
          {/* <Spacer unit={50} /> */}
          <Row className={`d-flex flex-column mt-5 ${window.innerWidth > 1599 ? "mb-5" : ""}`}>
            <Col className="login-bottom-spacing mt-4">
              <LoginForm location={location} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-self-center form-icon copyright-sign-in">© Copyright Thrive Agric 2020. All rights reserved.</Col>
          </Row>
          {/* <Spacer unit={150} /> */}
        </Col>
      </Row>
    </Container>
  );
}
