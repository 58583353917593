import React, { useEffect, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { connect } from "react-redux";
import CustomTable from "../../components/Table";
import { agentBody } from "../../components/Table/body";
import { agentHeaderArr } from "../../components/Table/headers";
import AgentView from "../../components/modal/agentModals/agentView";
import { assignRole, lockAgent, unlockAgent } from "../../features/farmers/actions";
import { useModal, useSelected } from "../../hooks";
import FarmersAgentOnboarded from "./component/agentsFarmerOnboarded";

const AgentTable = ({ farmers, agents, pageNumber, pageSize, ...props }) => {
  const [selected, handleClick] = useSelected(farmers);
  const [modal, handleModalToggle, showModal] = useModal();
  const [picked, setPicked] = useState("");
  const { lockAgents, unlockAgents, assignAgentRole } = props;
  useEffect(() => {}, [agents, pageNumber, pageSize]);

  const handlePicked = (optionPicked, id) => {
    setPicked(optionPicked);
    handleClick(id);
    showModal();
  };

  const closeBtn = <FaRegWindowClose className="text-danger mt-2 close-btn" onClick={handleModalToggle} />;

  const assignRole = (values, { setSubmitting, setErrors }, toggleModal, selectedOption) => {
    values.role = selectedOption.value;

    assignAgentRole(values, { setSubmitting, setErrors }, toggleModal);
  };
  return (
    <>
      {
        <CustomTable
          bodyArray={agentBody(farmers)}
          dropDowns={{
            lockUser: true,
            unlockUser: true,
            assignRole: true,
            viewFarmers: true,
          }}
          headerArr={agentHeaderArr}
          handlePicked={handlePicked}
        />
      }
      {!selected[0] || selected.length < 1 ? (
        ""
      ) : picked === "assignRole" ? (
        <AgentView
          action={assignRole}
          agent={selected[0]}
          headerTitle="Assign Role"
          toggleModal={handleModalToggle}
          key={selected[0]._id}
          isOpen={modal}
          assignRole
          initialValues={{
            role: "",
          }}
        />
      ) : picked === "lockuser" ? (
        <>
          <AgentView
            action={lockAgents}
            agent={selected[0]}
            headerTitle="Lock user"
            toggleModal={handleModalToggle}
            key={selected[0]._id}
            isOpen={modal}
          />
        </>
      ) : picked === "unlockuser" ? (
        <>
          <AgentView
            action={unlockAgents}
            agent={selected[0]}
            headerTitle="Unlock user"
            toggleModal={handleModalToggle}
            key={selected[0]._id}
            isOpen={modal}
          />
        </>
      ) : picked === "viewFarmers" ? (
        <>
          <FarmersAgentOnboarded
            isOpen={modal}
            toggle={handleModalToggle}
            close={closeBtn}
            userID={selected[0]._id}
            userName={selected[0].firstName}
            lastName={selected[0].lastName}
            phoneNumber={selected[0].phone}
          />
        </>
      ) : (
        <AgentView
          action={unlockAgents}
          agent={selected[0]}
          headerTitle="Unlock user"
          toggleModal={handleModalToggle}
          key={selected[0]._id}
          isOpen={modal}
          showDefaultFooter
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  farmers: state.farmer.farmerData.data || [],
  pageNumber: state.farmer.farmerData.skip,
  pageSize: state.farmer.farmerData.limit,
  totalDocumentCount: state.farmer.farmerData.totalDocumentCount,
  currentPage: state.farmer.pageNumberFarmers,
  // Values for searchBy Date range
  unlockSuccessful: state.farmer.unLockSuccessful,
});

const mapDispatchToProps = dispatch => {
  return {
    lockAgents: (values, { setSubmitting, setErrors }, handleModalToggle) =>
      dispatch(lockAgent(values, { setSubmitting, setErrors }, handleModalToggle)),
    unlockAgents: (values, { setSubmitting, setErrors }, handleModalToggle) =>
      dispatch(unlockAgent(values, { setSubmitting, setErrors }, handleModalToggle)),
    assignAgentRole: (values, { setSubmitting, setErrors }, handleModalToggle) =>
      dispatch(assignRole(values, { setSubmitting, setErrors }, handleModalToggle)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentTable);
