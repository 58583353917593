import dayjs from "dayjs";
import React from "react";
import { Col, ModalBody, Row } from "reactstrap";

const ViewInputModal = ({ inputObj }) => {
  return (
    <ModalBody>
      <Col>
        <Row className="mb-4 d-flex justify-content-around">
          <Col className="d-flex">
            <div className="farmer-img-info d-flex flex-column justify-content-center ca-font-regular">
              <div className="form-header-color">
                {`${inputObj.inputReference} `}
                {/* {inputObj.lastName} */}
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div>
                  {" "}
                  <span>Created On : </span>
                </div>
                <div>
                  {dayjs(inputObj.meta.createdAt).format(
                    "YYYY-MM-DD (HH:mm:ss A)"
                  )}
                </div>
              </div>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div>
                  {" "}
                  <span>Updated On : </span>
                </div>
                <div>
                  {dayjs(inputObj.meta.updatedAt).format(
                    "YYYY-MM-DD (HH:mm:ss A)"
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular">
          <Col>
            <div className="view-profile-subheader">Input Type</div>
            <div className="view-profile-fieldstyle">{inputObj.inputType}</div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Input Category</div>
            <div className="view-profile-fieldstyle">
              {inputObj.inputCategory}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Input Name</div>
            <div className="view-profile-fieldstyle">{inputObj.inputName}</div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Input Lga</div>
            <div className="view-profile-fieldstyle">{inputObj.inputLga}</div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Input State</div>
            <div className="view-profile-fieldstyle">{inputObj.inputState}</div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Instock</div>
            <div className="view-profile-fieldstyle">
              {inputObj.instock === true ? "Yes" : "No"}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Per Unit Price</div>
            <div className="view-profile-fieldstyle">
              {inputObj.perUnitPrice}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Unit Of Measurement</div>
            <div className="view-profile-fieldstyle">
              {inputObj.unitOfMeasurement}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">description</div>
            <div className="view-profile-fieldstyle">
              {inputObj.description}{" "}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">
              Input Distribution Company
            </div>
            <div className="view-profile-fieldstyle">
              {inputObj.inputDistributionCompany}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col>
            <div className="view-profile-subheader">Total Units Instock</div>
            <div className="view-profile-fieldstyle">
              {inputObj.totalUnitsInstock}
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div className="view-profile-subheader">Units Remaining</div>
            <div className="view-profile-fieldstyle">
              {inputObj.unitsRemaining}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between ca-font-regular mt-3">
          <Col className="d-flex align-items-center flex-column"></Col>
        </Row>
      </Col>
    </ModalBody>
  );
};

export default ViewInputModal;
